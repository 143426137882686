import React, { useState, useEffect, useRef } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useCANStream from '../../../hooks/useCANStream.hook';

const MAX_TEST_TIME = 20; // seconds
const POLL_INTERVAL = 100; // milliseconds
const MAX_ITERATIONS = (MAX_TEST_TIME * 1000) / POLL_INTERVAL;
const THROTTLE_MAX = 10000; // 100% throttle value

const ThrottleTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const { t } = useTranslation();
  
  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Setting PAS to 0 for safety...");
  const [countdown, setCountdown] = useState(MAX_TEST_TIME);
  const [maxThrottleReached, setMaxThrottleReached] = useState(0);
  const [initialValueFailed, setInitialValueFailed] = useState(false);
  const [safetyCheckComplete, setSafetyCheckComplete] = useState(false);
  const [shouldStartStream, setShouldStartStream] = useState(false);
  
  const timerRef = useRef(null);
  const iterationCount = useRef(0);
  const hasStartedRef = useRef(false);
  const testReadyRef = useRef(false);

  // Stream indices for throttle and PAS
  const streamIndices = [
    {
      nodeId: 0x00000601,
      index: 0x2027,
      subIndex: 0x01 // Throttle value
    }
  ];

  // Set up CAN stream
  const { values, startStream, stopStream, writeValue } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    streamIndices,
    () => {} // We'll handle values through the values array
  );

  // Safety check - Set PAS to 0
  useEffect(() => {
    const initSafety = async () => {
      try {
        console.log("Setting PAS to 0 for safety");
        await writeValue(0x00000601, 0x2003, 0x00, 0, true);
        console.log("PAS set to 0 successfully");
        setSafetyCheckComplete(true);
        setShouldStartStream(true);
        setInstruction("Waiting for initial throttle to be 0...");
      } catch (error) {
        console.error("Failed to set PAS to 0:", error);
        handleTestStop(false);
        setInstruction("ERROR: Failed to set PAS to 0 - Test cannot proceed safely");
      }
    };

    if (!safetyCheckComplete && !testFinished) {
      initSafety();
    }
  }, [safetyCheckComplete, testFinished, writeValue]);

  const startTimer = () => {
    if (timerRef.current) return;
    
    console.log("Starting throttle test timer");
    timerRef.current = setInterval(() => {
      iterationCount.current += 1;
      
      if (iterationCount.current >= MAX_ITERATIONS) {
        cleanup();
        if (!testFinished) {
          handleTestStop(maxThrottleReached >= THROTTLE_MAX && !initialValueFailed);
          setTestFinished(true);
        }
        return;
      }

      const timeElapsed = (iterationCount.current * POLL_INTERVAL) / 1000;
      setCountdown(Math.max(0, MAX_TEST_TIME - timeElapsed));
    }, POLL_INTERVAL);
  };

  // Monitor values array for changes
  useEffect(() => {
    if (!testRunning || testFinished || !safetyCheckComplete) return;

    const currentValue = values?.[0];
    
    // Wait for valid initial value
    if (!hasStartedRef.current && currentValue !== null && currentValue !== undefined) {
      console.log("Got initial throttle value:", currentValue);
      hasStartedRef.current = true;
      
      if (currentValue === 0) {
        console.log("Initial throttle is 0, ready for test");
        testReadyRef.current = true;
        setInstruction("Press and hold the throttle until 100%");
        startTimer();
      } else {
        console.log("Initial throttle not 0:", currentValue);
        setInitialValueFailed(true);
        setInstruction("Release the throttle completely before starting test");
        startTimer();
      }
      return;
    }

    // Monitor throttle changes
    if (hasStartedRef.current && currentValue !== null && currentValue !== undefined) {
      setMaxThrottleReached(prev => Math.max(prev, currentValue));

      if (currentValue >= THROTTLE_MAX && !initialValueFailed) {
        console.log("Reached 100% throttle");
        handleTestStop(true);
        setTestFinished(true);
      }
    }
  }, [values, testRunning, testFinished, safetyCheckComplete]);

  // Cleanup function
  const cleanup = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    stopStream();
  };

  // Start stream after safety check
  useEffect(() => {
    if (shouldStartStream && !testRunning && !testFinished) {
      console.log("Starting throttle test after safety check");
      setTestRunning(true);
      startStream();
      setShouldStartStream(false);
    }
    return () => cleanup();
  }, [shouldStartStream, testRunning, testFinished]);

  const handleTestStop = (result) => {
    setInstruction(null);
    setTestResult(result);
    setTestRunning(false);
    cleanup();
  };

  const restartTest = async () => {
    cleanup();
    // Reset everything but repeat safety check
    setSafetyCheckComplete(false);
    setTestRunning(false);
    setTestResult(null);
    setInstruction("Setting PAS to 0 for safety...");
    setCountdown(MAX_TEST_TIME);
    setTestFinished(false);
    setMaxThrottleReached(0);
    setInitialValueFailed(false);
    setShouldStartStream(false);
    hasStartedRef.current = false;
    testReadyRef.current = false;
    iterationCount.current = 0;
  };

  const getCurrentThrottlePercent = (value) => {
    if (value === 'Waiting for data...') return value;
    return `${((value / THROTTLE_MAX) * 100).toFixed(1)}%`;
  };

  const currentValue = values?.[0] ?? 'Waiting for data...';

  return (
    <div>
      <h1>Step {stepNumber} - Throttle Test</h1>
      <p className="instructions">{instruction}</p>
      
      {testFinished && (
        <div className={testResult ? "success-title" : "fail-title"}>
          <h3>{testResult ? "Test Successful" : "Test Failed"}</h3>
          {initialValueFailed 
            ? "Throttle initial state is not zero. This means that the throttle sensor is sending an input when not pressed, which can be dangerous and the throttle needs to be replaced."
            : maxThrottleReached < THROTTLE_MAX
              ? "The throttle did not reach 100%. If you pressed it completely and still get this error, the throttle likely needs to be replaced."
              : "The throttle is working correctly. Initial state was 0 and maximum throttle was reached."}
        </div>
      )}
      
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt={t("Warning")} />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      
      {testResult === null && (
        <>
          <p>Current Throttle: {getCurrentThrottlePercent(currentValue)}</p>
          <p>Maximum Throttle Reached: {getCurrentThrottlePercent(maxThrottleReached)}</p>
        </>
      )}
      
      {!testFinished && (
        <div>
          {hasStartedRef.current ? (
            <>
              <span className="countdown">Time remaining: {Math.ceil(countdown)} seconds</span>
              <ProgressBar
                now={(MAX_TEST_TIME - countdown) * 5}
                className={countdown > 0 ? 'in-progress' : 'timeout'}
              />
            </>
          ) : (
            <p>Waiting for throttle to be released...</p>
          )}
        </div>
      )}
      
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: maxThrottleReached,
            message: "The throttle is working correctly. Initial state was 0 and maximum throttle was reached."
          })}>Next</Button>
        </div>
      )}
      
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={restartTest}>
            Restart Test
          </Button>
          <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
            result: false, 
            data: maxThrottleReached,
            message: initialValueFailed
              ? "Throttle initial state is not zero. This means that the throttle sensor is sending an input when not pressed, which can be dangerous and the throttle needs to be replaced."
              : "The throttle did not reach 100%. If you pressed it completely and still get this error, the throttle likely needs to be replaced."
          })}>Skip Test</Button>
        </div>
      )}
    </div>
  );
};

export default ThrottleTest;