import React, { useState } from 'react';
import ManufacturingService from '../../services/manufacturingDatabase.service';
import { useTranslation } from 'react-i18next';

const QCComponent = () => {
  const { t } = useTranslation();
  const [serial, setSerial] = useState('');
  const [testResult, setTestResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSerialSubmit = async () => {
    if (!serial.trim()) {
      setError('Please enter a serial number');
      return;
    }
    setLoading(true);
    setError(null);
    setTestResult(null);
    try {
      const result = await ManufacturingService.getLastEOLTestResultAfterResin(serial);
      setTestResult(result);
    } catch (error) {
      console.error('Error fetching EOL test result after resin:', error);
      setError(error.message || 'Failed to fetch EOL test result.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSerialSubmit();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h4>{t('Quality Control Testing')}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2 className="text-secondary-color mb-4">{t('Test Results')}</h2>
        </div>
        <div className="col-md-6">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder={t('Enter serial number')}
              value={serial}
              onChange={(e) => setSerial(e.target.value)}
              onKeyPress={handleKeyPress}
              disabled={loading}
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary"
                onClick={handleSerialSubmit}
                disabled={loading}
              >
                {loading ? t('Checking...') : t('Check EOL After Resin Test')}
              </button>
            </div>
          </div>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </div>
        <div className="col-md-12">
          {testResult && (
            <div className="card">
              <div className="card-body">
                {testResult.result === 'NOT_AVAILABLE' ? (
                  <div className="alert alert-warning">
                    {testResult.message}
                  </div>
                ) : (
                  <div>
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <strong>{t('EOL Test Result')}:</strong>
                        <span className={`badge ${testResult.result === 'PASS' ? 'badge-success' : 'badge-danger'} ml-2`}>
                          {testResult.result}
                        </span>
                      </div>
                      {testResult.failReason && (
                        <div className="col-md-8">
                          <strong>{t('Fail Reason')}:</strong>
                          <span className="text-danger ml-2">{testResult.failReason}</span>
                        </div>
                      )}
                    </div>
                    <div className="row mb-3">
                      <div className="col-12">
                        <strong>{t('Latest Test Key')}:</strong>
                        <span className="ml-2">{testResult.latestTestKey}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <strong>{t('Test Details')}:</strong>
                        <pre className="bg-light p-3 mt-2 rounded">
                          {JSON.stringify(testResult.latestTest, null, 2)}
                        </pre>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QCComponent;