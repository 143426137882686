import React, { useState, useEffect, useRef } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useCANStream from '../../../hooks/useCANStream.hook';

const MAX_TEST_TIME = 20; // seconds
const POLL_INTERVAL = 100; // milliseconds
const MAX_ITERATIONS = (MAX_TEST_TIME * 1000) / POLL_INTERVAL;

const PASTest = ({
  onResult,
  stepNumber,
  primaryService,
  pin,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const { t } = useTranslation();
  
  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Please change the PAS level three times.");
  const [pasChangeCounter, setPasChangeCounter] = useState(0);
  const [countdown, setCountdown] = useState(MAX_TEST_TIME);
  
  const lastValue = useRef(null);
  const timerRef = useRef(null);
  const iterationCount = useRef(0);
  const hasStartedRef = useRef(false);

  // Configure stream indices
  const streamIndices = [{
    nodeId: 0x00000601,
    index: 0x2003,
    subIndex: 0x00
  }];

  const startTimer = () => {
    if (timerRef.current) return; // Don't start if already running
    
    console.log("Starting timer");
    timerRef.current = setInterval(() => {
      iterationCount.current += 1;
      
      if (iterationCount.current >= MAX_ITERATIONS) {
        cleanup();
        if (!testFinished) {
          handleTestStop(pasChangeCounter >= 3);
          setTestFinished(true);
        }
        return;
      }

      const timeElapsed = (iterationCount.current * POLL_INTERVAL) / 1000;
      setCountdown(Math.max(0, MAX_TEST_TIME - timeElapsed));
    }, POLL_INTERVAL);
  };

  // Set up CAN stream
  const { values, startStream, stopStream } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    streamIndices,
    () => {} // We'll handle values through the values array
  );

  // Monitor values array for changes
  useEffect(() => {
    if (!testRunning || testFinished) return;

    const currentValue = values?.[0];
    
    // Start test when we get first valid value
    if (!hasStartedRef.current && currentValue !== null && currentValue !== undefined) {
      console.log("Starting test with initial value:", currentValue);
      lastValue.current = currentValue;
      hasStartedRef.current = true;
      startTimer();
      return;
    }

    // Monitor for value changes
    if (hasStartedRef.current && currentValue !== null && currentValue !== undefined) {
      if (lastValue.current !== currentValue) {
        console.log("Value changed from", lastValue.current, "to", currentValue);
        const newCount = pasChangeCounter + 1;
        setPasChangeCounter(newCount);
        lastValue.current = currentValue;

        if (newCount >= 3) {
          console.log("Three changes detected - test passed!");
          handleTestStop(true);
          setTestFinished(true);
        }
      }
    }
  }, [values, testRunning, testFinished, pasChangeCounter]);

  // Cleanup function
  const cleanup = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    stopStream();
  };

  // Start stream on mount
  useEffect(() => {
    if (!testRunning && !testFinished) {
      console.log("Starting initial test setup");
      setTestRunning(true);
      startStream();
    }
    return () => cleanup();
  }, []);

  const handleTestStop = (result) => {
    setInstruction(null);
    setTestResult(result);
    setTestRunning(false);
    cleanup();
  };

  const restartTest = () => {
    cleanup();
    setTestRunning(true);
    setTestResult(null);
    setPasChangeCounter(0);
    lastValue.current = null;
    setInstruction("Please change the PAS level three times.");
    setCountdown(MAX_TEST_TIME);
    setTestFinished(false);
    hasStartedRef.current = false;
    iterationCount.current = 0;
    startStream();
  };

  const currentValue = values?.[0] ?? 'Waiting for data...';

  return (
    <div>
      <h1>Step {stepNumber} - Screen Communication Test</h1>
      <p className="instructions">{instruction}</p>
      
      {testFinished && (
        <div className={pasChangeCounter >= 3 ? "success-title" : "fail-title"}>
          <h3>{pasChangeCounter >= 3 ? "Test Successful" : "Test Failed"}</h3>
          {pasChangeCounter >= 3 
            ? "PAS successfully completed. Detecting PAS changes indicates that the screen is successfully communicating with the controller."
            : "PAS level changes not detected. This means either the PAS selector is not connected, broken, or that the screen is not communicating correctly with the controller."}
        </div>
      )}
      
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt={t("Warning")} />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      
      {testResult === null && (
        <>
          <p>Current PAS Level: {currentValue}</p>
          <p>Changes detected: {pasChangeCounter}</p>
        </>
      )}
      
      {!testFinished && (
        <div>
          {hasStartedRef.current ? (
            <>
              <span className="countdown">Time remaining: {Math.ceil(countdown)} seconds</span>
              <ProgressBar
                now={(MAX_TEST_TIME - countdown) * 5}
                className={countdown > 0 ? 'in-progress' : 'timeout'}
              />
            </>
          ) : (
            <p>Waiting for connection...</p>
          )}
        </div>
      )}
      
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: pasChangeCounter,
            message: "PAS successfully completed. Detecting PAS changes indicates that the screen is successfully communicating with the controller."
          })}>Next</Button>
        </div>
      )}
      
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={restartTest}>
            Restart Test
          </Button>
          <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
            result: false, 
            data: pasChangeCounter,
            message: "PAS level changes not detected. This means either the PAS selector is not connected, broken, or that the screen is not communicating correctly with the controller."
          })}>
            Skip Test
          </Button>
        </div>
      )}
    </div>
  );
};

export default PASTest;