import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useCAN } from './useCAN.hook'; // Import the useCAN hook

const useDiagnostics = ({
  connectToDevice,
  disconnectFromDevice,
  isConnected,
  stopNotificationListener,
  startNotificationListener,
  primaryService,
  verifyPin,
  writePassthroughCharacteristic,
  sendPassthroughRead,
  sendPassthroughWrite,
  getSendPassthroughRW,
}) => {
  const { getProtocolByParam } = useCAN();
  const { t } = useTranslation();
  const [pin, setPin] = useState('');
  const [pinVerified, setPinVerified] = useState(false);
  const [error, setError] = useState('');
  const [isPrimaryServiceAvailable, setIsPrimaryServiceAvailable] = useState(false);
  const [results, setResults] = useState([]);
  const [errors, setErrors] = useState([]);
  const [batteryStatusMessage, setBatteryStatusMessage] = useState('');
  const [brakeState, setBrakeState] = useState(null);
  const [frontLightState, setFrontLightState] = useState(null);
  const [rearLightState, setRearLightState] = useState(null);
  const [readWritePassthrough, setReadWritePassthrough] = useState(null);

  const gattQueueRef = useRef([]);
  const gattOperationInProgressRef = useRef(false);
  const intervalsRef = useRef([]);

  const errorDescriptions = {
    0x00000000: t("No error"),
    0x00000001: t("Throttle stuck"),
    0x00000002: t("Under voltage"),
    0x00000004: t("Over voltage"),
    0x00000008: t("Under temperature"),
    0x00000010: t("Motor Over temperature"),
    0x00000020: t("Motor foldback temperature"),
    0x00000040: t("Motor NTC Disconnected/ low temperature"),
    0x00000080: t("Controller over temperature"),
    0x00000100: t("Controller foldback temperature"),
    0x00000200: t("Motor Hall error"),
    0x00000400: t("Motor Phase Error"),
    0x00000800: t("IoT Comm Error"),
    0x00001000: t("Dual Comm Error"),
    0x00002000: t("Over Current"),
    0x00004000: t("Battery Low"),
    0x00008000: t("PAS Sensor Error"),
    0x00010000: t("Controller Error"),
    0x00020000: t("Brake Error"),
    0x00040000: t("Screen Communication Error"),
    0x00080000: t("Torque Sensor Stuck Error"),
    0x00200000: t("Battery Error"),
    0x00400000: t("Powertrain Locked")
  };

  const cloudDriveErrorMapping = {
    1: '7', // Throttle stuck
    2: '4', // Under voltage
    4: '12', // Over voltage
    8: '8', // Under temperature
    16: '11', // Motor Over temperature
    32: '11', // Motor foldback temperature
    64: '14', // Motor NTC Disconnected/ low temperature
    128: '9', // Controller over temperature
    256: '9', // Controller foldback temperature
    512: '6', // Motor Hall error
    1024: '3', // Motor Phase Error
    2048: '10', // IoT Comm Error
    16384: '13', // Battery Low
    32768: '1', // PAS Sensor Error
    65536: '2', // Controller Anomaly
    131072: '5'  // Brake Error
  };

  const baseCommands = [
    { interval: 0, category: 'Screen', subName: 'Max vehicle speed', protocol: 'CO_PARAM_MAX_VEHICLE_SPEED' },
    { interval: 0, category: 'Screen', subName: 'Walk mode speed', protocol: 'CO_PARAM_WALK_MODE_CONTROL' },
    { interval: 0, category: 'Screen', subName: 'Wheel diameter', protocol: 'CO_PARAM_WHEEL_DIAMETER' },
    { interval: 0, category: 'Screen', subName: 'Pulses per rotation', protocol: 'CO_PARAM_WHEELSPEED_SENSOR_PULSES_PER_ROTATION' },
    { interval: 0, category: 'Screen', subName: 'Current throttle ADC value', protocol: '' },
    { interval: 0, category: 'Screen', subName: 'Throttle value', protocol: 'CO_PARAM_THROTTLE_REALTIME_CONTROL' },
    { interval: 0, category: 'Screen', subName: 'Throttle min value', protocol: 'CO_PARAM_THROTTLE_CONFIG_MIN_VALUE' },
    { interval: 0, category: 'Screen', subName: 'Throttle max value', protocol: 'CO_PARAM_THROTTLE_CONFIG_MAX_VALUE' },
    { interval: 0, category: 'Screen', subName: 'Throttle enabled state', protocol: 'CO_PARAM_THROTTLE_CONFIG_ENABLED' },
    { interval: 0, category: 'Screen', subName: 'Throttle max speed', protocol: 'CO_PARAM_THROTTLE_CONFIG_MAX_SPEED' },
    { interval: 0, category: 'Screen', subName: 'Screen protocol selection', protocol: '' },
    { interval: 20, category: 'Battery', subName: 'Battery SOC', protocol: 'CO_PARAM_BATTERY_SOC' },
    { interval: 20, category: 'Battery', subName: 'Master bus voltage', protocol: 'CO_PARAM_LEGACY_VOLTAGE' },
    { interval: 0, category: 'Battery', subName: 'Battery full voltage', protocol: 'CO_PARAM_BATTERY_CONFIG_FULL_VOLTAGE' },
    { interval: 0, category: 'Battery', subName: 'Battery empty voltage', protocol: 'CO_PARAM_BATTERY_CONFIG_EMPTY_VOLTAGE' },
    { interval: 0, category: 'Battery', subName: 'Max DC current', protocol: 'CO_PARAM_BATTERY_CONFIG_MAX_CURRENT' },
    { interval: 0, category: 'Battery', subName: 'Continuous DC current', protocol: 'CO_PARAM_BATTERY_CONFIG_CONTINUOUS_CURRENT' },
    { interval: 0, category: 'Battery', subName: 'Peak current duration', protocol: 'CO_PARAM_BATTERY_CONFIG_MAX_TIME' },
    { interval: 10, category: 'Diagnostics and configuration', subName: 'Motor temperature', protocol: 'CO_PARAM_MOTOR_TEMPERATURE_MEASUREMENT' },
    { interval: 0, category: 'Diagnostics and configuration', subName: 'Max motor temperature', protocol: 'CO_PARAM_MOTOR_CONFIG_MAX_TEMP' },
    { interval: 10, category: 'Diagnostics and configuration', subName: 'Controller temperature', protocol: 'CO_PARAM_CONTROLLER_TEMPERATURE_MEASUREMENT' },
    { interval: 0, category: 'Diagnostics and configuration', subName: 'Max controller temperature', protocol: '' },
    { interval: 1, category: 'Live', subName: 'Vehicle speed with decimals', protocol: 'CO_PARAM_SPEED_DECIMAL' },
    { interval: 1, category: 'Live', subName: 'Real-time total power', protocol: 'CO_PARAM_TOTAL_POWER' },
    { interval: 1, category: 'Live', subName: 'Requested motor torque', protocol: 'CO_PARAM_REQUESTED_TORQUE' },
    { interval: 1, category: 'Live', subName: 'Mechanical power in motor', protocol: 'CO_PARAM_MECHANICAL_POWER' },
    { interval: 1, category: 'Live', subName: 'Active errors', protocol: 'CO_PARAM_ACTIVE_ERRORS' },
    { interval: 10, category: 'Live', subName: 'Motor temperature', protocol: 'CO_PARAM_MOTOR_TEMPERATURE_MEASUREMENT' },
    { interval: 10, category: 'Live', subName: 'Controller temperature', protocol: 'CO_PARAM_CONTROLLER_TEMPERATURE_MEASUREMENT' },
    { interval: 1, category: 'Live', subName: 'Current throttle ADC value', protocol: '' },
    { interval: 1, category: 'Live', subName: 'Throttle value', protocol: 'CO_PARAM_THROTTLE_REALTIME_CONTROL' },
    { interval: 1, category: 'Live', subName: 'Pedaling cadence', protocol: 'CO_PARAM_PAS_CADENCE_MEASUREMENT' },
    { interval: 1, category: 'Live', subName: 'Torque detected on pedals', protocol: 'CO_PARAM_PAS_TORQUE' },
    { interval: 1, category: 'Live', subName: 'Brake state', protocol: 'CO_PARAM_BRAKE_MEASUREMENT' },
    { interval: 1, category: 'Live', subName: 'PAS levels', protocol: 'CO_PARAM_PAS_LEVEL_CONTROL' },
    { interval: 1, category: 'Live', subName: 'Front light state', protocol: 'CO_PARAM_TOGGLABLE_OUTPUT_1_CONTROL' },
    { interval: 1, category: 'Live', subName: 'Rear light state', protocol: 'CO_PARAM_TOGGLABLE_OUTPUT_2_CONTROL' }
  ];

  // Process commands to add protocol information
  const commands = baseCommands.map(cmd => {
    if (!cmd.protocol) return cmd;
    
    const protocolInfo = getProtocolByParam(cmd.protocol);
    if (!protocolInfo) return cmd;

    // Convert hex string to number for index
    const index = parseInt(protocolInfo.Index, 16);
    const subIndex = parseInt(protocolInfo.Subindex, 16);

    return {
      ...cmd,
      index,
      subIndex,
      description: protocolInfo.Description,
      unit: protocolInfo.Unit || ''
    };
  });

  const batteryCommands = commands.filter(command => command.category === 'Battery' || command.category.includes('Battery'));
  const liveCommands = commands.filter(command => command.category === 'Live' || command.category.includes('Live'));
  const otherCommands = commands.filter(command => !batteryCommands.includes(command) && !liveCommands.includes(command));

  useEffect(() => {
    if (primaryService) {
      setIsPrimaryServiceAvailable(true);
    } else {
      setIsPrimaryServiceAvailable(false);
    }

    if (primaryService && pinVerified) {
      getSendPassthroughRW(primaryService).then(sendFunction => {
        if (sendFunction) {
          setReadWritePassthrough(() => sendFunction);
        } else {
          console.error('Failed to initialize passthrough function');
        }
      });
    }
  }, [primaryService, pinVerified, pin]);

  useEffect(() => {
    if (typeof readWritePassthrough === "function") {
      runDiagnosticsCommands(commands);
      runNextGattOperation();
      startIntervals();
    }
  }, [readWritePassthrough]);

  useEffect(() => {
    const masterBusVoltageResult = results.find(result => result.index === 0x200A && result.subIndex === 0x00);
    const batteryFullVoltageResult = results.find(result => result.index === 0x201E && result.subIndex === 0x00);
    const batteryEmptyVoltageResult = results.find(result => result.index === 0x201E && result.subIndex === 0x01);

    if (masterBusVoltageResult && batteryFullVoltageResult && batteryEmptyVoltageResult) {
      const masterBusVoltage = masterBusVoltageResult.latestValue;
      const batteryFullVoltage = batteryFullVoltageResult.latestValue;
      const batteryEmptyVoltage = batteryEmptyVoltageResult.latestValue;

      if (masterBusVoltage > batteryFullVoltage || masterBusVoltage < batteryEmptyVoltage) {
        setBatteryStatusMessage(t('There is a battery problem.'));
      } else {
        setBatteryStatusMessage(t('Battery diagnostics has passed.'));
      }
    }
  }, [results, t]);

  const decodePassthroughResponse = (response) => {
    if (response.length < 9) {
      console.error('Invalid response length:', response.length);
      return null;
    }

    const canHeader = response.slice(1, 5);
    const canDataBytes = response.slice(5, 9);
    const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

    const index = (canHeader[2] << 8) | canHeader[1];
    const subIndex = canHeader[3];

    return { index, subIndex, canData };
  };

  const decodeErrorResponse = (value) => {
    const errorValue = new DataView(new ArrayBuffer(8));
    new Uint8Array(errorValue.buffer).set(new Uint8Array(value.slice(1, 9)));

    const errorValueString = JSON.stringify(Array.from(new Uint8Array(errorValue.buffer)));
    console.log('error detected: ' + errorValueString);

    return readErrorCodeCharacteristic(errorValue);
  };

  const readErrorCodeCharacteristic = (dataView) => {
    if (dataView.byteLength !== 8) {
      throw new Error("Invalid data length for error code characteristic");
    }
    const errorCode = dataView.getUint32(0, true);
    const timestamp = dataView.getUint32(4, true);
    const binaryString = errorCode.toString(2).padStart(32, '0');
    console.log('error code char', errorCode, binaryString, dataView);

    const currentErrors = [];
    Object.keys(errorDescriptions).forEach((key, index) => {
      if (binaryString[31 - index] === '1') {
        currentErrors.push({
          code: cloudDriveErrorMapping[key],
          description: errorDescriptions[key],
          timestamp: new Date(timestamp * 1000).toISOString(),
        });
      }
    });
    return currentErrors.length > 0 ? currentErrors : [{
      code: '0',
      description: 'No error',
      timestamp: new Date(timestamp * 1000).toISOString(),
    }];
  };

  const notificationCallback = (event) => {
    if (event) {
      const value = new Uint8Array(event.buffer);
      console.log('USEDIAGNOSTICS NOTIFICATION');
      const messageType = String.fromCharCode(value[0]);

      if (messageType === 'E') {
        const decodedErrors = decodeErrorResponse(value);
        setErrors(decodedErrors);
      } else if (messageType === 'P') {
        const { index, subIndex, canData } = decodePassthroughResponse(value);
        if (canData !== null) {
          setResults((prevResults) => {
            const existingResultIndex = prevResults.findIndex(result => result.index === index && result.subIndex === subIndex);
            const currentTime = new Date().toISOString();

            if (existingResultIndex !== -1) {
              const updatedResults = [...prevResults];
              updatedResults[existingResultIndex] = {
                ...updatedResults[existingResultIndex],
                latestValue: canData,
                time: currentTime,
              };
              return updatedResults;
            } else {
              const command = commands.find(cmd => cmd.index === index && cmd.subIndex === subIndex);
              if (command) {
                const newResults = [
                  ...prevResults,
                  {
                    category: command.category,
                    name: command.name,
                    subName: command.subName,
                    description: command.description,
                    unit: command.unit,
                    index,
                    subIndex,
                    latestValue: canData,
                    time: currentTime,
                  },
                ];
                return newResults;
              }
              return prevResults;
            }
          });

          if (index === 0x2021 && subIndex === 0) {
            setFrontLightState(canData === 1 ? 'Front Light ON' : 'Front Light OFF');
          }
          if (index === 0x2022 && subIndex === 0) {
            setRearLightState(canData === 1 ? 'Rear Light ON' : 'Rear Light OFF');
          }
          if (index === 0x202C && subIndex === 0) {
            setBrakeState(canData === 1);
          }
        } else {
          console.error('Failed to decode response:', value);
        }
      } else {
        console.error('Unexpected format or type in notification value:', value);
      }
    } else {
      console.error('Invalid event structure:', event);
    }
  };

  const enqueueGattOperation = async (args = []) => {
    const operationKey = `function-${JSON.stringify(args)}`;
    const isDuplicate = gattQueueRef.current.some(op => op.key === operationKey);

    if (args[0] === 'write') {
      gattQueueRef.current.push({ args, key: operationKey });
    } else if (!isDuplicate) {
      gattQueueRef.current.push({ args, key: operationKey });
    }
  };

  const runNextGattOperation = async () => {
    if (primaryService && pinVerified && readWritePassthrough) {
      if (!gattOperationInProgressRef.current && gattQueueRef.current.length > 0) {
        const nextOperationIndex = gattQueueRef.current.findIndex(op => op.args[0] === 'write');
        const operation = nextOperationIndex !== -1 ? gattQueueRef.current.splice(nextOperationIndex, 1)[0] : gattQueueRef.current.shift();

        try {
          gattOperationInProgressRef.current = true;
          await readWritePassthrough(...operation.args);
        } catch (error) {
          console.error('Error running GATT operation:', error);
        } finally {
          gattOperationInProgressRef.current = false;
          runNextGattOperation(); // Continue with the next operation
        }
      } else {
        setTimeout(runNextGattOperation, 50); // Wait 50ms before trying again
      }
    }
  };

  const startIntervals = () => {
    stopIntervals();
    const intervalsMap = {};

    if (intervalsRef.current) {
      intervalsRef.current.forEach(clearInterval);
      intervalsRef.current = [];
    }

    commands.forEach((command) => {
      if (command.interval > 0) {
        if (!intervalsMap[command.interval]) {
          intervalsMap[command.interval] = [];
        }
        intervalsMap[command.interval].push(command);
      } else {
        // Run commands with interval 0 once at the start
        enqueueGattOperation(['read', pin, 0x00000601, command.index, command.subIndex]);
      }
    });

    intervalsRef.current = Object.keys(intervalsMap).map(interval => {
      const commandList = intervalsMap[interval];
      if (commandList.length > 0) {
        return setInterval(() => {
          runDiagnosticsCommands(commandList);
        }, interval * 250); // Ensure interval is in milliseconds
      }
      return null;
    }).filter(interval => interval !== null);
  };

  const runDiagnosticsCommands = async (commandList) => {
    if (!Array.isArray(commandList)) {
      commandList = commands;
    }
    for (const command of commandList) {
      try {
        enqueueGattOperation(['read', pin, 0x00000601, command.index, command.subIndex]);
      } catch (error) {
        console.error('Error sending command:', command, error);
      }
    }
  };

  useEffect(() => {
    return () => {
      stopIntervals();
    };
  }, []);

  const stopIntervals = () => {
    if (intervalsRef.current) {
      intervalsRef.current.forEach(clearInterval);
      intervalsRef.current = [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const isValid = await verifyPin(pin, primaryService);
      if (isValid) {
        await stopNotificationListener(primaryService);
        await startNotificationListener(primaryService, notificationCallback);
        setError('');
        setPinVerified(true);
        return true;
      } else {
        setError(t('Invalid PIN'));
        return false;
      }
    } catch (err) {
      setError(t('Error verifying PIN'));
      return false;
    }
  };

  const handleSubmitOnly = async (e) => {
    e.preventDefault();
    try {
      const isValid = await verifyPin(pin, primaryService);
      if (isValid) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  const runDiagnosticsCommandsAfterWait = async () => {
    try {
      await runDiagnosticsCommands();
    } catch (err) {
      setError(t('Error verifying PIN'));
    }
  };

  const handleFrontLightNotification = (event) => {
    const value = new Uint8Array(event.buffer);
    setFrontLightState(value[0] === 1 ? 'Front Light ON' : 'Front Light OFF');
  };

  const handleRearLightNotification = (event) => {
    const value = new Uint8Array(event.buffer);
    setRearLightState(value[0] === 1 ? 'Rear Light ON' : 'Rear Light OFF');
  };

  const toggleFrontLight = async () => {
    const newState = frontLightState === 'Front Light ON' ? 0 : 1;
    enqueueGattOperation(['write', pin, 0x00000601, 0x2021, 0x00, newState]);
  };

  const toggleRearLight = async () => {
    const newState = rearLightState === 'Rear Light ON' ? 0 : 1;
    enqueueGattOperation(['write', pin, 0x00000601, 0x2022, 0x00, newState]);
  };

  const stopUpdates = () => {
    console.log('stopUpdates', intervalsRef.current); 
    gattOperationInProgressRef.current = true;
    if (intervalsRef.current) {
      intervalsRef.current.forEach(clearInterval);
      intervalsRef.current = [];
    }

    gattQueueRef.current = [];
  };

  const restartUpdates = async () => {
    gattOperationInProgressRef.current = false;
    await stopNotificationListener(primaryService);
    await startNotificationListener(primaryService, notificationCallback);

    startIntervals();
    runNextGattOperation();
  };

  const restartUpdatesOnly = async () => {
    gattOperationInProgressRef.current = false;
    startIntervals();
    runNextGattOperation();
  };

  return {
    t,
    pin,
    setPin,
    error,
    setError,
    isPrimaryServiceAvailable,
    connectToDevice,
    disconnectFromDevice,
    isConnected,
    pinVerified,
    setPinVerified,
    results,
    batteryCommands,
    liveCommands,
    otherCommands,
    batteryStatusMessage,
    errors,
    handleSubmit,
    handleSubmitOnly,
    runDiagnosticsCommands,
    brakeState,
    frontLightState,
    rearLightState,
    toggleFrontLight,
    toggleRearLight,
    getSendPassthroughRW,
    primaryService,
    stopUpdates,
    restartUpdates,
    restartUpdatesOnly,
    stopNotificationListener,
    startNotificationListener,
  };
};

export default useDiagnostics;