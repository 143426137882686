import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import FormComponent from './form.component';

const TabComponent = ({ item, mapping, updateMapping, reloadSingleValue }) => {
    const tabs = mapping[item] ? Object.keys(mapping[item]).map(tab => tab.toLowerCase()) : [];
    const { tab } = useParams();
    const navigate = useNavigate();
    const [key, setKey] = useState(tabs[0]);
    const [maxPASLevels, setMaxPASLevels] = useState(9); // Default to max value
    const [regenType, setRegenType] = useState(0); // Default to max value

    useEffect(() => {
        if (tabs.includes(tab?.toLowerCase())) {
            setKey(tab.toLowerCase());
        } else {
            if (tab === undefined) {
                setKey(tabs[0]);
                navigate(`/configuration/${item.toLowerCase()}/${tabs[0]}`);
            }
        }
    }, [tab, tabs, item, navigate]);

    useEffect(() => {
        Object.keys(mapping[item]).forEach(tabKey => {
            const tabVariables = mapping[item][tabKey].variables;
            for (let variableKey in tabVariables) {
                const variable = tabVariables[variableKey];
                if (variable.showhide === 'PAS') {
                    setMaxPASLevels(variable.value || 9); // Set the max PAS levels based on the loaded value
                    break;
                }
            }
        });
    }, [item]);

    useEffect(() => {
        Object.keys(mapping[item]).forEach(tabKey => {
            const tabVariables = mapping[item][tabKey].variables;
            for (let variableKey in tabVariables) {
                const variable = tabVariables[variableKey];
                if (variable.showhide === 'BRAKE_REGEN') {
                    setRegenType(variable.value || 0);
                    break;
                }
            }
        });
    }, [item]);

    const handleSelect = (k) => {
        setKey(k);
        navigate(`/configuration/${item.toLowerCase()}/${k}`);
    };

    const handleMaxPASChange = (newMaxPASLevels) => {
        setMaxPASLevels(newMaxPASLevels);
    };

    const handleRegenTypeChange = (newRegenType) => {
        setRegenType(newRegenType);
    };

    return (
        <div className="row">
            <div className="col">
                <Tabs id="controlled-tab" activeKey={key} onSelect={handleSelect} className="mt-4 mb-4">
                    {Object.keys(mapping[item]).map((tabItem, index) => (
                        <Tab eventKey={tabItem.toLowerCase()} title={mapping[item][tabItem].tab_title} key={index}>
                            <FormComponent 
                                variables={mapping[item][tabItem].variables} 
                                updateMapping={updateMapping} 
                                maxPASLevels={maxPASLevels} 
                                regenType={regenType}
                                onMaxPASChange={handleMaxPASChange}
                                onRegenTypeChange={handleRegenTypeChange}
                                reloadSingleValue={reloadSingleValue}
                            />
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </div>
    );
};

export default TabComponent;
