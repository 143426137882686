import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import AutotuneModal from './autotune.component';
import DFUModal from './DFU.component';

const SideMenu = ({
    menuItems,
    isConnected,
    disconnectFromDevice,
    dataLoaded,
    mapping,
    updateMapping,
    resetStates,
    performFirmwareUpdate,
    primaryService,
    updateControllerSettings,
    readPassthroughValue,
    pin,
    reloadAllValues,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    
    const [showExportModal, setShowExportModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [showFirmwareUpdateModal, setShowFirmwareUpdateModal] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showAutotuneModal, setShowAutotuneModal] = useState(false);
    const [fileName, setFileName] = useState('');
    const [importFile, setImportFile] = useState(null);
    const [importError, setImportError] = useState('');
    const [saving, setSaving] = useState(false);
    const [saveComplete, setSaveComplete] = useState(false);
    const [saveResults, setSaveResults] = useState([]);
    const [updatedVariables, setUpdatedVariables] = useState([]);

    const handleDisconnectClick = () => {
        disconnectFromDevice();
        resetStates();
        navigate('/configuration/pin-entry');
    };

    const handleExportClick = () => {
        setShowExportModal(true);
    };

    const handleImportClick = () => {
        setShowImportModal(true);
    };

    const handleFirmwareUpdateClick = () => {
        setShowFirmwareUpdateModal(true);
    };

    const handleDownload = () => {
        const element = document.createElement('a');
        const file = new Blob([JSON.stringify(mapping, null, 2)], { type: 'application/json' });
        element.href = URL.createObjectURL(file);
        element.download = `${fileName || 'mapping'}.json`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        setShowExportModal(false);
    };

    const handleDropImportFile = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.name.endsWith('.json')) {
            setImportFile(file);
            setImportError('');
        } else {
            setImportError('Please select a valid .json file.');
        }
    };

    const { getRootProps: getRootPropsImport, getInputProps: getInputPropsImport } = useDropzone({
        onDrop: handleDropImportFile,
        accept: '.json',
        multiple: false,
    });

    const handleLoad = () => {
        if (importFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const importedMapping = JSON.parse(e.target.result);
                    const updatedMapping = { ...mapping };
                    Object.keys(importedMapping).forEach((categoryKey) => {
                        const category = importedMapping[categoryKey];
                        Object.keys(category).forEach((subCategoryKey) => {
                            const subCategory = category[subCategoryKey];
                            if (subCategory.variables) {
                                Object.keys(subCategory.variables).forEach((variableKey) => {
                                    const variable = subCategory.variables[variableKey];
                                    if (variable.index && variable.sub_index && variable.value) {
                                        const currentVariable = updatedMapping[categoryKey]?.[subCategoryKey]?.variables?.[variableKey];
                                        if (currentVariable && currentVariable.index === variable.index && currentVariable.sub_index === variable.sub_index) {
                                            if (updatedMapping[categoryKey][subCategoryKey].variables[variableKey].value !== variable.value) {
                                                updatedMapping[categoryKey][subCategoryKey].variables[variableKey].value = variable.value;
                                                updatedMapping[categoryKey][subCategoryKey].variables[variableKey].updated = true;                                                
                                            }
                                        }
                                    }
                                });
                            }
                        });
                    });
                    updateMapping(updatedMapping);
                    setShowImportModal(false);
                } catch (error) {
                    setImportError(t('Invalid JSON file.'));
                }
            };
            reader.readAsText(importFile);
        } else {
            setImportError(t('Please select a file.'));
        }
    };

    const handleSaveSettings = async () => {
        setSaving(true);
        setSaveComplete(false);
        setSaveResults([]);

        const updatedMapping = { ...mapping };
        const updates = [];

        for (const [categoryKey, category] of Object.entries(mapping)) {
            for (const [subCategoryKey, subCategory] of Object.entries(category)) {
                for (const [variableKey, variableDetails] of Object.entries(subCategory.variables)) {
                    if (variableDetails.updated) {
                        updates.push({
                            index: variableDetails.index,
                            sub_index: variableDetails.sub_index,
                            newValue: variableDetails.value,
                            size: variableDetails.size,
                            categoryKey,
                            subCategoryKey,
                            variableKey,
                            inputTitle: variableDetails.input_title,
                        });
                    }
                }
            }
        }

        try {
            await updateControllerSettings(pin, updates, primaryService);
            let allVerified = true;
            let anyError = false;

            for (const update of updates) {
                const { index, sub_index, newValue, categoryKey, subCategoryKey, variableKey, inputTitle } = update;
                
                await new Promise(resolve => setTimeout(resolve, 500));
                const readValue = await readPassthroughValue(pin, 0x00000601, parseInt(index, 16), parseInt(sub_index, 16), primaryService);
                if (readValue !== newValue) {
                    allVerified = false;
                    anyError = true;
                    updatedMapping[categoryKey][subCategoryKey].variables[variableKey].updated = true;
                    updatedMapping[categoryKey][subCategoryKey].variables[variableKey].error = true;
                    setSaveResults(prevResults => [...prevResults, { variable: inputTitle, status: 'error' }]);
                } else {
                    updatedMapping[categoryKey][subCategoryKey].variables[variableKey].updated = false;
                    updatedMapping[categoryKey][subCategoryKey].variables[variableKey].error = false;
                    setSaveResults(prevResults => [...prevResults, { variable: inputTitle, status: 'updated' }]);
                }
            }

            updateMapping(updatedMapping);
            setSaveComplete(allVerified && !anyError ? true : 'partial');

        } catch (error) {
            console.error('Error saving settings:', error);
        }
        setSaving(false);
    };

    const openSaveSettingsModal = () => {
        const updates = [];
        Object.entries(mapping).forEach(([categoryKey, category]) => {
            Object.entries(category).forEach(([subCategoryKey, subCategory]) => {
                Object.entries(subCategory.variables).forEach(([variableKey, variableDetails]) => {
                    if (variableDetails.updated) {
                        updates.push(variableDetails);
                    }
                });
            });
        });

        setUpdatedVariables(updates);
        setShowSaveModal(true);
    };

    const hideSaveSettingsModal = () => {
        setSaveComplete(false);
        setSaveResults([]);
        setUpdatedVariables([]);
        setShowSaveModal(false);
    };

    return (
        isConnected && dataLoaded && (
            <div className="ftex-side-menu">
                <ul>
                    {menuItems.map((item, index) => (
                        <li key={index} className={location.pathname.includes(`/configuration/${item.toLowerCase()}`) ? 'active' : ''}>
                            <Link to={`/configuration/${item.toLowerCase()}`}>{t(item)}</Link>
                        </li>
                    ))}
                </ul>
                <button className="btn btn-block btn-big btn-secondary-reverse" onClick={handleExportClick}>
                    {t('Export')}
                </button>
                <button className="btn btn-block btn-big btn-secondary-reverse" onClick={handleImportClick}>
                    {t('Import')}
                </button>
                <button className="btn btn-block btn-big btn-secondary-reverse" onClick={reloadAllValues}>
                    {t('Reload All Values')}
                </button>
                <button className="btn btn-block btn-big btn-info" onClick={() => setShowAutotuneModal(true)}>
                    {t('Autotune')}
                </button>
                <button className="btn btn-block btn-big btn-secondary" onClick={handleDisconnectClick}>
                    {t('Disconnect')}
                </button>
                <button className="btn btn-block btn-big btn-danger" onClick={openSaveSettingsModal}>
                    {t('Save Settings')}
                </button>
                <button className="btn btn-block btn-big btn-primary" onClick={handleFirmwareUpdateClick}>
                    {t('Firmware Update')}
                </button>

                {/* Export Modal */}
                <Modal show={showExportModal} onHide={() => setShowExportModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Export Mapping')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formFileName">
                                <Form.Label>{t('Enter file name')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={fileName}
                                    onChange={(e) => setFileName(e.target.value)}
                                    placeholder={t('File name')}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowExportModal(false)}>
                            {t('Cancel')}
                        </Button>
                        <Button variant="primary" onClick={handleDownload}>
                            {t('Download')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Import Modal */}
                <Modal show={showImportModal} onHide={() => setShowImportModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Import Mapping')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formFileUpload">
                                <Form.Label>{t('Upload JSON file')}</Form.Label>
                                <div {...getRootPropsImport()} className="dropzone">
                                    <input {...getInputPropsImport()} />
                                    <p>{t('Drag & drop a JSON file here, or click to select one')}</p>
                                    {importFile && <p>{t('Selected file:')} {importFile.name}</p>}
                                </div>
                            </Form.Group>
                            {importError && <div className="text-danger">{importError}</div>}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowImportModal(false)}>
                            {t('Cancel')}
                        </Button>
                        <Button variant="primary" onClick={handleLoad}>
                            {t('Load')}
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Save Settings Modal */}
                <Modal show={showSaveModal} onHide={hideSaveSettingsModal}>
                    <Modal.Header closeButton={!saving}>
                        <Modal.Title>{t('Save Settings')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {saveResults.length === 0 ? (
                            <>
                                <p>{t('Are you sure you would like to save these settings to your controller?')}</p>
                                {updatedVariables.map((variable, index) => (
                                    <li key={index}>
                                        {variable.input_title}
                                    </li>
                                ))}
                            </>
                        ) : (
                            <>
                                <ul>
                                    {saveResults.map((result, index) => (
                                        <li 
                                            key={index} 
                                            style={{ color: result.status === 'updated' ? 'green' : 'red' }}
                                        >
                                            {result.status === 'updated' ? t('Updated successfully') : t('Error saving')}
                                        </li>
                                    ))}
                                </ul>
                                {saveResults.length === updatedVariables.length && (
                                    saveResults.every(result => result.status === 'updated') ? (
                                        <p style={{ color: 'green' }}>{t('Settings have been successfully saved.')}</p>
                                    ) : saveResults.every(result => result.status === 'error') ? (
                                        <p style={{ color: 'red' }}>{t('Error: the settings have not been updated.')}</p>
                                    ) : (
                                        <p style={{ color: 'orange' }}>{t('Settings have been saved with some errors.')}</p>
                                    )
                                )}
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {saveComplete ? (
                            <Button variant="primary" onClick={hideSaveSettingsModal}>
                                {t('Dismiss')}
                            </Button>
                        ) : (
                            <>
                                <Button variant="secondary" onClick={hideSaveSettingsModal} disabled={saving}>
                                    {t('Cancel')}
                                </Button>
                                <Button variant="primary" onClick={handleSaveSettings} disabled={saving}>
                                    {t('Yes, Save Settings')}
                                </Button>
                            </>
                        )}
                    </Modal.Footer>
                </Modal>

                {/* Autotune Modal */}
                <AutotuneModal 
                    show={showAutotuneModal}
                    onHide={() => setShowAutotuneModal(false)}
                    primaryService={primaryService}
                    pin={pin}
                    updateControllerSettings={updateControllerSettings}
                    readPassthroughValue={readPassthroughValue}
                />

                {/* DFU Modal */}
                <DFUModal 
                    show={showFirmwareUpdateModal}
                    onHide={() => setShowFirmwareUpdateModal(false)}
                    primaryService={primaryService}
                    performFirmwareUpdate={performFirmwareUpdate}
                    pin={pin}
                />
            </div>
        )
    );
};

export default SideMenu;