import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Import test components
import Intro from './tests/intro.component';
import PASTest from './tests/PAS.component';
import CadenceTest from './tests/cadence.component';
import TorqueTest from './tests/torque.component';
import LeftBrakeTest from './tests/leftBrake.component';
import RightBrakeTest from './tests/rightBrake.component';
import ThrottleTest from './tests/throttle.component';
import FrontLightTest from './tests/frontLight.component';
import RearLightTest from './tests/rearLight.component';
import SpeedMotorTest from './tests/speedMotor.component';

const BikeTestMain = ({
  show,
  setDiagnosticsResults,
  diagnosticsResults,
  compKey,
  onHide,
  primaryService,
  pin,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const instanceId = useRef(`instance-${Math.random().toString(36).substr(2, 9)}`);
  const testListRef = useRef(null);
  const [testItemHeight, setTestItemHeight] = useState(0);
  const { t } = useTranslation();

  // Map test names to components
  const testsMap = {
    intro: Intro,
    PAS: PASTest,
    leftBrake: LeftBrakeTest,
    rightBrake: RightBrakeTest,
    cadence: CadenceTest,
    torque: TorqueTest,
    throttle: ThrottleTest,
    frontLight: FrontLightTest,
    rearLight: RearLightTest,
    speedMotor: SpeedMotorTest,
  };

  const tests = [
                  {
                    file: 'intro',
                    name: t('Intro')
                  },
                  {
                    file: 'PAS',
                    name: t('Screen Test')
                  },
                  {
                    file: 'leftBrake',
                    name: t('Left Brake')
                  },
                  {
                    file: 'rightBrake',
                    name: t('Right Brake')
                  },
                  {
                    file: 'cadence',
                    name: t('Cadence Test')
                  },
                  {
                    file: 'torque',
                    name: t('Torque Test')
                  },
                  {
                    file: 'throttle',
                    name: t('Throttle Test')
                  },
                  {
                    file: 'frontLight',
                    name: t('Front Light')
                  },
                  {
                    file: 'rearLight',
                    name: t('Rear Light')
                  },
                  {
                    file: 'speedMotor',
                    name: t('Speed and Motor')
                  }
                ];


  useEffect(() => {
    if (show) {
      setCurrentStep(0);
      setIsInitialized(true);
    } else {
      setIsInitialized(false);
    }
  }, [show]);

  useEffect(() => {
    if (testListRef.current) {
      const testItem = testListRef.current.querySelector('.test-item');
      const separator = testListRef.current.querySelector('.test-separator');
      const itemHeight = testItem?.offsetHeight || 0;
      const separatorHeight = separator?.clientHeight || 0;
      setTestItemHeight(itemHeight + separatorHeight);
    }
  }, [isInitialized]);

  const handleResult = useCallback((result) => {
    setDiagnosticsResults((prevResults) => ({
      ...prevResults,
      [tests[currentStep].file]: result,
    }));

    if (currentStep < tests.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onHide();
    }
  }, [currentStep, tests, onHide, setDiagnosticsResults]);

  const calculateMargin = () => {
    let margin = 0;
    for (let i = 0; i < currentStep; i++) {
      margin -= testItemHeight;
    }
    return margin;
  };

  const getTestClass = (index) => {
    if (index < currentStep) {
      return diagnosticsResults[tests[index]?.file]?.result ? 'success' : 'fail';
    } else if (index === currentStep) {
      return 'active';
    }
    return '';
  };

  const CurrentTestComponent = useMemo(() => {
    const TestComponent = testsMap[tests[currentStep]?.file];
    if (!TestComponent) return null;

    return TestComponent;
  }, [tests, currentStep]);

  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      size="full-screen" 
      className="diagnostics-test-modal" 
      backdrop="static" 
      keyboard={false}
    >
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 side-col">
              <div className="test-list-container">
                <div className="test-list" ref={testListRef} style={{ marginTop: calculateMargin() }}>
                  {tests.map((test, index) => (
                    <React.Fragment key={index}>
                      <div className={`test-item ${getTestClass(index)}`}>
                        <span className="test-index">0{index + 1}</span>
                        <span className="test-name">{test.name}</span>
                      </div>
                      {index < tests.length - 1 && (
                        <div className="test-separator">
                          <span></span><span></span><span></span><span></span>
                          <span></span><span></span><span></span><span></span>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-9 main-col">
              <div className="row">
                <div className="col-11 offset-1">
                  {isInitialized && CurrentTestComponent && show && (
                    <CurrentTestComponent
                      key={`${currentStep}-${compKey}`}
                      stepNumber={`${currentStep + 1}`}
                      onResult={handleResult}
                      primaryService={primaryService}
                      pin={pin}
                      getSendPassthroughRW={getSendPassthroughRW}
                      stopNotificationListener={stopNotificationListener}
                      startNotificationListener={startNotificationListener}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>      
      </Modal.Body>
      <Modal.Footer>
        <Button variant="btn btn-primary" onClick={onHide}>
          End Test
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BikeTestMain;