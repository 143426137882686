import React, { useState, useEffect, useRef } from 'react';
import { Button, Alert, ProgressBar } from 'react-bootstrap';
import useCANStream from '../../../hooks/useCANStream.hook';

const MotorTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  // Test states
  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState(
    "Please lift the rear wheel from the floor. Click 'Ready' when you are ready."
  );
  const [currentSpeed, setCurrentSpeed] = useState(0);
  const [currentPAS, setCurrentPAS] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [activeErrors, setActiveErrors] = useState([]);
  
  // Test tracking refs
  const startTime = useRef(null);
  const testStep = useRef(0); // 0: initial, 1: waiting for PAS, 2: testing
  const speedTimer = useRef(null);
  const successfulSpeedDuration = useRef(0);

  // Configure stream indices
  const streamIndices = [
    { nodeId: 0x00000601, index: 0x2000, subIndex: 0x00 }, // Speed integer
    { nodeId: 0x00000601, index: 0x2000, subIndex: 0x01 }, // Speed decimal
    { nodeId: 0x00000601, index: 0x2003, subIndex: 0x00 }, // PAS level
    { nodeId: 0x00000601, index: 0x2006, subIndex: 0x00 }  // Errors
  ];

  // Set up CAN stream
  const { values, startStream, stopStream } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    streamIndices,
    () => {}
  );

  // Decode error bitmask into descriptions
  const decodeErrors = (errorBitmask) => {
    const errors = [];
    if (errorBitmask & 0x1) errors.push({ code: '0x1', description: 'Throttle Error' });
    if (errorBitmask & 0x200) errors.push({ code: '0x200', description: 'Motor Hall Sensor Error' });
    if (errorBitmask & 0x400) errors.push({ code: '0x400', description: 'Motor Phase Error' });
    if (errorBitmask & 0x2000) errors.push({ code: '0x2000', description: 'Controller Overcurrent Warning' });
    // Add other error codes as needed
    return errors;
  };

  // Monitor values changes
  useEffect(() => {
    if (!testRunning || testFinished) return;

    const speedInt = values?.[0] || 0;
    const speedDec = values?.[1] || 0;
    const speed = speedInt + (speedDec / 10);
    const pas = values?.[2];
    const errors = values?.[3];

    setCurrentSpeed(speed);
    setCurrentPAS(pas);

    if (errors) {
      const decodedErrors = decodeErrors(errors);
      setActiveErrors(decodedErrors);
      
      if (decodedErrors.length > 0) {
        handleTestStop(false, 'Test failed due to detected errors');
      }
    }

    // Handle test steps
    if (testStep.current === 1 && pas === 1) {
      setInstruction("Press and hold the throttle.");
      startTime.current = Date.now();
      testStep.current = 2;
    }

    // Track speed above 10km/h
    if (testStep.current === 2 && speed > 10) {
      if (!speedTimer.current) {
        speedTimer.current = Date.now();
      }
      const speedDuration = (Date.now() - speedTimer.current) / 1000;
      if (speedDuration >= 5) {
        handleTestStop(true, 'Test completed successfully');
      }
    } else {
      speedTimer.current = null; // Reset timer if speed drops below threshold
    }
  }, [values, testRunning, testFinished]);

  // Progress timer
  useEffect(() => {
    if (!testRunning || testFinished || testStep.current !== 2) return;  // Only start timer after PAS is set

    const timer = setInterval(() => {
      const elapsed = (Date.now() - startTime.current) / 1000;
      setElapsedTime(elapsed);
      
      if (elapsed >= 20) {
        handleTestStop(false, 'Test timeout - speed threshold not maintained');
      }
    }, 100); // Update every 100ms for smooth progress

    return () => clearInterval(timer);
  }, [testRunning, testFinished, testStep.current]);

  const startTesting = () => {
    testStep.current = 1;  // Set to waiting for PAS
    setTestRunning(true);
    setInstruction("Set PAS level to 1");
    startStream();
  };

  const handleTestStop = (result, message) => {
    setTestResult(result);
    setInstruction(message);
    setTestRunning(false);
    setTestFinished(true);
    stopStream();
  };

  const resetTest = () => {
    stopStream();
    setTestRunning(false);
    setTestFinished(false);
    setTestResult(null);
    setInstruction("Please lift the rear wheel from the floor. Click 'Ready' when you are ready.");
    setCurrentSpeed(0);
    setCurrentPAS(0);
    setActiveErrors([]);
    testStep.current = 0;
    successfulSpeedDuration.current = 0;
  };

  return (
    <div>
      <h1>Step {stepNumber} - Motor Test</h1>
      
      <Alert variant="warning">
        Warning: The motor will begin spinning. Please lift the rear wheel from the floor.
      </Alert>
      
      <p className="instructions">{instruction}</p>
      
      {!testRunning && !testFinished && (
        <Button onClick={startTesting}>Ready</Button>
      )}
      
      {testRunning && (
        <div>
          <p>Current Speed: {currentSpeed.toFixed(1)} km/h</p>
          <p>Current PAS: {currentPAS}</p>
          <p>Time elapsed: {Math.floor(elapsedTime)} seconds</p>
          
          <ProgressBar
            now={(elapsedTime / 20) * 100}
            variant="primary"
            className={elapsedTime < 20 ? 'in-progress' : 'timeout'}
          />
          
          {activeErrors.length > 0 && (
            <div>
              <p style={{ color: 'red' }}>Errors detected:</p>
              <ul>
                {activeErrors.map((error, index) => (
                  <li key={index}>
                    Error {error.code}: {error.description}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
      
      {testFinished && (
        <div>
          {testResult ? (
            <div>
              <p style={{ color: 'green' }}>{instruction}</p>
              <Button 
                onClick={() => onResult({
                  result: true,
                  data: "",
                  message: "Motor test completed successfully with no errors detected."
                })}
              >
                Next
              </Button>
            </div>
          ) : (
            <div>
              <Button 
                className="btn btn-large btn-primary mr-4"
                onClick={resetTest}
              >
                Restart Test
              </Button>
              <Button 
                className="btn btn-large btn-secondary"
                onClick={() => onResult({
                  result: false,
                  data: "Failed",
                  message: instruction
                })}
              >
                Skip Test
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MotorTest;