import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SideMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackClick = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    if (pathParts.length > 1) {
      const parentPath = '/' + pathParts.slice(0, -1).join('/');
      navigate(parentPath);
    } else {
      navigate('/'); // Default route
    }
  };

  return (
    <div className="ftex-side-menu">
      <ul>
        <li>{t('Menu')}</li>
        <li className={location.pathname === '/docs/overview' ? 'active' : ''}>
          <Link to="/docs/overview">{t('Overview')}</Link>
        </li>
        <li className={location.pathname === '/docs/protocols' ? 'active' : ''}>
          <Link to="/docs/protocols">{t('Protocols')}</Link>
        </li>
      </ul>
      <button className="btn btn-block btn-big" onClick={handleBackClick}>
        {t('Back')}
      </button>
    </div>
  );
};

export default SideMenu;
