import React, { useState, useEffect } from 'react';

const decodePassthroughResponse = (response) => {
    if (response.length !== 9) {
        return 'Invalid response';
    }

    const canDataBytes = response.slice(5);
    const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

    return canData;
};

const FormComponent = ({ variables, updateMapping, maxPASLevels, regenType, onMaxPASChange, onRegenTypeChange, reloadSingleValue }) => {
    const [errors, setErrors] = useState({});
    const [visibleVariables, setVisibleVariables] = useState([]);
    const [loadingStates, setLoadingStates] = useState({});

    useEffect(() => {
        const filteredVariables = Object.keys(variables).filter(key => {
            const variable = variables[key];
            if (variable.PAS && parseInt(variable.PAS) > maxPASLevels) {
                return false;
            }
            if (variable.BRAKE_REGEN && parseInt(variable.BRAKE_REGEN) !== regenType) {
                return false;
            }
            return true;
        });

        setVisibleVariables(filteredVariables);
    }, [variables, maxPASLevels, regenType]);

    const handleChange = (key, value) => {
        if (variables[key].access === "R") {
            return;
        }

        const variable = variables[key];
        const min = variable.input_value_range ? variable.input_value_range[0] : -Infinity;
        const max = variable.input_value_range ? variable.input_value_range[1] : Infinity;
        const numericValue = parseFloat(value);

        if (variable.showhide === 'PAS') {
            onMaxPASChange(parseInt(value));
        }

        if (variable.showhide === 'BRAKE_REGEN') {
            onRegenTypeChange(parseInt(value));
        }

        if (isNaN(numericValue) || numericValue < min || numericValue > max) {
            setErrors(prevErrors => ({ ...prevErrors, [key]: true }));
            updateMapping(key, numericValue);
        } else {
            setErrors(prevErrors => ({ ...prevErrors, [key]: false }));
            updateMapping(key, numericValue);
        }
    };

    const handleReload = async (key) => {
        setLoadingStates(prev => ({ ...prev, [key]: true }));
        try {
            await reloadSingleValue(key);
        } catch (error) {
            console.error(`Error reloading ${key}:`, error);
        } finally {
            setLoadingStates(prev => ({ ...prev, [key]: false }));
        }
    };

    const renderInputField = (variable, key) => {
        const isReadOnly = variable.access === "R";
        const isLoading = loadingStates[key];
        const baseProps = {
            id: key,
            disabled: isReadOnly || isLoading,
            className: `form-control ${errors[key] ? 'is-invalid' : ''} ${isReadOnly ? 'bg-light' : ''} ${isLoading ? 'opacity-50' : ''}`
        };

        const inputElement = () => {
            switch (variable.input_type) {
                case 'text_input':
                    return (
                        <input
                            type="text"
                            {...baseProps}
                            value={variable.value || ''}
                            onChange={(e) => handleChange(key, e.target.value)}
                        />
                    );
                case 'decimal_input':
                    return (
                        <input
                            type="text"
                            {...baseProps}
                            value={((variable.value || 0) / 100).toFixed(2)}
                            onChange={(e) => handleChange(key, parseFloat(e.target.value) * 100)}
                        />
                    );
                case 'combo_input':
                    return (
                        <select
                            {...baseProps}
                            value={variable.value || ''}
                            onChange={(e) => handleChange(key, e.target.value)}
                        >
                            {variable.input_value_options.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.key}
                                </option>
                            ))}
                        </select>
                    );
                default:
                    return (
                        <input
                            type="text"
                            {...baseProps}
                            value={variable.value || ''}
                            onChange={(e) => handleChange(key, e.target.value)}
                        />
                    );
            }
        };

        return (
            <div className={`input-group has-validation ${errors[key] ? 'is-invalid' : ''} ${isLoading ? 'opacity-50' : ''}`}>
                {variable.input_unit && (
                    <span className="input-group-text">{variable.input_unit}</span>
                )}
                <div className="form-floating flex-grow-1">
                    {inputElement()}
                </div>
                <button
                    type="button"
                    className="btn btn-block btn-small btn-secondary-reverse btn-config-refresh"
                    onClick={() => handleReload(key)}
                    disabled={isLoading}
                >
                    <svg 
                        className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.3"/>
                    </svg>
                </button>
            </div>
        );
    };

    return (
        <form>
            <div className="row">
                <div className="col-lg-8 col-md-10">
                    <div className="row">
                        {visibleVariables.map((key, index) => {
                            const variable = variables[key];
                            const value = variable.value ? decodePassthroughResponse(variable.value) : '';
                            const options = {};
                            if (variable.input_unit) {
                                options.input_unit = variable.input_unit;
                            }

                            return (
                                <div key={index} className={`form-group col-md-5 ${variable.error ? 'error' : ''} ${variable.updated ? 'updated' : ''}`}>
                                    <label htmlFor={key}>
                                        {variable.input_title}
                                        {variable.access === "R" && (
                                            <span className="badge bg-secondary ml-2">Read-only</span>
                                        )}
                                    </label>
                                    {renderInputField(variable, key)}
                                    {variable.error && (
                                        <div className="error-tooltip">
                                            <img src="/imgs/icons/icon-alt-warning.svg" alt="Error" />
                                            <span className="tooltip-text">This value did not get set in the controller.</span>
                                        </div>
                                    )}
                                    {errors[key] && (
                                        <div className="invalid-feedback">
                                            Value must be between {variable.input_value_range[0]} and {variable.input_value_range[1]}.
                                        </div>
                                    )}
                                    <small className="form-text text-muted">{variable.input_tool_tip}</small>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default FormComponent;