import React, { useState, useRef, useEffect, useCallback, navigation, useImperativeHandle, forwardRef } from 'react';
import { Card, Button, Form, ProgressBar, Alert } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const DFUTestComponent = forwardRef(({ primaryService, performFirmwareUpdate }, ref) => {
    const { t } = useTranslation();
    const logsEndRef = useRef(null);
    const hasStartedUpdate = useRef(false);
    
    // State management
    const [firmwareFile, setFirmwareFile] = useState(null);
    const [firmwarePin, setFirmwarePin] = useState('');
    const [error, setError] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('');
    const [logs, setLogs] = useState([]);
    const [testSummary, setTestSummary] = useState(null);

    const addLog = useCallback((...args) => {
        const timestamp = new Date().toISOString().split('T')[1].slice(0, -1);
        const logMessage = args.map(arg => 
            typeof arg === 'object' ? JSON.stringify(arg, null, 2) : String(arg)
        ).join(' ');
        
        setLogs(prevLogs => [...prevLogs, `[${timestamp}] ${logMessage}`]);
        
        if (logsEndRef.current) {
            logsEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const downloadLogs = useCallback(() => {
        if (logs.length === 0) { return; }

        const deviceName = primaryService?.device?.name || 'unknown-device';
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        const fileName = `dfu-test-${deviceName}-${timestamp}.txt`;
        
        let fileContent = '=== DFU Test Summary ===\n';
        if (testSummary) {
            fileContent += `Status: ${testSummary.status}\n`;
            if (testSummary.status === 'success') {
                fileContent += `Duration: ${testSummary.duration}s\n`;
                fileContent += `File Size: ${testSummary.fileSize} bytes\n`;
            } else {
                fileContent += `Error: ${testSummary.error}\n`;
            }
            fileContent += `Device: ${testSummary.deviceName}\n`;
            fileContent += `Timestamp: ${testSummary.timestamp}\n`;
        }
        fileContent += '\n=== Detailed Logs ===\n';
        fileContent += logs.join('\n');

        const blob = new Blob([fileContent], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        
    }, [logs, testSummary, primaryService?.device?.name]);

    useImperativeHandle(ref, () => ({
        triggerDownload: () => {
          downloadLogs();
        }
    }));

    const handleDropFirmwareFile = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && (file.name.endsWith('.bin') || file.name.endsWith('.dfu'))) {
            setFirmwareFile(file);
            setError('');
            addLog('File selected:', file.name, `Size: ${file.size} bytes`);
        } else {
            setError(t('Please select a valid .bin or .dfu file.'));
            addLog('Error: Invalid file type selected');
        }
    }, [addLog, t]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDropFirmwareFile,
        accept: {
            'application/octet-stream': ['.bin', '.dfu']
        },
        multiple: false,
    });

    const startFirmwareUpdate = async () => {
        if (!firmwareFile || !firmwarePin) {
            setError(t('Please select a firmware file and enter a valid 4-digit PIN.'));
            addLog('Error: Missing file or PIN');
            return;
        }

        hasStartedUpdate.current = true;
        setIsUpdating(true);
        setError('');
        setProgress(0);
        setStatus('');
        setTestSummary(null);
        addLog('Starting firmware update process');

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const firmwareData = new Uint8Array(e.target.result);
                addLog('Firmware data loaded successfully');
                addLog('Data length:', firmwareData.length, 'bytes');

                const updateStartTime = Date.now();
                await performFirmwareUpdate(
                    primaryService,
                    firmwarePin,
                    firmwareData,
                    (progress, statusMessage) => {
                        setProgress(progress);
                        setStatus(statusMessage);
                        addLog(`Progress: ${progress}%${statusMessage ? ' - ' + statusMessage : ''}`);
                        
                        if (progress === 100) {
                            const updateEndTime = Date.now();
                            const duration = ((updateEndTime - updateStartTime) / 1000).toFixed(2);
                            setTestSummary({
                                status: 'success',
                                duration: duration,
                                fileSize: firmwareData.length,
                                deviceName: primaryService?.device?.name || 'Unknown',
                                timestamp: new Date().toISOString()
                            });
                            addLog('Update completed successfully');
                            addLog(`Total duration: ${duration} seconds`);
                        }
                    },
                    (...args) => addLog(...args)
                );
            } catch (error) {
                addLog('Error during update:', error.message);
                setError(t('Firmware update failed: ') + error.message);
                setTestSummary({
                    status: 'failed',
                    error: error.message,
                    deviceName: primaryService?.device?.name || 'Unknown',
                    timestamp: new Date().toISOString()
                });
            } finally {
                setIsUpdating(false);
            }
        };

        reader.onerror = (error) => {
            addLog('Error reading file:', error);
            setError(t('Failed to read firmware file'));
            setIsUpdating(false);
        };

        reader.readAsArrayBuffer(firmwareFile);
    };

    return (
        <div className="container-fluid">
            <Card>
                <Card.Header>
                    <Card.Title>DFU Protocol Test</Card.Title>
                    <Card.Subtitle className="text-muted">
                        Test and validate Device Firmware Update process
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    <Form className="mb-4">
                        <Form.Group controlId="formFirmwareFile" className="mb-3">
                            <Form.Label>Select Firmware File</Form.Label>
                            <div 
                                {...getRootProps()} 
                                className={`dropzone p-4 border border-dashed rounded ${
                                    firmwareFile ? 'border-success' : ''
                                }`}
                            >
                                <input {...getInputProps()} />
                                <p className="mb-1">
                                    {t('Drag & drop a firmware file here, or click to select one')}
                                </p>
                                {firmwareFile && (
                                    <p className="mb-0 text-success">
                                        {t('Selected file:')} {firmwareFile.name}
                                    </p>
                                )}
                            </div>
                        </Form.Group>

                        <Form.Group controlId="formFirmwarePin" className="mb-3">
                            <Form.Label>Enter 4-digit PIN</Form.Label>
                            <Form.Control
                                type="text"
                                maxLength="4"
                                value={firmwarePin}
                                onChange={(e) => setFirmwarePin(e.target.value.replace(/[^0-9]/g, '').slice(0, 4))}
                                placeholder={t('Enter PIN')}
                                disabled={isUpdating}
                            />
                        </Form.Group>

                        {error && (
                            <Alert variant="danger" className="mb-3">
                                {error}
                            </Alert>
                        )}

                        <div className="d-flex gap-2">
                            <Button 
                                variant="primary" 
                                onClick={startFirmwareUpdate}
                                disabled={isUpdating || !firmwareFile || firmwarePin.length !== 4}
                                className="flex-grow-1"
                            >
                                {isUpdating ? t('Updating...') : t('Start Update')}
                            </Button>
                        </div>
                    </Form>

                    {(isUpdating || progress > 0) && (
                        <div className="mb-4">
                            <ProgressBar 
                                now={progress} 
                                label={`${Math.ceil(progress)}%`}
                                variant={progress === 100 ? "success" : "primary"}
                                className="mb-2"
                            />
                            {status && (
                                <div className="text-center text-muted">
                                    {status}
                                </div>
                            )}
                        </div>
                    )}

                    {testSummary && (
                        <Alert 
                            variant={testSummary.status === 'success' ? 'success' : 'danger'}
                            className="mb-4"
                        >
                            <Alert.Heading>
                                {testSummary.status === 'success' ? 
                                    'Update Successful' : 
                                    'Update Failed'}
                            </Alert.Heading>
                            <hr />
                            <p className="mb-0">
                                {testSummary.status === 'success' ? (
                                    <>
                                        Duration: {testSummary.duration}s<br />
                                        File Size: {testSummary.fileSize} bytes<br />
                                        Device: {testSummary.deviceName}<br />
                                        Timestamp: {testSummary.timestamp}
                                    </>
                                ) : (
                                    <>
                                        Error: {testSummary.error}<br />
                                        Device: {testSummary.deviceName}<br />
                                        Timestamp: {testSummary.timestamp}
                                    </>
                                )}
                            </p>
                        </Alert>
                    )}

                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <Card.Title>Debug Logs</Card.Title>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={downloadLogs}
                            >
                                Download Logs
                            </Button>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <pre 
                                className="m-0 p-3" 
                                style={{ 
                                    maxHeight: '500px', 
                                    overflowY: 'auto',
                                    backgroundColor: '#f8f9fa',
                                    fontSize: '0.875rem',
                                    whiteSpace: 'pre-wrap'
                                }}
                            >
                                {logs.join('\n')}
                                <div ref={logsEndRef} />
                            </pre>
                        </Card.Body>
                    </Card>
                </Card.Body>
            </Card>
        </div>
    );
});

export default DFUTestComponent;