import React, { useState, useEffect, useRef } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useCANStream from '../../../hooks/useCANStream.hook';

const MAX_TEST_TIME = 20; // seconds
const POLL_INTERVAL = 100; // milliseconds
const MAX_ITERATIONS = (MAX_TEST_TIME * 1000) / POLL_INTERVAL;
const MIN_RPM = 30; // Minimum RPM required for success

const CadenceTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const { t } = useTranslation();
  
  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Setting PAS to 0 for safety...");
  const [countdown, setCountdown] = useState(MAX_TEST_TIME);
  const [maxRpmReached, setMaxRpmReached] = useState(0);
  const [initialValueFailed, setInitialValueFailed] = useState(false);
  const [safetyCheckComplete, setSafetyCheckComplete] = useState(false);
  const [shouldStartStream, setShouldStartStream] = useState(false);
  
  const timerRef = useRef(null);
  const iterationCount = useRef(0);
  const hasStartedRef = useRef(false);
  const testReadyRef = useRef(false);

  // Stream configuration
  const streamIndices = [{
    nodeId: 0x00000601,
    index: 0x2024,
    subIndex: 0x00
  }];

  // Set up CAN stream
  const { values, startStream, stopStream, writeValue } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    streamIndices,
    () => {} // We'll handle values through the values array
  );

  // Safety check - Set PAS to 0
  useEffect(() => {
    const initSafety = async () => {
      try {
        console.log("Setting PAS to 0 for safety");
        await writeValue(0x00000601, 0x2003, 0x00, 0, true);
        console.log("PAS set to 0 successfully");
        setSafetyCheckComplete(true);
        setShouldStartStream(true);
        setInstruction("Waiting for initial cadence to be 0...");
      } catch (error) {
        console.error("Failed to set PAS to 0:", error);
        handleTestStop(false);
        setInstruction("ERROR: Failed to set PAS to 0 - Test cannot proceed safely");
      }
    };

    if (!safetyCheckComplete && !testFinished) {
      initSafety();
    }
  }, [safetyCheckComplete, testFinished, writeValue]);

  const startTimer = () => {
    if (timerRef.current) return;
    
    console.log("Starting cadence test timer");
    timerRef.current = setInterval(() => {
      iterationCount.current += 1;
      
      if (iterationCount.current >= MAX_ITERATIONS) {
        cleanup();
        if (!testFinished) {
          handleTestStop(maxRpmReached >= MIN_RPM && !initialValueFailed);
          setTestFinished(true);
        }
        return;
      }

      const timeElapsed = (iterationCount.current * POLL_INTERVAL) / 1000;
      setCountdown(Math.max(0, MAX_TEST_TIME - timeElapsed));
    }, POLL_INTERVAL);
  };

  // Monitor values array for changes
  useEffect(() => {
    if (!testRunning || testFinished || !safetyCheckComplete) return;

    const currentValue = values?.[0];
    
    // Wait for valid initial value
    if (!hasStartedRef.current && currentValue !== null && currentValue !== undefined) {
      console.log("Got initial cadence value:", currentValue);
      hasStartedRef.current = true;
      
      if (currentValue === 0) {
        console.log("Initial cadence is 0, ready for test");
        testReadyRef.current = true;
        setInstruction("Start pedaling - try to reach at least 30 RPM");
        startTimer();
      } else {
        console.log("Initial cadence not 0:", currentValue);
        setInitialValueFailed(true);
        setInstruction("Initial cadence must be 0 when not pedaling");
        startTimer();
      }
      return;
    }

    // Monitor cadence changes
    if (hasStartedRef.current && currentValue !== null && currentValue !== undefined) {
      const rpm = Math.abs(currentValue); // Handle both positive and negative values
      setMaxRpmReached(prev => Math.max(prev, rpm));

      if (rpm >= MIN_RPM && !initialValueFailed) {
        console.log("Reached minimum RPM:", rpm);
        handleTestStop(true);
        setTestFinished(true);
      }
    }
  }, [values, testRunning, testFinished, safetyCheckComplete]);

  // Cleanup function
  const cleanup = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    stopStream();
  };

  // Start stream after safety check
  useEffect(() => {
    if (shouldStartStream && !testRunning && !testFinished) {
      console.log("Starting cadence test after safety check");
      setTestRunning(true);
      startStream();
      setShouldStartStream(false);
    }
    return () => cleanup();
  }, [shouldStartStream, testRunning, testFinished]);

  const handleTestStop = (result) => {
    setInstruction(null);
    setTestResult(result);
    setTestRunning(false);
    cleanup();
  };

  const restartTest = () => {
    cleanup();
    // Reset everything but repeat safety check
    setSafetyCheckComplete(false);
    setTestRunning(false);
    setTestResult(null);
    setInstruction("Setting PAS to 0 for safety...");
    setCountdown(MAX_TEST_TIME);
    setTestFinished(false);
    setMaxRpmReached(0);
    setInitialValueFailed(false);
    setShouldStartStream(false);
    hasStartedRef.current = false;
    testReadyRef.current = false;
    iterationCount.current = 0;
  };

  const currentValue = values?.[0] ?? 'Waiting for data...';

  return (
    <div>
      <h1>Step {stepNumber} - Cadence Test</h1>
      <p className="instructions">{instruction}</p>
      
      {testFinished && (
        <div className={testResult ? "success-title" : "fail-title"}>
          <h3>{testResult ? "Test Successful" : "Test Failed"}</h3>
          {initialValueFailed 
            ? "Cadence initial state is not zero. This means that the cadence/torque sensor is sending a cadence input when pedals are not turning, this can be dangerous and the cadence/torque sensor needs to be replaced."
            : maxRpmReached < MIN_RPM
              ? `Cadence must reach at least ${MIN_RPM} RPM. Maximum reached was ${maxRpmReached} RPM.`
              : "The cadence sensor is working correctly. Initial state was 0 and required RPM was reached."}
        </div>
      )}
      
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt={t("Warning")} />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      
      {testResult === null && (
        <>
          <p>Current RPM: {currentValue}</p>
          <p>Maximum RPM reached: {maxRpmReached}</p>
        </>
      )}
      
      {!testFinished && (
        <div>
          {hasStartedRef.current ? (
            <>
              <span className="countdown">Time remaining: {Math.ceil(countdown)} seconds</span>
              <ProgressBar
                now={(MAX_TEST_TIME - countdown) * 5}
                className={countdown > 0 ? 'in-progress' : 'timeout'}
              />
            </>
          ) : (
            <p>Waiting for connection...</p>
          )}
        </div>
      )}
      
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: maxRpmReached,
            message: "The cadence sensor is working correctly. Initial state was 0 and required RPM was reached."
          })}>Next</Button>
        </div>
      )}
      
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={restartTest}>
            Restart Test
          </Button>
          <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
            result: false, 
            data: maxRpmReached,
            message: initialValueFailed
              ? "Cadence initial state is not zero. This means that the cadence/torque sensor is sending a cadence input when pedals are not turning, this can be dangerous and the cadence/torque sensor needs to be replaced."
              : `Cadence must reach at least ${MIN_RPM} RPM. Maximum reached was ${maxRpmReached} RPM.`
          })}>Skip Test</Button>
        </div>
      )}
    </div>
  );
};

export default CadenceTest;