import { useState, useRef } from 'react';
import useBluetooth from './useBluetooth.hook';
import { useCAN } from './useCAN.hook';

const RETRY_ATTEMPTS = 2;  // Reduced from 3 to 2
const RETRY_DELAY = 1000;  // Increased from 500 to 1000ms
const READ_TIMEOUT = 1000; // 1 second timeout for reads

const useAutotune = () => {
    const {
        sendPassthroughWrite,
        readPassthroughValue,
        sendPassthroughRead,
        startNotificationListener,
        stopNotificationListener,
    } = useBluetooth();

    const { getProtocolByParam } = useCAN();

    // Get all protocol parameters at initialization
    const autotuneControl = getProtocolByParam('CO_PARAM_AUTOTUNE_CONTROL');
    const autotunePairs = getProtocolByParam('CO_PARAM_AUTOTUNE_CONFIG_POLEPAIRS');
    const autotuneRatedCurrent = getProtocolByParam('CO_PARAM_AUTOTUNE_CONFIG_RATED_CURRENT');
    const autotuneGearRatio = getProtocolByParam('CO_PARAM_AUTOTUNE_MOTOR_GEAR_RATIO');
    const autotuneProgressParam = getProtocolByParam('CO_PARAM_AUTOTUNE_PROGRESS');
    const autotuneErrors = getProtocolByParam('CO_PARAM_AUTOTUNE_ERRORS_OUTPUT');

    // Result parameters
    const resultParams = {
        Iq_Kp: getProtocolByParam('CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_IQ_KP'),
        Iq_Ki: getProtocolByParam('CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_IQ_KI'),
        Id_Kp: getProtocolByParam('CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_ID_KP'),
        Id_Ki: getProtocolByParam('CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_ID_KI'),
        Stator_Resistance: getProtocolByParam('CO_PARAM_AUTOTUNE_OUTPUTS_STATOR_RESISTANCE'),
        Stator_Inductance: getProtocolByParam('CO_PARAM_AUTOTUNE_OUTPUTS_STATOR_INDUCTANCE'),
        Ke: getProtocolByParam('CO_PARAM_AUTOTUNE_KE'),
        Rated_Torque: getProtocolByParam('CO_PARAM_AUTOTUNE_RATED_TORQUE'),
        Rated_Speed: getProtocolByParam('CO_PARAM_AUTOTUNE_RATED_SPEED')
    };

    const [autotuneProgress, setAutotuneProgress] = useState(null);
    const [autotuneResults, setAutotuneResults] = useState(null);
    const [autotuneStatus, setAutotuneStatus] = useState(null);
    
    // Refs for tracking state and cleanup
    const progressIntervalRef = useRef(null);
    const currentServiceRef = useRef(null);
    const operationInProgressRef = useRef(false);
    const parameterHandlersRef = useRef(new Map());
    const successfulReadsRef = useRef(new Set()); // Track successful reads

    const decodePassthroughResponse = (response) => {
        if (response.length < 9) {
            console.error("Invalid response length:", response.length);
            return null;
        }

        const canHeader = response.slice(1, 5);
        const canDataBytes = response.slice(5, 9);
        const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

        const index = (canHeader[2] << 8) | canHeader[1];
        const subIndex = canHeader[3];

        return { index, subIndex, canData };
    };

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const cleanup = () => {
        console.log('Running cleanup process');
        
        if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
            progressIntervalRef.current = null;
        }

        if (currentServiceRef.current) {
            stopNotificationListener(currentServiceRef.current);
            currentServiceRef.current = null;
        }

        // Clear parameter handlers and successful reads
        parameterHandlersRef.current.clear();
        successfulReadsRef.current.clear();

        operationInProgressRef.current = false;
        setAutotuneProgress(null);
        setAutotuneStatus(null);
        
        console.log('Cleanup complete');
    };

    const parseIndex = (indexStr) => {
        if (typeof indexStr === 'number') return indexStr;
        return parseInt(indexStr.replace('0x', ''), 16);
    };

    const readParameterWithRetry = async (primaryService, pin, nodeId, paramProtocol, paramName) => {
        // Skip if we've already successfully read this parameter
        const paramKey = `${paramName}`;
        if (successfulReadsRef.current.has(paramKey)) {
            console.log(`Already have successful read for ${paramName}, skipping`);
            return null;
        }

        const index = parseIndex(paramProtocol.Index);
        const subindex = parseIndex(paramProtocol.Subindex);
        
        return new Promise(async (resolve, reject) => {
            let attempts = 0;
            let timeoutId;

            const attemptRead = async () => {
                if (attempts >= RETRY_ATTEMPTS) {
                    reject(new Error(`Failed to read ${paramName} after ${RETRY_ATTEMPTS} attempts`));
                    return;
                }

                try {
                    while (operationInProgressRef.current) {
                        await delay(100);
                    }
                    
                    operationInProgressRef.current = true;
                    await sendPassthroughRead(pin, nodeId, index, subindex, primaryService);
                    operationInProgressRef.current = false;
                    
                    timeoutId = setTimeout(async () => {
                        attempts++;
                        console.log(`Timeout reading ${paramName} on attempt ${attempts}`);
                        
                        if (attempts < RETRY_ATTEMPTS) {
                            await delay(RETRY_DELAY);
                            attemptRead();
                        }
                    }, READ_TIMEOUT);
                    
                } catch (error) {
                    operationInProgressRef.current = false;
                    console.error(`Failed to send read command for ${paramName}:`, error);
                    reject(error);
                }
            };

            const responseHandler = (value) => {
                clearTimeout(timeoutId);
                console.log(`Got response for ${paramName}:`, value);
                successfulReadsRef.current.add(paramKey);
                resolve(value);
            };

            parameterHandlersRef.current.set(`${index}-${subindex}`, responseHandler);
            attemptRead();
        });
    };
    
    const fetchFinalResults = async (primaryService, pin, nodeId) => {
        try {
            console.log('Starting to fetch final autotune results...');
            
            // Skip error checking if progress is 101% as it indicates success
            if (autotuneProgress < 101) {
                const errorValue = await readParameterWithRetry(
                    primaryService,
                    pin,
                    nodeId,
                    autotuneErrors,
                    'AUTOTUNE_ERRORS'
                );

                if (errorValue !== null && errorValue !== 0) {
                    console.error(`Autotune error detected: ${errorValue}`);
                    setAutotuneStatus('error');
                    setAutotuneResults({ error: `Autotune failed with error code: ${errorValue}` });
                    return;
                }
            }

            console.log('Reading autotune parameters...');
            const results = {};
            
            // Read all parameters with small delay between reads
            for (const [paramName, protocol] of Object.entries(resultParams)) {
                try {
                    const value = await readParameterWithRetry(
                        primaryService,
                        pin,
                        nodeId,
                        protocol,
                        paramName
                    );
                    
                    if (value !== null) {
                        console.log(`Read ${paramName}:`, value);
                        results[paramName] = value;
                    }
                    
                    await delay(100); // Small delay between reads
                    
                } catch (error) {
                    console.error(`Failed to read ${paramName}:`, error);
                }
            }

            if (Object.keys(results).length > 0) {
                console.log('Final autotune results:', results);
                setAutotuneResults(results);
                setAutotuneStatus('success');
            } else {
                setAutotuneStatus('error');
                setAutotuneResults({ error: 'Failed to read autotune results' });
            }
            
        } catch (error) {
            console.error('Error fetching final results:', error);
            setAutotuneStatus('error');
            setAutotuneResults({ error: 'Failed to read autotune results' });
        }
    };

    const monitorAutotuneProgress = async (primaryService, pin, nodeId) => {
        try {
            const notificationCallback = (event) => {
                const value = new Uint8Array(event.buffer);
                const decoded = decodePassthroughResponse(value);
                
                if (!decoded) {
                    console.error("Failed to decode response");
                    return;
                }

                const { index, subIndex, canData } = decoded;
                const paramKey = `${index}-${subIndex}`;

                const progressIndex = parseIndex(autotuneProgressParam.Index);
                const errorsIndex = parseIndex(autotuneErrors.Index);

                // Handle error notifications
                if (index === errorsIndex && subIndex === parseIndex(autotuneErrors.Subindex)) {
                    if (canData !== 0) {
                        console.log('Autotune error:', canData);
                        setAutotuneStatus('error');
                        setAutotuneResults({ error: `Error code: ${canData}` });
                        return;
                    }
                }

                // Handle progress notifications
                if (index === progressIndex && subIndex === parseIndex(autotuneProgressParam.Subindex)) {
                    setAutotuneProgress(canData);
                    console.log('Autotune progress:', canData);

                    // Check for completion
                    if (canData === 101) {
                        console.log('Autotune complete, fetching final results');
                        if (progressIntervalRef.current) {
                            clearInterval(progressIntervalRef.current);
                            progressIntervalRef.current = null;
                        }
                        fetchFinalResults(primaryService, pin, nodeId);
                        return;
                    }
                }

                // Handle parameter read responses
                const handler = parameterHandlersRef.current.get(paramKey);
                if (handler) {
                    handler(canData);
                    parameterHandlersRef.current.delete(paramKey);
                }
            };

            await startNotificationListener(primaryService, notificationCallback);

            // Set up progress monitoring interval if not already running
            if (!progressIntervalRef.current) {
                progressIntervalRef.current = setInterval(async () => {
                    try {
                        // Wait if another operation is in progress
                        while (operationInProgressRef.current) {
                            await delay(100);
                        }
                        
                        // Read progress
                        operationInProgressRef.current = true;
                        await sendPassthroughRead(
                            pin, 
                            nodeId, 
                            parseIndex(autotuneProgressParam.Index),
                            parseIndex(autotuneProgressParam.Subindex),
                            primaryService
                        );
                        operationInProgressRef.current = false;
                        
                        // Read errors
                        operationInProgressRef.current = true;
                        await sendPassthroughRead(
                            pin,
                            nodeId,
                            parseIndex(autotuneErrors.Index),
                            parseIndex(autotuneErrors.Subindex),
                            primaryService
                        );
                        operationInProgressRef.current = false;
                    } catch (error) {
                        operationInProgressRef.current = false;
                        console.error('Error sending read command during progress monitoring:', error);
                    }
                }, 1000);
            }

        } catch (error) {
            console.error('Error monitoring autotune progress:', error);
            setAutotuneStatus('error');
        }
    };

    const startAutotune = async (primaryService, pin, nodeId, polePairs, ratedCurrent, gearRatio) => {
        try {
            // Run cleanup before starting new autotune
            cleanup();
            
            console.log('Starting autotune with parameters:', { polePairs, ratedCurrent, gearRatio });
            
            setAutotuneStatus('running');
            setAutotuneProgress(0);
            setAutotuneResults(null);
            
            currentServiceRef.current = primaryService;

            // Write configuration values using protocol parameters
            const controlIndex = parseIndex(autotuneControl.Index);
            const pairsIndex = parseIndex(autotunePairs.Index);
            const currentIndex = parseIndex(autotuneRatedCurrent.Index);
            const ratioIndex = parseIndex(autotuneGearRatio.Index);

            await sendPassthroughWrite(
                pin, 
                nodeId, 
                pairsIndex,
                parseIndex(autotunePairs.Subindex),
                polePairs,
                primaryService,
                1
            );

            await sendPassthroughWrite(
                pin,
                nodeId,
                currentIndex,
                parseIndex(autotuneRatedCurrent.Subindex),
                ratedCurrent,
                primaryService,
                2
            );

            await sendPassthroughWrite(
                pin,
                nodeId,
                ratioIndex,
                parseIndex(autotuneGearRatio.Subindex),
                gearRatio,
                primaryService,
                2
            );

            // Start autotune process
            await sendPassthroughWrite(
                pin,
                nodeId,
                controlIndex,
                parseIndex(autotuneControl.Subindex),
                1,
                primaryService,
                1
            );

            // Monitor autotune progress
            await monitorAutotuneProgress(primaryService, pin, nodeId);
        } catch (error) {
            console.error('Error starting autotune:', error);
            setAutotuneStatus('error');
            setAutotuneResults({ error: 'Failed to start autotune process' });
        }
    };
    
    const endAutotune = () => {
        console.log('Explicitly ending autotune process');
        cleanup();
    };

    return {
        startAutotune,
        endAutotune,
        autotuneProgress,
        autotuneResults,
        autotuneStatus
    };
};

export default useAutotune;