import axios from "axios";
import Debug from "./debug.js";

// const API_URL = "http://localhost:5050/manufacturing";
const API_URL = "https://ftex-backend-73e39a9f925f.herokuapp.com/manufacturing";

class ManufacturingService {
  // Get EOL test result after resin
  async getLastEOLTestResultAfterResin(serial) {
    try {
      const response = await axios.get(`${API_URL}/eol-test/${serial}`);
      return response.data;
    } catch (error) {
      Debug.log('EOL Test Result Error in ManufacturingService', JSON.stringify(error));
      throw error;
    }
  }

  // Check serial status
  async checkSerial(serial) {
    try {
      const response = await axios.get(`${API_URL}/check/${serial}`);
      return response.data;
    } catch (error) {
      Debug.log('Check Serial Error in ManufacturingService', JSON.stringify(error));
      throw error;
    }
  }

  // Report a defect
  async reportDefect(serial, defect) {
    try {
      const response = await axios.post(`${API_URL}/defect`, { serial, defect });
      return response.data;
    } catch (error) {
      Debug.log('Report Defect Error in ManufacturingService', JSON.stringify(error));
      throw error;
    }
  }

  // Get all defects for a serial
  async getDefects(serial) {
    try {
      const response = await axios.get(`${API_URL}/defects/${serial}`);
      return response.data;
    } catch (error) {
      Debug.log('Get Defects Error in ManufacturingService', JSON.stringify(error));
      throw error;
    }
  }
}

export default new ManufacturingService();