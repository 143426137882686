import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import ReactCodeInput from "react-code-input";
import { useTranslation } from 'react-i18next';
import Logo from '../logo.component';

const PinEntryComponent = ({
  userGroup,
  bikeSerial,
  primaryService,
  verifyPin,
  setPinVerified,
  connectToDevice,
  isConnected,
  disconnectFromDevice,
  pin,
  setPin,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handlePinChange = (value) => {
    setPin(value);
  };

  useEffect(() => {
    if (!isConnected) {
      navigate("/dev-tools");
    }
  }, [isConnected, navigate]);

  useEffect(() => {
      if (pin.length === 4 && isConnected) {
          handleSubmit();
      }
  }, [pin]);

  const handleConnect = async () => {
    setLoading(true);
    setError("");
    try {
      const serial = await connectToDevice();
      if (serial) {
        navigate("/dev-tools/pin-entry");
      } else {
        setError("Connection failed. Please try again.");
      }
    } catch (err) {
      setError("Error during connection. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();      
    }
    setLoading(true);
    setError("");

    try {
      const isValid = await verifyPin(pin, primaryService);
      if (isValid) {
        setPinVerified(true);
        navigate("/dev-tools/canbus-tests");
      } else {
        setError("Invalid PIN. Please try again.");
        setShowModal(true);
        disconnectFromDevice();
      }
    } catch (err) {
      setError("Error verifying PIN. Please try again.");
      setShowModal(true);
      disconnectFromDevice();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="col-md-12 connection pin-verification text-center">
          <Logo userGroup={userGroup} />
          {!isConnected ? (
              // Not Connected - Show Connect UI
              <div className="text-center">
                  <p>{t('Please turn on Bluetooth on both devices.')}</p>
                  <button 
                      onClick={handleConnect} 
                      className="btn btn-primary btn-large" 
                      disabled={loading}
                  >
                      {loading ? t('Connecting...') : t('Connect to Bike')}
                  </button>
              </div>
          ) : !primaryService ? (
              // Connected but waiting for service
              <div className="text-center">
                  <p>{t('Loading primary service...')}</p>
              </div>
          ) : !loading ? (
              // Connected with service, show PIN entry
              <div className="row text-center d-flex align-items-center justify-content-center h-100">
                  <div className="col">
                      <div className="pin-entry config-tool">
                          <h4>{t("Enter PIN")}</h4>
                          <form onSubmit={handleSubmit}>
                              <div className="form-group">
                                  <ReactCodeInput
                                      type="number"
                                      fields={4}
                                      id="pin"
                                      value={pin}
                                      onChange={setPin}
                                  />
                              </div>
                              {error && <div className="alert alert-danger">{error}</div>}
                              <button type="submit" className="btn btn-block btn-primary">
                                  {t('Submit')}
                              </button>
                          </form>
                      </div>
                  </div>
              </div>
          ) : null}
      </div>

      {/* Modal for Failed Verification */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>PIN Verification Failed</Modal.Title>
        </Modal.Header>
        <Modal.Body>Invalid PIN. Please try again.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowModal(false);
              navigate("/dev-tools");
            }}
          >
            Reconnect
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PinEntryComponent;
