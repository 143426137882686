import React, { useState, useEffect, useRef } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useCANStream from '../../../hooks/useCANStream.hook';

const MAX_TEST_TIME = 20; // seconds
const POLL_INTERVAL = 100; // milliseconds
const MAX_ITERATIONS = (MAX_TEST_TIME * 1000) / POLL_INTERVAL;
const MIN_TORQUE_PERCENT = 35; // Minimum torque percentage required for success

const TorqueTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const { t } = useTranslation();
  
  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Setting PAS to 0 for safety...");
  const [countdown, setCountdown] = useState(MAX_TEST_TIME);
  const [maxTorqueReached, setMaxTorqueReached] = useState(0);
  const [minTorqueReached, setMinTorqueReached] = useState(Infinity);
  const [safetyCheckComplete, setSafetyCheckComplete] = useState(false);
  const [shouldStartStream, setShouldStartStream] = useState(false);
  
  const timerRef = useRef(null);
  const iterationCount = useRef(0);
  const hasStartedRef = useRef(false);
  const testReadyRef = useRef(false);

  // Stream configuration
  const streamIndices = [{
    nodeId: 0x00000601,
    index: 0x2024,
    subIndex: 0x01
  }];

  // Set up CAN stream
  const { values, startStream, stopStream, writeValue } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    streamIndices,
    () => {} // We'll handle values through the values array
  );

  // Safety check - Set PAS to 0
  useEffect(() => {
    const initSafety = async () => {
      try {
        console.log("Setting PAS to 0 for safety");
        await writeValue(0x00000601, 0x2003, 0x00, 0, true);
        console.log("PAS set to 0 successfully");
        setSafetyCheckComplete(true);
        setShouldStartStream(true);
        setInstruction("Test starting - press and release pedals to show full range...");
      } catch (error) {
        console.error("Failed to set PAS to 0:", error);
        handleTestStop(false);
        setInstruction("ERROR: Failed to set PAS to 0 - Test cannot proceed safely");
      }
    };

    if (!safetyCheckComplete && !testFinished) {
      initSafety();
    }
  }, [safetyCheckComplete, testFinished, writeValue]);

  const startTimer = () => {
    if (timerRef.current) return;
    
    console.log("Starting torque test timer");
    timerRef.current = setInterval(() => {
      iterationCount.current += 1;
      
      if (iterationCount.current >= MAX_ITERATIONS) {
        cleanup();
        if (!testFinished) {
          const testPassed = maxTorqueReached >= MIN_TORQUE_PERCENT && minTorqueReached <= 0;
          handleTestStop(testPassed);
          setTestFinished(true);
        }
        return;
      }

      const timeElapsed = (iterationCount.current * POLL_INTERVAL) / 1000;
      setCountdown(Math.max(0, MAX_TEST_TIME - timeElapsed));
    }, POLL_INTERVAL);
  };

  // Monitor values array for changes
  useEffect(() => {
    if (!testRunning || testFinished || !safetyCheckComplete) return;

    const currentValue = values?.[0];
    
    // Start test when we get first valid value
    if (!hasStartedRef.current && currentValue !== null && currentValue !== undefined) {
      console.log("Got initial torque value:", currentValue);
      hasStartedRef.current = true;
      testReadyRef.current = true;
      setInstruction(`Press hard on the pedals to reach at least ${MIN_TORQUE_PERCENT}% torque, and release to show 0%`);
      startTimer();
      return;
    }

    // Monitor torque changes
    if (hasStartedRef.current && currentValue !== null && currentValue !== undefined) {
      const torquePercent = currentValue;
      setMaxTorqueReached(prev => Math.max(prev, torquePercent));
      setMinTorqueReached(prev => Math.min(prev, torquePercent));

      // Check for success conditions during the test
      if (maxTorqueReached >= MIN_TORQUE_PERCENT && minTorqueReached <= 0) {
        console.log("Test requirements met - Max:", maxTorqueReached, "Min:", minTorqueReached);
        handleTestStop(true);
        setTestFinished(true);
      }
    }
  }, [values, testRunning, testFinished, safetyCheckComplete, maxTorqueReached, minTorqueReached]);

  // Cleanup function
  const cleanup = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    stopStream();
  };

  // Start stream after safety check
  useEffect(() => {
    if (shouldStartStream && !testRunning && !testFinished) {
      console.log("Starting torque test after safety check");
      setTestRunning(true);
      startStream();
      setShouldStartStream(false);
    }
    return () => cleanup();
  }, [shouldStartStream, testRunning, testFinished]);

  const handleTestStop = (result) => {
    setInstruction(null);
    setTestResult(result);
    setTestRunning(false);
    cleanup();
  };

  const restartTest = () => {
    cleanup();
    // Reset everything but repeat safety check
    setSafetyCheckComplete(false);
    setTestRunning(false);
    setTestResult(null);
    setInstruction("Setting PAS to 0 for safety...");
    setCountdown(MAX_TEST_TIME);
    setTestFinished(false);
    setMaxTorqueReached(0);
    setMinTorqueReached(Infinity);
    setShouldStartStream(false);
    hasStartedRef.current = false;
    testReadyRef.current = false;
    iterationCount.current = 0;
  };

  const currentValue = values?.[0] ?? 'Waiting for data...';

  return (
    <div>
      <h1>Step {stepNumber} - Torque Test</h1>
      <p className="instructions">{instruction}</p>
      
      {testFinished && (
        <div className={testResult ? "success-title" : "fail-title"}>
          <h3>{testResult ? "Test Successful" : "Test Failed"}</h3>
          {maxTorqueReached < MIN_TORQUE_PERCENT
            ? `Torque must reach at least ${MIN_TORQUE_PERCENT}%. Maximum reached was ${maxTorqueReached}%.`
            : minTorqueReached > 0
              ? `Torque must return to 0% or less. Minimum reached was ${minTorqueReached}%.`
              : "The torque sensor is working correctly. Full range of motion detected."}
        </div>
      )}
      
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt={t("Warning")} />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      
      {testResult === null && (
        <>
          <p>Current Torque: {currentValue}%</p>
          <p>Maximum Torque Reached: {maxTorqueReached}%</p>
          <p>Minimum Torque Reached: {minTorqueReached === Infinity ? '-' : minTorqueReached}%</p>
        </>
      )}
      
      {!testFinished && (
        <div>
          {hasStartedRef.current ? (
            <>
              <span className="countdown">Time remaining: {Math.ceil(countdown)} seconds</span>
              <ProgressBar
                now={(MAX_TEST_TIME - countdown) * 5}
                className={countdown > 0 ? 'in-progress' : 'timeout'}
              />
            </>
          ) : (
            <p>Waiting for connection...</p>
          )}
        </div>
      )}
      
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: { max: maxTorqueReached, min: minTorqueReached },
            message: "The torque sensor is working correctly. Full range of motion detected."
          })}>Next</Button>
        </div>
      )}
      
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={restartTest}>
            Restart Test
          </Button>
          <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
            result: false, 
            data: { max: maxTorqueReached, min: minTorqueReached },
            message: maxTorqueReached < MIN_TORQUE_PERCENT
              ? `Torque must reach at least ${MIN_TORQUE_PERCENT}%. Maximum reached was ${maxTorqueReached}%.`
              : `Torque must return to 0% or less. Minimum reached was ${minTorqueReached}%.`
          })}>Skip Test</Button>
        </div>
      )}
    </div>
  );
};

export default TorqueTest;