import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const CANTester = ({ 
  pin,
  primaryService,
  startNotificationListener,
  stopNotificationListener,
  getSendPassthroughRW,
  updateControllerSettings,
  readPassthroughValue
}) => {
  // Format node ID (e.g., node 1 = 0x00000601, node 5 = 0x00000605)
  const formatNodeId = (node) => {
    return parseInt(`0x00000${600 + node}`, 16);
  };

  const [selectedNode, setSelectedNode] = useState(1);
  const [index, setIndex] = useState('');
  const [subIndex, setSubIndex] = useState('');
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [data, setData] = useState([]);
  const [currentValue, setCurrentValue] = useState(null);
  const [writeValue, setWriteValue] = useState('');
  const [timeRange, setTimeRange] = useState(30);
  const [dataType, setDataType] = useState('uint32_t');
  const [readWritePassthrough, setReadWritePassthrough] = useState(null);
  const writeInProgress = useRef(false);
  const intervalRef = useRef(null);
  const autoResumeTimeoutRef = useRef(null);

  // Format hex values
  const formatToHexString = (value, padding = 2) => {
    return `0x${value.toString(16).toUpperCase().padStart(padding, '0')}`;
  };

  // Handle input changes
  const handleInputChange = (setter) => (e) => {
    if (isMonitoring) {
      console.log('Stopping monitoring due to input change');
      stopMonitoring();
    }
    setter(e.target.value);
  };

  // Initialize passthrough function
  useEffect(() => {
    if (primaryService) {
      console.log('Initializing passthrough function...');
      getSendPassthroughRW(primaryService).then((sendFunction) => {
        if (sendFunction) {
          console.log('Passthrough function initialized successfully');
          setReadWritePassthrough(() => sendFunction);
        } else {
          console.error('Failed to initialize passthrough function');
        }
      });
    } else {
      setReadWritePassthrough(null);
    }

    return () => {
      setReadWritePassthrough(null);
    };
  }, [primaryService, getSendPassthroughRW]);

  // Notification callback
  const notificationCallback = (event) => {
    if (event) {
      console.log('Received notification:', event);
      const value = new Uint8Array(event.buffer);
      if (value[0] === 'P'.charCodeAt(0)) {
        const { msgIndex, msgSubIndex, canData } = decodePassthroughResponse(value);
        
        if (canData !== null && 
            msgIndex === parseInt(index, 16) && 
            msgSubIndex === parseInt(subIndex, 16)) {
          const timestamp = Date.now();
          setCurrentValue(canData);
          setData(prevData => [...prevData, { timestamp, value: canData }]);
        }
      }
    }
  };

  // Decode passthrough response
  const decodePassthroughResponse = (response) => {
    if (response.length !== 9) {
      console.error('Invalid response length:', response.length);
      return { msgIndex: null, msgSubIndex: null, canData: null };
    }

    const canHeader = response.slice(1, 5);
    const canDataBytes = response.slice(5, 9);
    const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

    const msgIndex = (canHeader[2] << 8) | canHeader[1];
    const msgSubIndex = canHeader[3];

    console.log('Decoded response:', { msgIndex, msgSubIndex, canData });
    return { msgIndex, msgSubIndex, canData };
  };

  const stopMonitoring = () => {
    console.log('Stopping monitoring');
    setIsMonitoring(false);
    stopNotificationListener(primaryService);
    
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const startMonitoring = async () => {
    console.log('Starting monitoring...');
    if (!readWritePassthrough) {
      console.error('Passthrough function not initialized');
      return;
    }

    setIsMonitoring(true);
    
    // Set up notification listener
    stopNotificationListener(primaryService);
    startNotificationListener(primaryService, notificationCallback);

    // Set up polling interval
    intervalRef.current = setInterval(async () => {
        if (!writeInProgress.current) {
          try {
            await readWritePassthrough(
              'read',
              pin, 
              formatNodeId(selectedNode),
              parseInt(index, 16),
              parseInt(subIndex, 16)
            );
          } catch (error) {
            console.error('Error reading parameter:', error);
          }            
        }
    }, 500);
  };

  // Toggle monitoring
  const toggleMonitoring = () => {
    if (!isMonitoring) {
      startMonitoring();
    } else {
      stopMonitoring();
    }
  };

  // Handle real-time write
  const handleRealTimeWrite = async () => {
    console.log('Performing real-time write:', writeValue);
    
    writeInProgress.current = true;
    await new Promise(resolve => setTimeout(resolve, 500));

    try {
      await readWritePassthrough(
        'write',
        pin,
        formatNodeId(selectedNode),
        parseInt(index, 16),
        parseInt(subIndex, 16),
        parseInt(writeValue)
      );
      console.log('Real-time write successful');

        writeInProgress.current = false;
    } catch (error) {
      console.error('Error during real-time write:', error);
    }
  };

  // Handle configuration write
  const handleConfigWrite = async () => {
    console.log('Performing configuration write:', writeValue);
    
    writeInProgress.current = true;
    await new Promise(resolve => setTimeout(resolve, 500));

    try {
      await updateControllerSettings(
        pin,
        [{
          index: index,
          sub_index: subIndex,
          newValue: parseInt(writeValue),
          size: dataType === 'uint8_t' ? 1 : dataType === 'uint16_t' ? 2 : 4
        }],
        primaryService
      );
      console.log('Configuration write successful');

        await new Promise(resolve => setTimeout(resolve, 2000));
        writeInProgress.current = false;

    } catch (error) {
      console.error('Error during configuration write:', error);
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      console.log('Component unmounting, cleaning up...');
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
      if (autoResumeTimeoutRef.current) {
        clearTimeout(autoResumeTimeoutRef.current);
        autoResumeTimeoutRef.current = null;
      }
      if (primaryService) {
        stopNotificationListener(primaryService);
      }
    };
  }, [primaryService, stopNotificationListener]);

  // Prepare chart data
  const now = Date.now();
  const chartData = {
    labels: data
      .filter((entry) => now - entry.timestamp <= timeRange * 1000)
      .map((entry) => -(now - entry.timestamp) / 1000),
    datasets: [{
      label: `Parameter ${formatToHexString(parseInt(index || '0', 16))}.${formatToHexString(parseInt(subIndex || '0', 16))}`,
      data: data
        .filter((entry) => now - entry.timestamp <= timeRange * 1000)
        .map((entry) => entry.value),
      fill: false,
      borderColor: '#2563eb',
      tension: 0.4,
      pointRadius: 0,
    }],
  };

  const chartOptions = {
    animation: false,
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          text: 'Time (seconds ago)',
        },
        min: -timeRange,
        max: 0,
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>CAN Bus Tester</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form className="mb-4">
          <div className="row mb-3">
            <div className="col-md-4">
              <Form.Group>
                <Form.Label>Node (1-9)</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  max="9"
                  value={selectedNode}
                  onChange={handleInputChange(setSelectedNode)}
                />
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group>
                <Form.Label>Index (hex)</Form.Label>
                <Form.Control
                  value={index}
                  onChange={handleInputChange(setIndex)}
                  placeholder="e.g. 1000"
                />
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group>
                <Form.Label>SubIndex (hex)</Form.Label>
                <Form.Control
                  value={subIndex}
                  onChange={handleInputChange(setSubIndex)}
                  placeholder="e.g. 00"
                />
              </Form.Group>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-4">
              <Form.Group>
                <Form.Label>Value to Write</Form.Label>
                <Form.Control
                  type="number"
                  value={writeValue}
                  onChange={(e) => setWriteValue(e.target.value)}
                  placeholder="Enter value"
                />
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group>
                <Form.Label>Data Type</Form.Label>
                <Form.Select
                  value={dataType}
                  onChange={(e) => setDataType(e.target.value)}
                >
                  <option value="uint8_t">uint8_t</option>
                  <option value="uint16_t">uint16_t</option>
                  <option value="uint32_t">uint32_t</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-md-5">
              <Form.Group>
                <Form.Label>&nbsp;</Form.Label>
                <div className="d-flex gap-2">
                  <Button onClick={handleRealTimeWrite} variant="primary">
                    Real-time Write
                  </Button>
                  <Button onClick={handleConfigWrite} variant="warning">
                    Configuration Write
                  </Button>
                </div>
              </Form.Group>
            </div>
          </div>
          
          <div className="row mb-3">
            <div className="col-md-4">
              <Form.Group>
                <Form.Label>Time Range</Form.Label>
                <Form.Select 
                  value={timeRange} 
                  onChange={(e) => setTimeRange(Number(e.target.value))}
                >
                  <option value={30}>Last 30 seconds</option>
                  <option value={60}>Last 60 seconds</option>
                  <option value={120}>Last 120 seconds</option>
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Group>
                <Form.Label>&nbsp;</Form.Label>
                <div>
                  <Button 
                    onClick={toggleMonitoring}
                    variant={isMonitoring ? "danger" : "success"}
                    className="w-100"
                  >
                    {isMonitoring ? 'Stop Monitoring' : 'Start Monitoring'}
                  </Button>
                </div>
              </Form.Group>
            </div>
          </div>

          {currentValue !== null && (
            <div className="mb-3">
              Current Value: {currentValue}
            </div>
          )}

          <div style={{ height: '400px' }}>
            <Line data={chartData} options={chartOptions} />
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CANTester;