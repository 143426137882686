import React, { useState, useEffect, useRef } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import SideMenu from "./sidemenu.component";
import PinComponent from "./pin.component";
import CanbusTestComponent from "./canbus-tests.component";
import ReadWriteTestsComponent from "./canbus-readWrite.component"
import AppTesterComponent from "./appTester.component"
import DFUTestComponent from "./DFUTester.component"
import BMSTesterComponent from "./bmsTester.component"
import CANTesterComponent from "./canTester.component"

const DevToolsComponent = ({
  userGroup,
  verifyPin,
  primaryService,
  writePassthroughCharacteristic,
  disconnectFromDevice,
  connectToDevice,
  isConnected,
  bikeSerial,
  getSendPassthroughRW,
  startNotificationListener,
  stopNotificationListener,
  readPassthroughValue,
  updateControllerSettings,
  getDeviceVersionInfo,
  performFirmwareUpdate
}) => {
  const [pin, setPin] = useState("");
  const [pinVerified, setPinVerified] = useState(false);
  const navigate = useNavigate();
  const dfuComponentRef = useRef(null);

  useEffect(() => {
    if (!isConnected) {
      if (window.location.pathname.includes('/dev-tools/dfu-tester') && dfuComponentRef.current) {
        dfuComponentRef.current.triggerDownload();
      }
      navigate("/dev-tools/pin-entry");
    }
  }, [isConnected, navigate]);

  return (
    <div className="container-fluid container-diagnostics-module">
      <div className="row">
        {isConnected && pinVerified && (
          <div className="col-md-2">
            <SideMenu
              isConnected={isConnected}
              pinVerified={pinVerified}
              disconnectFromDevice={disconnectFromDevice}
              primaryService={primaryService}
              pin={pin}
            />
          </div>
        )}
        <div className={`col-md-${isConnected && pinVerified ? "10" : "12"} main-content h-100`}>
          {isConnected && pinVerified && (
            <div className="row">
              <div className="col-12">
                <h3>Dev Tools</h3>
              </div>
            </div>
          )}
          <Routes>
            <Route
              path="/pin-entry"
              element={
                <PinComponent
                  userGroup={userGroup}
                  bikeSerial={bikeSerial}
                  primaryService={primaryService}
                  verifyPin={verifyPin}
                  setPinVerified={setPinVerified}
                  writePassthroughCharacteristic={writePassthroughCharacteristic}
                  disconnectFromDevice={disconnectFromDevice}
                  connectToDevice={connectToDevice}
                  isConnected={isConnected}
                  startNotificationListener={startNotificationListener}
                  stopNotificationListener={stopNotificationListener}
                  pin={pin}
                  setPin={setPin}
                />
              }
            />
            <Route
              path="/can-tester"
              element={
                <CANTesterComponent
                  bikeSerial={bikeSerial}
                  primaryService={primaryService}
                  verifyPin={verifyPin}
                  writePassthroughCharacteristic={writePassthroughCharacteristic}
                  disconnectFromDevice={disconnectFromDevice}
                  connectToDevice={connectToDevice}
                  isConnected={isConnected}
                  getSendPassthroughRW={getSendPassthroughRW}
                  startNotificationListener={startNotificationListener}
                  stopNotificationListener={stopNotificationListener}
                  pin={pin}
                  readPassthroughValue={readPassthroughValue}
                  updateControllerSettings={updateControllerSettings}
                />
              }
            />
            <Route
              path="/canbus-tests"
              element={
                <CanbusTestComponent
                  bikeSerial={bikeSerial}
                  primaryService={primaryService}
                  verifyPin={verifyPin}
                  writePassthroughCharacteristic={writePassthroughCharacteristic}
                  disconnectFromDevice={disconnectFromDevice}
                  connectToDevice={connectToDevice}
                  isConnected={isConnected}
                  getSendPassthroughRW={getSendPassthroughRW}
                  startNotificationListener={startNotificationListener}
                  stopNotificationListener={stopNotificationListener}
                  pin={pin}
                />
              }
            />
            <Route
              path="/canbus-rw"
              element={
                <ReadWriteTestsComponent
                  bikeSerial={bikeSerial}
                  primaryService={primaryService}
                  verifyPin={verifyPin}
                  writePassthroughCharacteristic={writePassthroughCharacteristic}
                  disconnectFromDevice={disconnectFromDevice}
                  connectToDevice={connectToDevice}
                  isConnected={isConnected}
                  getSendPassthroughRW={getSendPassthroughRW}
                  startNotificationListener={startNotificationListener}
                  stopNotificationListener={stopNotificationListener}
                  pin={pin}
                  readPassthroughValue={readPassthroughValue}
                  updateControllerSettings={updateControllerSettings}
                />
              }
            />
            <Route
              path="/app-tester"
              element={
                <AppTesterComponent
                  primaryService={primaryService}
                  writePassthroughCharacteristic={writePassthroughCharacteristic}
                  getSendPassthroughRW={getSendPassthroughRW}
                  startNotificationListener={startNotificationListener}
                  stopNotificationListener={stopNotificationListener}
                  getDeviceVersionInfo={getDeviceVersionInfo}
                  pin={pin}
                />
              }
            />
            <Route
              path="/bms-tester"
              element={
                <BMSTesterComponent
                  primaryService={primaryService}
                  getSendPassthroughRW={getSendPassthroughRW}
                  startNotificationListener={startNotificationListener}
                  stopNotificationListener={stopNotificationListener}
                  pin={pin}
                />
              }
            />
            <Route
              path="/dfu-tester"
              element={
                <DFUTestComponent
                  ref={dfuComponentRef}
                  primaryService={primaryService}
                  performFirmwareUpdate={performFirmwareUpdate}
                />
              }
            />
            <Route
              path="*"
              element={
                <Navigate to="/dev-tools/pin-entry"/>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default DevToolsComponent;
