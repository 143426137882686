import React, { useState, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import AuthService from "../services/auth.service";
import AnalyticsService from "../services/analytics.service";
import { useNavigate, Link } from "react-router-dom";
import { BoxArrowLeft } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import Debug from "../services/debug.js";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = ({setLoadingScreen}) => {
  const { t, i18n } = useTranslation();

  const [loggedIn, setLoggedIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [language, setLanguage] = useState("en");
  const navigate = useNavigate();

  useEffect(() => {
    Debug.logger(1, 'setLoadingScreen', setLoadingScreen)
    const checkLoginStatus = async () => {
      try {
        const currentUser = await AuthService.getCurrentUser();
        if (currentUser) {
          setLoggedIn(true);
        }
      } catch (error) {
        Debug.logger(1, "Error checking user status:", error);
      }
    };

    checkLoginStatus();

    setLanguage(i18n.language);
    Debug.logger(1, 'i18n.language', i18n.language)
  }, [language]);

  const switchFrench = () => {
    i18n.changeLanguage("fr");
    setLanguage(i18n.language);
  }

  const switchEnglish = () => {
    i18n.changeLanguage("en");
    setLanguage(i18n.language);
  }

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoadingScreen(true); // Use the setLoading function

    Debug.logger(1, 'calling authservice.login');

    AuthService.login(email, password)
      .then((response) => {
        Debug.logger(1, 'LOGIN RESPONSE', response);
        if (response.locale && (response.locale === 'en_US' || response.locale === 'fr')) {
          i18n.changeLanguage(response.locale);
        }
        AuthService.getCurrentUserData().then((user) => {
          setLoadingScreen(false);
          AnalyticsService.track('login', user.brand, user);
          navigate("/modules");
        }).catch((error) => {
          setLoadingScreen(false);
          setMessage('Error fetching user data');
        });
      })
      .catch((error) => {
        if (error.data && error.data.data && error.data.data.message) {
          setMessage(t(error.data.data.message));
        } else {
          setMessage(t('An error occurred during login'));
        }
        setLoadingScreen(false);
      });
  }

  return (
    <div className="container-fluid login-wrapper">
      <div className="col-md-6 offset-md-1 title-column">
        {loggedIn && navigate("/modules")}
        <div className="top">
          <img src="../imgs/ftex-logo-color.svg" alt="FTEX Evionics Operating System" />
          <h1>
            {t('Evionics Platform')}
          </h1>
        </div>
        <div className="bottom">
          <h2 className="text-secondary-color">
            {t('We believe in')}
          </h2>
          <h2>
            {t('powering electric vehicles')}
          </h2>
        </div>
      </div>
      <div className="col-md-3 offset-md-1 text-center login-column">
        <div className="card card-container">
          <h2>
            {t('Get Started')}
          </h2>
          <p>{t('Please enter your details!')}</p>

          <Form onSubmit={handleLogin}>
            <div className="form-group">
              <Input
                type="text"
                className="form-control"
                name="email"
                placeholder={t('Email')}
                value={email}
                onChange={onChangeEmail}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <Input
                type="password"
                className="form-control"
                name="password"
                placeholder={t('Password')}
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>
            {message && (
              <div className="form-group">
                <div className="login-error alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <Link className="forgot-password" to={"/forgotPassword"}>
              {t('Forgot my password?')}
            </Link>
            <div className="form-group">
              <button
                className="btn btn-block btn-primary btn-big"
              >
                <span>{t('Login')}</span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;