import React, { useState, useEffect, useRef } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import SideMenu from "./sidemenu.component";
import QCComponent from './qualityControl.component';

const ManufacturingComponent = () => {

  return (
    <div className="container-fluid container-diagnostics-module">
      <div className="row">
        <div className="col-md-2">
          <SideMenu
          />
        </div>
        <div className="col-md-10 main-content h-100">
          <div className="row">
            <div className="col-12">
              <h3>Manufacturing Tool</h3>
            </div>
          </div>
          <Routes>
            <Route
              path="*"
              element={
                <QCComponent
                />
              }
            />
            <Route
              path="/qc"
              element={
                <QCComponent
                />
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default ManufacturingComponent;