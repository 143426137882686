import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, Link } from 'react-router-dom';

const OverviewComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { docId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const documents = [
    {
      id: 'config-docs',
      fileName: 'Configuration Module Documentation.pdf',
      title: 'Configuration Module Documentation',
      subTitle: 'PDF',
      icon: '/imgs/modules/docs-icon.svg',
      path: '/docs/Configuration Module Documentation.pdf',
      description: t('Complete user guide on how to configure your controllers')
    },
    {
      id: 'dev-tools-docs',
      fileName: 'Dev Tools Module Documentation.pdf',
      title: 'Dev Tools Module Documentation',
      subTitle: 'PDF',
      icon: '/imgs/modules/docs-icon.svg',
      path: '/docs/Dev Tools Module Documentation.pdf',
      description: t("Guide for using FTEX's developer tools to test new features")
    }
  ];

  const selectedDoc = documents.find(doc => doc.id === docId);

  const handleDocumentSelect = (doc) => {
    setLoading(true);
    navigate(`/docs/view/${doc.id}`);
  };

  const handleBack = () => {
    navigate('/docs');
  };

  if (selectedDoc) {
    return (
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <button 
              className="btn btn-outline-primary"
              onClick={handleBack}
            >
              <i className="fas fa-arrow-left me-2"></i>
              {t('Back to Documents')}
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h2>{selectedDoc.title}</h2>
            {error && (
              <div className="alert alert-danger">
                {error}
              </div>
            )}
            <div className="document-content-wrapper bg-white">
              <embed
                src={selectedDoc.path}
                type="application/pdf"
                width="100%"
                height="800px"
                onLoad={() => setLoading(false)}
                onError={() => {
                  setError(t('Error loading document'));
                  setLoading(false);
                }}
              />
              {loading && (
                <div className="text-center p-5 position-absolute top-50 start-50 translate-middle">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{t('Loading...')}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid documentation-wrapper">
      <div className="row">
        <div className="col-12">
          <h3 className="text-secondary-color text-center">{t('Product Guides and Manuals')}</h3>
        </div>
      </div>
      
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="row row-cols-5 g-4">
            {documents.map((doc) => (
              <div key={doc.id} className="col">
                <div 
                  className="card card-container module-card h-100 cursor-pointer"
                  onClick={() => handleDocumentSelect(doc)}
                >
                  <img 
                    src={doc.icon} 
                    alt={`${doc.title} icon`} 
                    className="card-img-top p-3" 
                  />
                  <div className="card-body">
                    <h5 className="card-title">{t(doc.title)}</h5>
                    <h6 className="card-subtitle mb-2 text-secondary-color">
                      {t(doc.subTitle)}
                    </h6>
                    <p className="card-text small">{doc.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewComponent;