import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Table, Alert, ProgressBar } from 'react-bootstrap';
import { useCAN } from '../../hooks/useCAN.hook';
import _ from 'lodash';

const BMSTesterComponent = ({
    pin,
    primaryService,
    startNotificationListener,
    stopNotificationListener,
    getSendPassthroughRW
}) => {
    // Get the protocol data
    const { getProtocolsSeparately } = useCAN();
    const protocols = getProtocolsSeparately();
    const bmsProtocol = protocols?.bms || {};

    // Refs for managing operations
    const logsEndRef = useRef(null);
    const intervalsRef = useRef([]);
    const gattQueueRef = useRef([]);
    const gattOperationInProgressRef = useRef(false);

    // State management
    const [readWritePassthrough, setReadWritePassthrough] = useState(null);
    const [isMonitoring, setIsMonitoring] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState(null);
    const [logs, setLogs] = useState([]);
    const [logFilter, setLogFilter] = useState('ALL');

    // Helper function to add logs
    function addLog(...args) {
        const level = typeof args[args.length - 1] === 'string' ? args.pop() : 'INFO';
        const message = args
            .map(arg => {
                if (typeof arg === 'object') return JSON.stringify(arg, null, 2);
                return String(arg);
            })
            .join(' ');
            
        const timestamp = new Date().toISOString().split('T')[1].slice(0, -1);
        setLogs(prev => {
            const newLogs = [...prev, { timestamp, message, level }];
            return newLogs.slice(-1000);
        });
    }

    // Helper function to parse index
    function parseIndex(indexStr) {
        if (!indexStr) return null;
        if (typeof indexStr === 'number') return indexStr;
        return parseInt(indexStr.replace('0x', ''), 16);
    }

    // Helper function to format timestamp
    function formatLastUpdate(timestamp) {
        return timestamp ? new Date(timestamp).toLocaleTimeString() : 'N/A';
    }

    // Protocol parameter organization
    function getProtocolParams() {
        const params = {
            bootup: {},
            adhoc: {},
            realtime: {}
        };

        if (!bmsProtocol || Object.keys(bmsProtocol).length === 0) {
            return params;
        }

        Object.entries(bmsProtocol).forEach(([paramName, paramDetails]) => {
            const index = parseInt(paramDetails.index.replace('0x', ''), 16);
            
            if (index === 0x0040) params.bootup[paramName] = paramDetails;
            else if (index === 0x0050) params.adhoc[paramName] = paramDetails;
            else if (index >= 0x0020 && index <= 0x0030) params.realtime[paramName] = paramDetails;
        });
        
        return params;
    }

    // Initialize values state
    const [values, setValues] = useState(() => {
        const initialValues = { bootup: {}, adhoc: {}, realtime: {} };
        const params = getProtocolParams();
        
        Object.entries(params).forEach(([category, categoryParams]) => {
            Object.entries(categoryParams).forEach(([paramName, paramDetails]) => {
                initialValues[category][paramName] = {
                    value: null,
                    lastUpdate: null,
                    valid: false,
                    metadata: paramDetails
                };
            });
        });
        
        return initialValues;
    });

    // Value validation
    function validateValue(value, param) {
        if (!param) return true;
        
        if (param.validRange) {
            return value >= param.validRange.min && value <= param.validRange.max;
        }
        
        if (param.validOptions) {
            return param.validOptions.some(option => option.value === value);
        }

        return true;
    }

    // Decode response from device
    function decodePassthroughResponse(response) {
        console.log('response', response)
        if (response.length !== 9) {
            addLog('Invalid response length:', response.length, 'WARNING');
            return null;
        }

        const canDataBytes = response.slice(5, 9);
        const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);
        const msgIndex = (response[3] << 8) | response[2];
        const msgSubIndex = response[4];

        return { msgIndex, msgSubIndex, canData };
    }

    // Queue management
    function enqueueGattOperation(args = []) {
        const operationKey = `function-${JSON.stringify(args)}`;
        
        const isDuplicate = gattQueueRef.current.some(op => op.key === operationKey);
        if (args[0] === 'write' || !isDuplicate) {
            gattQueueRef.current.push({ args, key: operationKey });
            runNextGattOperation();
        }
    }

    async function runNextGattOperation() {
        if (!readWritePassthrough || !pin || gattOperationInProgressRef.current) {
            return;
        }

        if (gattQueueRef.current.length > 0) {
            const operation = gattQueueRef.current.shift();
            try {
                gattOperationInProgressRef.current = true;
                await readWritePassthrough(...operation.args);
                
                const [command, pin, nodeId, index, subIndex] = operation.args;
                addLog(
                    `Send command to BMS: NodeID: ${nodeId} Index: 0x${index.toString(16).padStart(4, '0')} SubIndex: 0x${subIndex.toString(16).padStart(2, '0')}`,
                    'INFO'
                );
            } catch (error) {
                addLog('GATT operation failed:', error.message, 'ERROR');
            } finally {
                gattOperationInProgressRef.current = false;
                setTimeout(runNextGattOperation, 50);
            }
        }
    }

    // Handle device notifications
    function handleNotification(event) {
        if (!event?.buffer) return;

        const value = new Uint8Array(event.buffer);
        if (value[0] !== 80) { // 80 is ASCII code for 'P'
            addLog('Invalid notification prefix', 'WARNING');
            return;
        }

        const result = decodePassthroughResponse(value);

        if (!result) return;

        const { msgIndex, msgSubIndex, canData } = result;
        const params = getProtocolParams();

        addLog(
            `Received values for index ${msgIndex}, subIndex ${msgSubIndex}: ${canData}`,
            'INFO'
        );


        // Update matching parameters
        Object.entries(params).forEach(([category, categoryParams]) => {
            Object.entries(categoryParams).forEach(([paramName, paramDetails]) => {
                if (
                    parseIndex(paramDetails.index) === msgIndex &&
                    parseIndex(paramDetails.sub_index) === msgSubIndex
                ) {
                    const isValid = validateValue(canData, paramDetails);
                    if (!isValid) {
                        addLog(
                            `Value ${canData} for ${paramName} is out of range`,
                            'ERROR'
                        );
                    }

                    setValues(prev => ({
                        ...prev,
                        [category]: {
                            ...prev[category],
                            [paramName]: {
                                value: canData,
                                lastUpdate: Date.now(),
                                valid: isValid,
                                metadata: paramDetails
                            }
                        }
                    }));
                }
            });
        });
    }

    // Monitoring control
    async function stopMonitoring() {
        setIsFetching(true);
        try {
            addLog('Starting cleanup process...', 'INFO');
            
            intervalsRef.current.forEach(clearInterval);
            intervalsRef.current = [];
            
            if (primaryService) {
                await stopNotificationListener(primaryService);
                addLog('Notification listener stopped', 'INFO');
            }
            
            gattQueueRef.current = [];
            gattOperationInProgressRef.current = false;
            
        } catch (error) {
            addLog('Error during cleanup:', error.message, 'ERROR');
            throw error;
        } finally {
            setIsFetching(false);
        }
    }

    async function startMonitoring() {
        if (!readWritePassthrough || !pin || isFetching) {
            addLog('Cannot start monitoring - missing required parameters', 'ERROR');
            return;
        }

        setIsFetching(true);
        try {
            await stopMonitoring();
            
            await startNotificationListener(primaryService, handleNotification);
            addLog('Notification listener started', 'INFO');
            
            const params = getProtocolParams();

            // Queue initial reads
            Object.entries(params).forEach(([category, categoryParams]) => {
                Object.entries(categoryParams).forEach(([paramName, paramDetails]) => {
                    const protocolIndex = parseIndex(paramDetails.index);
                    const protocolSubindex = parseIndex(paramDetails.sub_index);
                    
                    if (protocolIndex !== null && protocolSubindex !== null) {
                        enqueueGattOperation([
                            'read',
                            pin,
                            '0x00000605',
                            protocolIndex,
                            protocolSubindex
                        ]);
                    }
                });
            });

            // Set up monitoring intervals
            const realtimeInterval = setInterval(() => {
                if (!gattOperationInProgressRef.current) {
                    Object.entries(params.realtime).forEach(([paramName, paramDetails]) => {
                        enqueueGattOperation([
                            'read',
                            pin,
                            '0x00000605',
                            parseIndex(paramDetails.index),
                            parseIndex(paramDetails.sub_index)
                        ]);
                    });
                }
            }, 1000);

            const adhocInterval = setInterval(() => {
                if (!gattOperationInProgressRef.current) {
                    Object.entries(params.adhoc).forEach(([paramName, paramDetails]) => {
                        enqueueGattOperation([
                            'read',
                            pin,
                            '0x00000605',
                            parseIndex(paramDetails.index),
                            parseIndex(paramDetails.sub_index)
                        ]);
                    });
                }
            }, 5000 + Math.random() * 5000);

            intervalsRef.current = [realtimeInterval, adhocInterval];
            addLog('Monitoring intervals set up', 'INFO');

        } catch (error) {
            addLog('Failed to start monitoring:', error.message, 'ERROR');
            throw error;
        } finally {
            setIsFetching(false);
        }
    }

    async function handleMonitoringToggle() {
        try {
            if (isMonitoring) {
                addLog('Stopping monitoring...', 'INFO');
                await stopMonitoring();
                setIsMonitoring(false);
            } else {
                addLog('Starting monitoring...', 'INFO');
                await startMonitoring();
                setIsMonitoring(true);
            }
        } catch (error) {
            addLog('Error toggling monitoring:', error.message, 'ERROR');
            setIsMonitoring(false);
        }
    }

    // Download logs functionality
    function downloadLogs() {
        if (logs.length === 0) return;

        const deviceName = primaryService?.device?.name || 'unknown-device';
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        const fileName = `bms-test-${deviceName}-${timestamp}.txt`;
        
        const fileContent = logs
            .map(log => `[${log.timestamp}][${log.level}] ${log.message}`)
            .join('\n');

        const blob = new Blob([fileContent], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    // Setup effect
    useEffect(() => {
        if (primaryService) {
            getSendPassthroughRW(primaryService).then((sendFunction) => {
                if (sendFunction) {
                    setReadWritePassthrough(() => sendFunction);
                    addLog('Passthrough function initialized', 'INFO');
                } else {
                    addLog('Failed to initialize passthrough function', 'ERROR');
                }
            });
        }
    }, [primaryService]);

    // Cleanup effect
    useEffect(() => {
        return () => {
            if (isMonitoring) {
                stopMonitoring().catch(error => {
                    console.error('Cleanup error:', error);
                });
            }
        };
    }, [isMonitoring]);

    if (!bmsProtocol || Object.keys(bmsProtocol).length === 0) {
        return (
            <Alert variant="danger">
                BMS protocol data is not available. Please check your connection and try again.
            </Alert>
        );
    }

    return (
        <div className="container-fluid">
            {/* Control Button */}
            <Button
                variant={isMonitoring ? "danger" : "success"}
                onClick={handleMonitoringToggle}
                disabled={!readWritePassthrough || !pin || isFetching}
            >
                {isMonitoring ? 'Stop Monitoring' : 'Start Monitoring'}
            </Button>

            {/* Boot-up Values Card */}
            <h4 className="mt-3 mb-n4">Boot-up Parameters</h4>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Value</th>
                        <th>Last Update</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(getProtocolParams().adhoc).map(([key, paramDetails]) => (
                        <tr key={key} className={!values.adhoc[key]?.valid ? 'table-danger' : ''}>
                            <td>{paramDetails.description}</td>
                            <td>
                                {values.adhoc[key]?.value !== null ? 
                                    `${values.adhoc[key].value}${paramDetails.unit ? ` ${paramDetails.unit}` : ''}` : 
                                    'N/A'
                                }
                            </td>
                            <td>{formatLastUpdate(values.adhoc[key]?.lastUpdate)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Real-time Values Card */}
            <Card className="mb-3">
                <Card.Header>
                    <h4 className="mb-0">Real-time Parameters</h4>
                </Card.Header>
                <Card.Body>
                    <Table striped bordered>
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Value</th>
                        <th>Index</th>
                        <th>Subindex</th>
                        <th>Last Update</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(values.realtime).map(([key, valueObj]) => (
                        <tr key={key} className={!valueObj.valid ? 'table-danger' : ''}>
                            <td>{valueObj.metadata.description}</td>
                            <td>
                                {key === 'CO_PARAM_EXTERNAL_BMS_SOC' && valueObj.value !== null ? (
                                    <div>
                                        {valueObj.value}%
                                        <ProgressBar 
                                            now={valueObj.value} 
                                            variant={valueObj.value > 20 ? "success" : "danger"}
                                            className="mt-2"
                                        />
                                    </div>
                                ) : key === 'CO_PARAM_EXTERNAL_BMS_ERROR_STATE' && valueObj.value !== null ? (
                                    valueObj.metadata.validOptions?.find(opt => opt.value === valueObj.value)?.description || `${valueObj.value}`
                                ) : key === 'CO_PARAM_EXTERNAL_BMS_STATE' && valueObj.value !== null ? (
                                    valueObj.metadata.validOptions?.find(opt => opt.value === valueObj.value)?.description || `${valueObj.value}`
                                ) : (
                                    valueObj.value !== null ? 
                                        `${valueObj.value}${valueObj.metadata.unit ? ` ${valueObj.metadata.unit}` : ''}` : 
                                        'N/A'
                                )}
                            </td>
                            <td>{valueObj.metadata.index}</td>
                            <td>{valueObj.metadata.sub_index}</td>
                            <td>{formatLastUpdate(valueObj.lastUpdate)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
                </Card.Body>
            </Card>

            {/* Logs Section */}
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center">
                    <h3>Debug Logs</h3>
                    <div>
                        <select 
                            value={logFilter}
                            onChange={(e) => setLogFilter(e.target.value)}
                            className="form-select me-2 d-inline-block w-auto"
                        >
                            <option value="ALL">All Logs</option>
                            <option value="ERROR">Errors Only</option>
                            <option value="WARNING">Warnings & Errors</option>
                            <option value="INFO">Info Only</option>
                        </select>
                        <Button
                            variant="primary"
                            onClick={downloadLogs}
                            disabled={logs.length === 0}
                            className="me-2"
                        >
                            Download Logs
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => setLogs([])}
                            disabled={logs.length === 0}
                        >
                            Clear Logs
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <pre 
                        style={{ 
                            maxHeight: '500px', 
                            overflowY: 'auto',
                            backgroundColor: '#f8f9fa',
                            padding: '1rem'
                        }}
                    >
                        {logs
                            .filter(log => 
                                logFilter === 'ALL' || 
                                (logFilter === 'WARNING' && ['WARNING', 'ERROR'].includes(log.level)) ||
                                log.level === logFilter
                            )
                            .map((log, index) => (
                                <div 
                                    key={index}
                                    className={
                                        log.level === 'ERROR' ? 'text-danger' :
                                        log.level === 'WARNING' ? 'text-warning' :
                                        'text-muted'
                                    }
                                >
                                    [{log.timestamp}][{log.level}] {log.message}
                                </div>
                            ))}
                        <div ref={logsEndRef} />
                    </pre>
                </Card.Body>
            </Card>
        </div>
    );
};

export default BMSTesterComponent;