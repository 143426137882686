import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import AuthService from "../services/auth.service";
import AnalyticsService from "../services/analytics.service";
import { useTranslation } from 'react-i18next';
import Debug from "../services/debug.js";
import Logo from "./logo.component";

const ModulesComponent = ({ userGroup, setUserGroup, currentUser, setCurrentUser, setLoadingScreen }) => {
  const { t, i18n } = useTranslation();
  const [userReady, setUserReady] = useState(false);
  const navigate = useNavigate();

  const normalizeRoles = (roles) => {
    if (!roles) return [];
    
    // If roles is already an array, return it
    if (Array.isArray(roles)) return roles;
    
    // If roles is an object with numeric keys (like {0: 'role1', 1: 'role2'})
    if (typeof roles === 'object') {
      return Object.values(roles);
    }
    
    // If it's a single string, wrap it in an array
    if (typeof roles === 'string') {
      return [roles];
    }
    
    return [];
  };

  useEffect(() => {
    const authedUser = AuthService.getCurrentUser();
    const group = AuthService.getCurrentGroup();
    setUserGroup(group);
    Debug.logger(1, 'authedUser', authedUser);
    Debug.logger(1, 'group', group);

    if (!authedUser) {
      navigate("/login");
      return;
    }

    // Normalize roles before setting the current user
    if (authedUser) {
      const normalizedUser = {
        ...authedUser,
        roles: normalizeRoles(authedUser.roles)
      };
      setCurrentUser(normalizedUser);
      
      // If the roles were transformed, you might want to update the stored user
      if (!Array.isArray(authedUser.roles)) {
        try {
          // Update the stored user with normalized roles
          const userStr = localStorage.getItem("user");
          if (userStr) {
            const storedUser = JSON.parse(userStr);
            storedUser.roles = normalizedUser.roles;
            localStorage.setItem("user", JSON.stringify(storedUser));
          }
        } catch (error) {
          Debug.logger(1, 'Error updating stored user roles:', error);
        }
      }
    }
    
    setUserReady(true);
  }, [navigate]);


  const hasPermission = (permission) => {
    Debug.logger(1, 'hasPermission', permission, currentUser);
    
    if (!currentUser) return false;
    const userRoles = normalizeRoles(currentUser.roles);
    
    // Check if user has admin roles or the specific permission
    return userRoles.some(role => 
      role === 'admin' || 
      role === 'administrator' ||
      role === 'diagnostics-admin' || 
      role === permission
    );
  };

  const modules = [
    {
      path: "/diagnostics",
      icon: "../imgs/modules/diagnostics-icon.svg",
      title: "Diagnostics",
      subTitle: "Tool",
      description: "Remote troubleshooting for bike brands, and equips workshops. Rapid diagnostic and repair tools.",
      permission: null // Always visible
    },
    {
      path: "/fleet",
      icon: "../imgs/modules/fleet-icon.svg",
      title: "Fleet Manager",
      subTitle: "Tool",
      description: "Monitoring of the general activity metrics and state of health of vehicles on the road.",
      permission: "fleet"
    },
    {
      path: "/users",
      icon: "../imgs/modules/users-icon.svg",
      title: "Users Admin",
      subTitle: "Tool",
      description: "Creation of user roles for all new users. Distribution of the access to other tools of the SAAS.",
      permission: "users"
    },
    {
      path: "/analytics",
      icon: "../imgs/modules/users-icon.svg",
      title: "Analytics",
      subTitle: "Module",
      description: "Get detailed analytics, statistics and information on how your users are interacting with their bikes, the mobile app and detailed error reporting.",
      permission: "analytics"
    },
    {
      path: "/manufacturing",
      icon: "../imgs/modules/users-icon.svg",
      title: "Manufacturing",
      subTitle: "Tool",
      description: "Tools for performing quality assurance testing by bike assemblers, and supply chain tracking.",
      permission: "manufacturing"
    },
    {
      path: "/configuration",
      icon: "../imgs/modules/users-icon.svg",
      title: "Configuration",
      subTitle: "Tool",
      description: "The configuration tool allows brands to connect to your IoT enabled bike and modify its settings.",
      permission: "configtool"
    },
    {
      path: "/dev-tools",
      icon: "../imgs/modules/users-icon.svg",
      title: "Dev",
      subTitle: "Tools",
      description: "The DevTools allows for testing and debugging controller firmware releases.",
      permission: "devtools"
    },
    {
      path: "/docs",
      icon: "../imgs/modules/users-icon.svg",
      title: "Documentation",
      subTitle: "Module",
      description: "Collection of documentation and user guides for FTEX products.",
      permission: "documentation"
    }
  ];

  return (
    <div className="container-fluid modules-wrapper text-center">
      <div className="row justify-content-center">
        <div className="col-md-11">
          <Logo userGroup={userGroup} noDefault={true}/>
          <img className="title-svg" src="../imgs/ftex-icon-color.svg" alt="FTEX Evionics Operating System" />
          <h1>{t('Evionics Modules')}</h1>
          <h3 className="text-secondary-color">{t('End-to-End Vehicles Management System')}</h3>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-9">
          <div className="row justify-content-center module-container">
            {modules.map((module, index) => (
              (!module.permission || hasPermission(module.permission)) && (
                <div key={index} className="col-md-4">
                  <Link to={module.path} className="card card-container module-card">
                    <img src={module.icon} alt={`${module.title} icon`} />
                    <h3>{t(module.title)}</h3>
                    <h4 className="text-secondary-color">{t(module.subTitle)}</h4>
                    <p>{t(module.description)}</p>
                  </Link>
                </div>
              )
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModulesComponent;