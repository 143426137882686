import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ManufacturingSideMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleBackClick = () => {
    const pathParts = location.pathname.split('/').filter(Boolean);
    if (pathParts.length > 1) {
      const parentPath = '/' + pathParts.slice(0, -1).join('/');
      navigate(parentPath);
    } else {
      navigate('/'); // Default route
    }
  };

  const isQCActive = location.pathname === '/manufacturing' || location.pathname === '/manufacturing/qc';

  return (
    <div className="ftex-side-menu">
      <ul>
        <li>{t('Menu')}</li>
        <li className={`nav-item ${isQCActive ? 'active' : ''}`}>
          <Link to="/manufacturing/qc">{t('Quality Control')}</Link>
        </li>
      </ul>
      <button className="btn btn-block btn-big" onClick={handleBackClick}>
        {t('Back')}
      </button>
    </div>
  );
};

export default ManufacturingSideMenu;
