import React, { useState } from 'react';
import { Modal, Button, Form, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

const DFUModal = ({ show, onHide, primaryService, pin, performFirmwareUpdate }) => {
    const { t } = useTranslation();
    
    const [firmwareFile, setFirmwareFile] = useState(null);
    const [firmwarePin, setFirmwarePin] = useState('');
    const [firmwareError, setFirmwareError] = useState('');
    const [updating, setUpdating] = useState(false);
    const [failed, setFailed] = useState(false);
    const [success, setSuccess] = useState(false);
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('');
    const [logs, setLogs] = useState([]);

    const handleDropFirmwareFile = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && (file.name.endsWith('.bin') || file.name.endsWith('.dfu'))) {
            setFirmwareFile(file);
            setFirmwareError('');
        } else {
            setFirmwareError(t('Please select a valid .bin or .dfu file.'));
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleDropFirmwareFile,
        accept: {
            'application/octet-stream': ['.bin', '.dfu']
        },
        multiple: false,
    });

    const handleStartFirmwareUpdate = async () => {
        if (!firmwareFile || !firmwarePin) {
            setFirmwareError(t('Please select a firmware file and enter a valid 4-digit PIN.'));
            return;
        }

        setUpdating(true);
        setFailed(false);
        setSuccess(false);
        setFirmwareError('');

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const firmwareData = new Uint8Array(e.target.result);
                const updateLogs = function (...args) {
                    const logMessage = args.map(arg => 
                        typeof arg === 'object' ? JSON.stringify(arg) : String(arg)
                    ).join(' ');
                    
                    setLogs(prevLogs => {
                        const newLogs = [...prevLogs, logMessage];
                        // Keep only the last 5 messages
                        return newLogs.slice(-5);
                    });
                }

                await performFirmwareUpdate(primaryService, firmwarePin, firmwareData, (progress, statusMessage) => {
                    setProgress(progress);
                    setStatus(statusMessage);
                    if (progress === 100) {
                        setSuccess(true);
                        setUpdating(false);
                        // Set status to inform user about the expected disconnect
                        setStatus(t('Update successful! Device is restarting... You may need to reconnect.'));
                    }
                    if (progress === 0 && updating) {
                        setFailed(true);
                        setFirmwareError(statusMessage);
                        setUpdating(false);
                    }
                }, updateLogs);
                
            } catch (error) {
                let errorMessage = error.message;
                // Check if error is due to expected disconnect
                if (error.message.includes('disconnected') && progress === 100) {
                    setSuccess(true);
                    setStatus(t('Update successful! Device is restarting... You may need to reconnect.'));
                } else {
                    setFailed(true);
                    setFirmwareError(t('Firmware update failed: ') + errorMessage);
                }
                setUpdating(false);
            }
        };
        reader.readAsArrayBuffer(firmwareFile);
    };

    const handleClose = () => {
        setFirmwareFile(null);
        setFirmwarePin('');
        setFirmwareError('');
        setUpdating(false);
        setFailed(false);
        setSuccess(false);
        setProgress(0);
        setStatus('');
        setLogs([]);  // Clear logs when closing
        onHide();
    };

    const getModalContent = () => {
        if (success) {
            return (
                <div className="text-center p-4">
                    <h4 className="text-success mb-3">{t('Firmware Update Successful!')}</h4>
                    <p>{status || t('The device has been updated successfully and is now restarting.')}</p>
                    <p className="text-muted mb-3">{t('Please wait a moment and reconnect to the device.')}</p>
                    <Button variant="primary" onClick={handleClose}>
                        {t('Close')}
                    </Button>
                </div>
            );
        }

        if (failed) {
            return (
                <div className="text-center p-4">
                    <h4 className="text-danger mb-3">{t('Firmware Update Failed')}</h4>
                    <p className="text-danger mb-4">{firmwareError}</p>
                    <div className="d-flex justify-content-center gap-2">
                        <Button variant="secondary" onClick={handleClose}>
                            {t('Close')}
                        </Button>
                        <Button variant="primary" onClick={() => {
                            setFailed(false);
                            setFirmwareError('');
                            setProgress(0);
                            setStatus('');
                            setLogs([]);  // Clear logs before retrying
                            handleStartFirmwareUpdate();
                        }}>
                            {t('Try Again')}
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <>
                <Form>
                    <Form.Group controlId="formFirmwareFile">
                        <Form.Label>{t('Select Firmware File')}</Form.Label>
                        <div {...getRootProps()} className="dropzone p-4 border border-dashed rounded mb-3">
                            <input {...getInputProps()} />
                            <p className="mb-1">{t('Drag & drop a firmware file here, or click to select one')}</p>
                            {firmwareFile && <p className="mb-0 text-success">{t('Selected file:')} {firmwareFile.name}</p>}
                        </div>
                    </Form.Group>
                    <Form.Group controlId="formFirmwarePin" className="mt-3">
                        <Form.Label>{t('Enter 4-digit PIN')}</Form.Label>
                        <Form.Control
                            type="text"
                            maxLength="4"
                            value={firmwarePin}
                            onChange={(e) => setFirmwarePin(e.target.value.replace(/[^0-9]/g, '').slice(0, 4))}
                            placeholder={t('Enter PIN')}
                            disabled={updating}
                        />
                    </Form.Group>
                    {firmwareError && (
                        <div className="text-danger mt-3">
                            {firmwareError}
                        </div>
                    )}
                    {updating && (
                        <div className="mt-3">
                            <ProgressBar 
                                now={progress} 
                                label={`${Math.ceil(progress)}%`}
                                variant="primary"
                            />
                            {status && <p className="mt-2 text-center">{status}</p>}
                            {logs.length > 0 && (
                                <div className="mt-3">
                                    <pre className="bg-light p-2 rounded" style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                        {logs.join('\n')}
                                    </pre>
                                </div>
                            )}
                        </div>
                    )}
                </Form>
            </>
        );
    };

    return (
        <Modal show={show} onHide={handleClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('Firmware Update')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getModalContent()}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('Cancel')}
                </Button>
                {!success && !failed && !updating && (
                    <Button variant="primary" onClick={handleStartFirmwareUpdate}>
                        {t('Start Update')}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default DFUModal;