import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, ProgressBar, Table, Alert, Card, Row, Col } from "react-bootstrap";
import { useCAN } from "../../hooks/useCAN.hook";

const MAX_RETRIES = 1;
const RETRY_DELAY = 200;
const QUEUE_PROCESS_DELAY = 50;

const decodePassthroughResponse = (response) => {
    if (response.length < 9) {
        console.error("Invalid response length:", response.length);
        return null;
    }

    const canHeader = response.slice(1, 5);
    const canDataBytes = response.slice(5, 9);
    const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

    const index = (canHeader[2] << 8) | canHeader[1];
    const subIndex = canHeader[3];

    return { index, subIndex, canData };
};

const isValueInRange = (value, parameter) => {
    console.log('isValueInRange', value, parameter);
    if (parameter.validRange) {
        return value >= parameter.validRange.min && value <= parameter.validRange.max;
    }
    if (parameter.validOptions) {
        return parameter.validOptions.some(option => option.value === value);
    }
    return true;
};

const formatRangeDisplay = (parameter) => {
    if (parameter.validRange) {
        return `${parameter.validRange.min} - ${parameter.validRange.max}${parameter.unit ? ` ${parameter.unit}` : ''}`;
    }
    if (parameter.validOptions) {
        return parameter.validOptions.map(opt => 
            `${opt.value}${opt.description ? ` (${opt.description})` : ''}`
        ).join(', ');
    }
    return 'No constraints';
};

const ResultsSection = ({ protocolParams, results, title }) => {
    return (
        <div className="mb-4">
            <h4>{title}</h4>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Parameter</th>
                        <th>Value</th>
                        <th>Expected Range</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(protocolParams).map(([paramName, paramDetails]) => {
                        const result = results[paramName];
                        const rangeDisplay = formatRangeDisplay(paramDetails);
                        const isInRange = result ? isValueInRange(result.value, paramDetails) : false;

                        return (
                            <tr key={paramName} className={!result ? 'bg-light' : ''}>
                                <td className="font-monospace">
                                    <div>{paramName}</div>
                                    <small className="text-muted">
                                        {`Index: ${paramDetails.index}, Sub: ${paramDetails.sub_index}`}
                                    </small>
                                    {paramDetails.description && (
                                        <small className="text-muted d-block">
                                            {paramDetails.description}
                                        </small>
                                    )}
                                </td>
                                <td className={`font-monospace ${
                                    !result ? 'text-danger' :
                                    !isInRange ? 'bg-danger bg-opacity-25' :
                                    result.retryCount > 0 ? 'text-warning' :
                                    'text-success'
                                }`}>
                                    {!result ? 'Read Failed' : 
                                        `${result.value}${paramDetails.unit ? ` ${paramDetails.unit}` : ''}`
                                    }
                                </td>
                                <td>
                                    {rangeDisplay}
                                </td>
                                <td>
                                    {!result ? (
                                        <span className="text-danger">Failed to read</span>
                                    ) : !isInRange ? (
                                        <span className="text-danger">Out of range</span>
                                    ) : result.retryCount > 0 ? (
                                        <span className="text-warning">Succeeded after retry</span>
                                    ) : (
                                        <span className="text-success">Success</span>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

const CanBusTestsComponent = ({ 
    pin, 
    primaryService, 
    startNotificationListener, 
    stopNotificationListener, 
    getSendPassthroughRW 
}) => {
    const { getProtocolsSeparately } = useCAN();
    const [isTestRunning, setIsTestRunning] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const [progress, setProgress] = useState(0);
    const [results, setResults] = useState({
        public: {},
        internal: {},
        bms: {}
    });
    const [summary, setSummary] = useState(null);

    // Refs for managing state and operations
    const protocolsRef = useRef({});
    const protocolTypesRef = useRef({});
    const gattQueueRef = useRef([]);
    const gattOperationInProgressRef = useRef(false);
    const readWritePassthrough = useRef(null);
    const receivedValues = useRef(new Map());
    const retryCountsRef = useRef(new Map());
    const totalParametersRef = useRef(0);
    const isCancelledRef = useRef(false);

    const prepareTestParameters = () => {
        const protocols = getProtocolsSeparately();
        protocolsRef.current = protocols;
        
        let parametersList = [];
        Object.entries(protocols).forEach(([protocolType, protocolParams]) => {
            Object.entries(protocolParams).forEach(([paramName, paramDetails]) => {
                if (paramDetails.index && paramDetails.sub_index) {
                    const param = {
                        name: paramName,
                        index: parseInt(paramDetails.index, 16),
                        subIndex: parseInt(paramDetails.sub_index, 16),
                        details: paramDetails,
                        protocolType
                    };
                    parametersList.push(param);
                    protocolTypesRef.current[`${param.index}-${param.subIndex}`] = protocolType;
                }
            });
        });

        totalParametersRef.current = parametersList.length;
        return parametersList;
    };

    const updateLiveResults = (parameterKey, result) => {
        const protocolType = protocolTypesRef.current[parameterKey];
        if (protocolType) {
            setResults(prev => ({
                ...prev,
                [protocolType]: {
                    ...prev[protocolType],
                    [result.name]: result
                }
            }));
        }
    };

    const enqueueOperation = (parameter) => {
        const { index, subIndex } = parameter;
        gattQueueRef.current.push({ 
            parameter,
            args: ["read", pin, 0x00000601, index, subIndex]
        });
    };

    const processQueue = async () => {
        if (isCancelledRef.current) {
            handleTestCompletion();
            return;
        }

        if (!readWritePassthrough.current && primaryService) {
            await startNotificationListener(primaryService, handleNotification);
            readWritePassthrough.current = await getSendPassthroughRW(primaryService);
        }

        if (gattOperationInProgressRef.current || gattQueueRef.current.length === 0) {
            if (gattQueueRef.current.length === 0) {
                handleTestCompletion();
            }
            return;
        }

        const operation = gattQueueRef.current.shift();
        try {
            gattOperationInProgressRef.current = true;
            await readWritePassthrough.current(...operation.args);
        } catch (error) {
            console.error("GATT operation failed:", error);
            handleReadFailure(operation.parameter);
        } finally {
            gattOperationInProgressRef.current = false;
            setTimeout(processQueue, QUEUE_PROCESS_DELAY);
        }
    };

    const handleNotification = (event) => {
        const value = new Uint8Array(event.buffer);
        const decoded = decodePassthroughResponse(value);
        
        if (!decoded) return;

        const { index, subIndex, canData } = decoded;
        const parameterKey = `${index}-${subIndex}`;
        
        const protocolType = protocolTypesRef.current[parameterKey];
        if (!protocolType) return;

        const protocol = protocolsRef.current[protocolType];
        const matchingParam = Object.entries(protocol)
            .find(([_, param]) => 
                parseInt(param.index, 16) === index && 
                parseInt(param.sub_index, 16) === subIndex
            );

        if (matchingParam) {
            const [paramName, paramDetails] = matchingParam;
            const retryCount = retryCountsRef.current.get(parameterKey) || 0;
            const isInRange = isValueInRange(canData, paramDetails);

            const result = {
                name: paramName,
                value: canData,
                retryCount,
                isInRange,
                details: paramDetails
            };

            receivedValues.current.set(parameterKey, result);
            updateLiveResults(parameterKey, result);
            updateProgress();
        }
    };

    const handleReadFailure = (parameter) => {
        const parameterKey = `${parameter.index}-${parameter.subIndex}`;
        const retryCount = retryCountsRef.current.get(parameterKey) || 0;

        if (retryCount < MAX_RETRIES) {
            retryCountsRef.current.set(parameterKey, retryCount + 1);
            gattQueueRef.current.push({ 
                parameter,
                args: ["read", pin, 0x00000601, parameter.index, parameter.subIndex]
            });
        } else {
            const result = {
                name: parameter.name,
                value: null,
                retryCount,
                isInRange: false,
                details: parameter.details,
                failed: true
            };
            
            receivedValues.current.set(parameterKey, result);
            updateLiveResults(parameterKey, result);
            updateProgress();
        }
    };

    const updateProgress = () => {
        const progress = (receivedValues.current.size / totalParametersRef.current) * 100;
        setProgress(Math.round(progress));
    };

    const handleTestCompletion = () => {
        const successfulReads = Array.from(receivedValues.current.values())
            .filter(v => v.value !== null).length;
        const outOfRangeCount = Array.from(receivedValues.current.values())
            .filter(v => !v.isInRange).length;
        const retriedCount = Array.from(receivedValues.current.values())
            .filter(v => v.retryCount > 0).length;

        setSummary({
            total: totalParametersRef.current,
            successful: successfulReads,
            failed: totalParametersRef.current - successfulReads,
            outOfRange: outOfRangeCount,
            retried: retriedCount
        });

        setIsTestRunning(false);
        setShowProgress(false);
        stopNotificationListener(primaryService);
    };

    const startTest = () => {
        const protocols = getProtocolsSeparately();
        protocolsRef.current = protocols;

        setIsTestRunning(true);
        setShowProgress(true);
        setProgress(0);
        setSummary(null);
        setResults({
            public: {},
            internal: {},
            bms: {}
        });
        
        receivedValues.current.clear();
        retryCountsRef.current.clear();
        gattQueueRef.current = [];
        readWritePassthrough.current = null;
        isCancelledRef.current = false;

        // Prepare parameters in protocol order
        let parametersList = [];
        Object.entries(protocols).forEach(([protocolType, protocolParams]) => {
            Object.entries(protocolParams).forEach(([paramName, paramDetails]) => {
                if (paramDetails.index && paramDetails.sub_index) {
                    const param = {
                        name: paramName,
                        index: parseInt(paramDetails.index, 16),
                        subIndex: parseInt(paramDetails.sub_index, 16),
                        details: paramDetails,
                        protocolType
                    };
                    parametersList.push(param);
                    protocolTypesRef.current[`${param.index}-${param.subIndex}`] = protocolType;
                }
            });
        });

        totalParametersRef.current = parametersList.length;
        
        // Queue all parameters
        parametersList.forEach(param => enqueueOperation(param));
        processQueue();
    };

    const cancelTest = () => {
        isCancelledRef.current = true;
        gattQueueRef.current.forEach(({ parameter }) => {
            const parameterKey = `${parameter.index}-${parameter.subIndex}`;
            const result = {
                name: parameter.name,
                value: null,
                retryCount: 0,
                isInRange: false,
                details: parameter.details,
                failed: true
            };
            receivedValues.current.set(parameterKey, result);
            updateLiveResults(parameterKey, result);
        });
        gattQueueRef.current = [];
        handleTestCompletion();
    };

    return (
        <div className="container-fluid">
            <Card>
                <Card.Header>
                    <Card.Title>CAN Bus Protocol Tests</Card.Title>
                    <Card.Subtitle className="text-muted">
                        Test and validate CAN bus protocol parameters
                    </Card.Subtitle>
                </Card.Header>
                <Card.Body>
                    <Button 
                        onClick={startTest} 
                        disabled={isTestRunning}
                        className="w-100 mb-4"
                        variant="primary"
                    >
                        Begin Protocol Tests
                    </Button>

                    {summary && (
                        <Row className="mb-4">
                            <Col>
                                <Alert variant="info">
                                    Total Parameters: {summary.total}
                                </Alert>
                            </Col>
                            <Col>
                                <Alert variant="success">
                                    Successful: {summary.successful}
                                </Alert>
                            </Col>
                            <Col>
                                <Alert variant="danger">
                                    Failed: {summary.failed}
                                </Alert>
                            </Col>
                            <Col>
                                <Alert variant="warning">
                                    Retried: {summary.retried}
                                </Alert>
                            </Col>
                            <Col>
                                <Alert variant="danger">
                                    Out of Range: {summary.outOfRange}
                                </Alert>
                            </Col>
                        </Row>
                    )}

                    {/* Show all parameters in protocol order, including failed ones */}
                    {protocolsRef.current.public && (
                        <ResultsSection 
                            protocolParams={protocolsRef.current.public}
                            results={results.public}
                            title="Public Protocol Parameters"
                        />
                    )}
                    {protocolsRef.current.internal && (
                        <ResultsSection 
                            protocolParams={protocolsRef.current.internal}
                            results={results.internal}
                            title="Internal Protocol Parameters"
                        />
                    )}
                    {protocolsRef.current.bms && (
                        <ResultsSection 
                            protocolParams={protocolsRef.current.bms}
                            results={results.bms}
                            title="BMS Protocol Parameters"
                        />
                    )}
                </Card.Body>
            </Card>

            <Modal
                show={showProgress}
                onHide={() => !isTestRunning && setShowProgress(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Test Progress</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProgressBar 
                        now={progress} 
                        label={`${progress}%`} 
                        className="mb-3"
                    />
                    <div className="text-center">
                        Testing {totalParametersRef.current} parameters...
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {isTestRunning ? (
                        <Button variant="danger" onClick={cancelTest}>
                            Cancel Test
                        </Button>
                    ) : (
                        <Button variant="secondary" onClick={() => setShowProgress(false)}>
                            Close
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CanBusTestsComponent;
