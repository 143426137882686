import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Logo from '../logo.component';
import ReactCodeInput from 'react-code-input';

const MAX_RETRIES = 2;
const RETRY_DELAY = 100;
const QUEUE_PROCESS_DELAY = 50;

const decodePassthroughResponse = (response) => {
    if (response.length < 9) {
        console.error('Invalid response length:', response.length);
        return null;
    }

    const canHeader = response.slice(1, 5);
    const canDataBytes = response.slice(5, 9);
    const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

    const index = (canHeader[2] << 8) | canHeader[1];
    const subIndex = canHeader[3];

    return { index, subIndex, canData };
};

const PinEntryComponent = ({ 
    userGroup,
    bikeSerial,
    primaryService,
    verifyPin,
    connectToDevice,
    isConnected,
    disconnectFromDevice,
    handleDataLoaded,
    updateMapping,
    dataLoaded,
    startNotificationListener,
    stopNotificationListener,
    getSendPassthroughRW,
    mapping,
    setMapping,
    pin,
    setPin,
    reloadMapping,
    resetStates,
    setDataLoaded,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [fetchErrors, setFetchErrors] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);

    // Refs for managing state and operations
    const protocolParamsRef = useRef([]);
    const gattQueueRef = useRef([]);
    const gattOperationInProgressRef = useRef(false);
    const readWritePassthrough = useRef(null);
    const receivedValues = useRef(new Map());
    const retryCountsRef = useRef(new Map());
    const totalParametersRef = useRef(0);
    const isCancelledRef = useRef(false);

    useEffect(() => {
        setMenuItems(Object.keys(mapping));
    }, [mapping]);

    useEffect(() => {
        if (pin.length === 4 && isConnected) {
            handleSubmit();
        }
    }, [pin]);

    useEffect(() => {
        if (!isConnected) {
            setDataLoaded(false);
            setLoading(false);
            setProgress(0);
        }
    }, [isConnected, resetStates, setDataLoaded]);
    
    const prepareParameters = () => {
        let parametersList = [];
        
        Object.entries(mapping).forEach(([categoryKey, category]) => {
            Object.entries(category).forEach(([subCategoryKey, subCategory]) => {
                Object.entries(subCategory.variables).forEach(([variableKey, variableDetails]) => {
                    if (variableDetails.index && variableDetails.sub_index) {
                        const param = {
                            key: variableKey,
                            index: parseInt(variableDetails.index, 16),
                            subIndex: parseInt(variableDetails.sub_index, 16),
                            details: variableDetails
                        };
                        parametersList.push(param);
                    }
                });
            });
        });

        totalParametersRef.current = parametersList.length;
        return parametersList;
    };

    const enqueueOperation = (parameter) => {
        const { index, subIndex } = parameter;
        gattQueueRef.current.push({ 
            parameter,
            args: ["read", pin, 0x00000601, index, subIndex]
        });
    };

    const processQueue = async () => {
        if (isCancelledRef.current) {
            handleFetchCompletion();
            return;
        }

        if (!readWritePassthrough.current && primaryService) {
            await startNotificationListener(primaryService, handleNotification);
            readWritePassthrough.current = await getSendPassthroughRW(primaryService);
        }

        if (gattOperationInProgressRef.current || gattQueueRef.current.length === 0) {
            if (gattQueueRef.current.length === 0) {
                handleFetchCompletion();
            }
            return;
        }

        const operation = gattQueueRef.current.shift();
        try {
            gattOperationInProgressRef.current = true;
            await readWritePassthrough.current(...operation.args);
        } catch (error) {
            console.error("GATT operation failed:", error);
            handleReadFailure(operation.parameter);
        } finally {
            gattOperationInProgressRef.current = false;
            setTimeout(processQueue, QUEUE_PROCESS_DELAY);
        }
    };

    const handleNotification = (event) => {
        const value = new Uint8Array(event.buffer);
        const decoded = decodePassthroughResponse(value);
        
        if (!decoded) return;

        const { index, subIndex, canData } = decoded;
        const parameterKey = `${index}-${subIndex}`;
        
        const matchingParam = protocolParamsRef.current.find(param => 
            param.index === index && param.subIndex === subIndex
        );

        if (matchingParam) {
            const retryCount = retryCountsRef.current.get(parameterKey) || 0;
            
            const result = {
                key: matchingParam.key,
                value: canData,
                retryCount
            };

            receivedValues.current.set(parameterKey, result);
            updateProgress();
        }
    };

    const handleReadFailure = (parameter) => {
        const parameterKey = `${parameter.index}-${parameter.subIndex}`;
        const retryCount = retryCountsRef.current.get(parameterKey) || 0;

        if (retryCount < MAX_RETRIES) {
            retryCountsRef.current.set(parameterKey, retryCount + 1);
            setTimeout(() => {
                gattQueueRef.current.push({ 
                    parameter,
                    args: ["read", pin, 0x00000601, parameter.index, parameter.subIndex]
                });
            }, RETRY_DELAY);
        }
    };

    const updateProgress = () => {
        const progress = (receivedValues.current.size / totalParametersRef.current) * 100;
        setProgress(Math.round(progress));
    };

    const handleFetchCompletion = () => {
        // Update mapping with all received values
        receivedValues.current.forEach((result) => {
            updateMapping(result.key, result.value, true);
        });

        // Check for any failed reads
        const errors = [];
        protocolParamsRef.current.forEach(param => {
            const paramKey = `${param.index}-${param.subIndex}`;
            if (!receivedValues.current.has(paramKey)) {
                errors.push({
                    key: param.key,
                    index: param.index,
                    subIndex: param.subIndex,
                    details: param.details
                });
            }
        });

        setLoading(false);
        stopNotificationListener(primaryService);
        handleDataLoaded();

        if (errors.length > 0) {
            setFetchErrors(errors);
            setShowErrorModal(true);
        } else {
            navigate(`/configuration/${menuItems[0].toLowerCase()}`);
        }
    };

    const startFetching = async () => {
        // Reset all state
        setLoading(true);
        setProgress(0);
        receivedValues.current.clear();
        retryCountsRef.current.clear();
        gattQueueRef.current = [];
        readWritePassthrough.current = null;
        isCancelledRef.current = false;

        // Prepare parameters
        const parametersList = prepareParameters();
        protocolParamsRef.current = parametersList;

        // Queue all parameters
        parametersList.forEach(param => enqueueOperation(param));
        processQueue();
    };

    const handlePinChange = (value) => {
        setPin(value);
    };

    const handleDisconnection = () => {
        setShowModal(true);
        disconnectFromDevice();
        reloadMapping();
    };

    const handleConnect = async () => {
        try {
            const serial = await connectToDevice();
            if (serial) {
                setLoading(false);
            }
        } catch (error) {
            console.error('Connection error:', error);
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        setError('');
        setLoading(true);

        try {
            const isValid = await verifyPin(pin, primaryService);
            if (isValid) {
                await startFetching(); // Don't set loading false here, let handleFetchCompletion do it
            } else {
                setError(t('Invalid PIN. Please try again.'));
                handleDisconnection();
                setPin("");
                setLoading(false); // Only set loading false for error cases
            }
        } catch (err) {
            setError(t('Error verifying PIN. Please try again.'));
            handleDisconnection();
            setPin("");
            setLoading(false); // Only set loading false for error cases
        }
        // Remove the finally block as we want loading to persist during fetchCompletion
    };



    return (
        <>
            <div className="col-md-12 connection pin-verification text-center">
                <Logo userGroup={userGroup} />
                {!isConnected ? (
                    // Not Connected - Show Connect UI
                    <div className="text-center">
                        <p>{t('Please turn on Bluetooth on both devices.')}</p>
                        <button 
                            onClick={handleConnect} 
                            className="btn btn-primary btn-large" 
                            disabled={loading}
                        >
                            {loading ? t('Connecting...') : t('Connect to Bike')}
                        </button>
                    </div>
                ) : !primaryService ? (
                    // Connected but waiting for service
                    <div className="text-center">
                        <p>{t('Loading primary service...')}</p>
                    </div>
                ) : !loading ? (
                    // Connected with service, show PIN entry
                    <div className="row text-center d-flex align-items-center justify-content-center h-100">
                        <div className="col-12 text-left">
                            <div className="pin-entry">
                                <h4>{t("Enter PIN")}</h4>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <ReactCodeInput
                                            type="number"
                                            fields={4}
                                            id="pin"
                                            value={pin}
                                            onChange={setPin}
                                        />
                                    </div>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <button type="submit" className="btn btn-block btn-primary">
                                        {t('Submit')}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            
            {/* Loading Modal - shown during initial data load or reload */}
            <Modal show={loading} centered backdrop="static">
                <Modal.Header>
                    <Modal.Title>{t('Loading...')} {progress}%</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProgressBar now={progress} />
                </Modal.Body>
            </Modal>
            
            {/* Error Modal - shown for incorrect PIN */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Incorrect PIN')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('Incorrect PIN. Please try again.')}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Fetch Errors Modal */}
            <Modal 
                show={showErrorModal} 
                onHide={() => {
                    setShowErrorModal(false);
                    navigate(`/configuration/${menuItems[0]?.toLowerCase()}`);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t('Failed to Read Some Parameters')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        {t('The following parameters could not be read. You may continue, but some values may be missing or incorrect:')}
                    </div>
                    <div className="border rounded p-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        {fetchErrors.map((error, index) => (
                            <div key={index} className="mb-2">
                                <div className="font-monospace">
                                    <strong>{error.details.input_title || error.key}</strong>
                                </div>
                                <small className="text-muted">
                                    Index: 0x{error.index.toString(16).padStart(4, '0')}, 
                                    SubIndex: 0x{error.subIndex.toString(16).padStart(2, '0')}
                                </small>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="primary" 
                        onClick={() => {
                            setShowErrorModal(false);
                            navigate(`/configuration/${menuItems[0]?.toLowerCase()}`);
                        }}
                    >
                        {t('Continue Anyway')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PinEntryComponent;