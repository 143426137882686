import React from 'react';

const LogoComponent = ({ userGroup, noDefault }) => {
  const defaultLogo = "/imgs/whiteLabel/logos/ftex/ftex-dark-logo.png";
  let logoPath;

  if (!userGroup) {
    userGroup = 'ftex';
  }

  if (userGroup.toLowerCase() === 'ftex-client') {
    userGroup = 'ftex';
  }

  if (!noDefault  && userGroup !== 'ftex') {
    logoPath = userGroup ? `/imgs/whiteLabel/logos/${userGroup.toLowerCase()}/${userGroup.toLowerCase()}-dark-logo.png` : defaultLogo;
  } else if (userGroup.toLowerCase() !== 'ftex' && userGroup.toLowerCase() !== 'admin') {
    logoPath = `/imgs/whiteLabel/logos/${userGroup.toLowerCase()}/${userGroup.toLowerCase()}-dark-logo.png`;
  }

  const altText = userGroup ? `${userGroup} Logo` : "FTEX Logo";
  console.log('logoPath', logoPath);
  if (logoPath) {
    return (
      <img src={logoPath} alt={altText} onError={(e) => { e.target.src = defaultLogo; e.target.alt = "FTEX Logo"; }} />
    );
  }

  return null;
};

export default LogoComponent;
