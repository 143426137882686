import React, { useState, useEffect, useRef } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useCANStream from '../../../hooks/useCANStream.hook';

const MAX_TEST_TIME = 20; // seconds
const FLASH_INTERVAL = 500; // milliseconds - light flashes every 500ms
const NODE_ID = 0x00000601;
const LIGHT_INDEX = 0x2021;
const LIGHT_SUBINDEX = 0x00;

const FrontLightTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const { t } = useTranslation();
  
  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Starting front light test...");
  const [countdown, setCountdown] = useState(MAX_TEST_TIME);
  
  const timerRef = useRef(null);
  const flashIntervalRef = useRef(null);

  // Set up CAN stream
  const { writeValue } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    [], // No stream indices needed for light test
    () => {} // No stream callback needed
  );

  // Start the test timer
  useEffect(() => {
    if (!testRunning || testFinished) return;

    const startTime = Date.now();
    timerRef.current = setInterval(() => {
      const elapsedTime = (Date.now() - startTime) / 1000;
      const remainingTime = Math.max(0, MAX_TEST_TIME - elapsedTime);
      setCountdown(remainingTime);

      if (remainingTime <= 0) {
        handleTestStop(false);
      }
    }, 100);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [testRunning, testFinished]);

  // Control light flashing
  useEffect(() => {
    if (!testRunning || testFinished) return;

    let lightState = 0;
    const flashLight = async () => {
      try {
        await writeValue(NODE_ID, LIGHT_INDEX, LIGHT_SUBINDEX, lightState, true);
        console.log('Light state written:', lightState);
      } catch (error) {
        console.error('Failed to write light state:', error);
      }
    };

    // Start flashing
    flashIntervalRef.current = setInterval(() => {
      lightState = lightState === 0 ? 1 : 0;
      flashLight();
    }, FLASH_INTERVAL);

    // Initial flash
    flashLight();

    return () => {
      if (flashIntervalRef.current) {
        clearInterval(flashIntervalRef.current);
        flashIntervalRef.current = null;
      }
      // Turn off light when cleaning up
      writeValue(NODE_ID, LIGHT_INDEX, LIGHT_SUBINDEX, 0, true)
        .catch(error => console.error('Failed to turn off light during cleanup:', error));
    };
  }, [testRunning, testFinished, writeValue]);

  // Start test automatically on mount
  useEffect(() => {
    startTest();
    return () => cleanup();
  }, []);

  const cleanup = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    if (flashIntervalRef.current) {
      clearInterval(flashIntervalRef.current);
      flashIntervalRef.current = null;
    }
    // Ensure light is off
    writeValue(NODE_ID, LIGHT_INDEX, LIGHT_SUBINDEX, 0, true)
      .catch(error => console.error('Failed to turn off light during cleanup:', error));
  };

  const handleTestStop = (result) => {
    cleanup();
    setTestResult(result);
    setTestRunning(false);
    setTestFinished(true);
    setInstruction(null);
  };

  const startTest = () => {
    cleanup();
    setTestRunning(true);
    setTestResult(null);
    setInstruction("The front light should be flashing. Please observe the front light.");
    setCountdown(MAX_TEST_TIME);
    setTestFinished(false);
  };

  return (
    <div>
      <h1>Step {stepNumber} - Front Light Test</h1>
      <p className="instructions">{instruction}</p>
      
      {testFinished && (
        <div className={testResult ? "success-title" : "fail-title"}>
          <h3>{testResult ? "Test Successful" : "Test Failed"}</h3>
          {testResult 
            ? "The front light was successfully flashing."
            : "You did not see the front light flashing. This means that the front light is either not functioning correctly or not plugged in. Otherwise it could be a controller issue."}
        </div>
      )}
      
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt={t("Warning")} />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      
      {!testFinished && (
        <div>
          <span className="countdown">Time remaining: {Math.ceil(countdown)} seconds</span>
          <ProgressBar
            now={(MAX_TEST_TIME - countdown) * 5}
            className={countdown > 0 ? 'in-progress' : 'timeout'}
          />
        </div>
      )}
      
      {testRunning && (
        <div className="row">
          <div className="col">
            <Button 
              variant="success"
              className="btn btn-large" 
              onClick={() => handleTestStop(true)}>
              Yes, I see the light flashing
            </Button>
          </div>
          <div className="col">
            <Button 
              variant="danger"
              className="btn btn-large" 
              onClick={() => handleTestStop(false)}>
              No, I don't see the light flashing
            </Button>
          </div>
        </div>
      )}
      
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: "Front Light Detected",
            message: "The front light was successfully flashing."
          })}>Next</Button>
        </div>
      )}
      
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={startTest}>
            Restart Test
          </Button>
          <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
            result: false, 
            data: "Front Light Not Detected",
            message: "You did not see the front light flashing. This means that the front light is either not functioning correctly or not plugged in. Otherwise it could be a controller issue."
          })}>Skip Test</Button>
        </div>
      )}
    </div>
  );
};

export default FrontLightTest;