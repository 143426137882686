// devToolsSideMenu.component.js
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SideMenu = ({
  isConnected,
  pinVerified,
  disconnectFromDevice,
  primaryService,
  pin
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleDisconnectClick = () => {
    disconnectFromDevice();
    navigate("/dev-tools/pin-entry");
  };

  return (
    isConnected && pinVerified && (
      <div className="ftex-side-menu">
        <ul>
          <li>{t('Menu')}</li>
          <li className={location.pathname === '/dev-tools/canbus-tests' ? 'active' : ''}>
            <Link className="bike" to="/dev-tools/canbus-tests">{t('CANOpen Read Tests')}</Link>
          </li>
          <li className={location.pathname === '/dev-tools/can-tester' ? 'active' : ''}>
            <Link className="bike" to="/dev-tools/can-tester">{t('CANOpen Manual Tester')}</Link>
          </li>
          <li className={location.pathname === '/dev-tools/canbus-read-write' ? 'active' : ''}>
            <Link className="bike" to="/dev-tools/canbus-rw">{t('CANOpen Persistent Read/Write Tests')}</Link>
          </li>
          <li className={location.pathname === '/dev-tools/app-tester' ? 'active' : ''}>
            <Link className="bike" to="/dev-tools/app-tester">{t('App Tester')}</Link>
          </li>
          <li className={location.pathname === '/dev-tools/dfu-tester' ? 'active' : ''}>
            <Link className="bike" to="/dev-tools/dfu-tester">{t('DFU Tester')}</Link>
          </li>
          <li className={location.pathname === '/dev-tools/bms-tester' ? 'active' : ''}>
            <Link className="bike" to="/dev-tools/bms-tester">{t('BMS Tester')}</Link>
          </li>
        </ul>
        <button
          className="btn btn-block btn-secondary"
          onClick={handleDisconnectClick}
          disabled={loading}
        >
          {loading ? t("Disconnecting...") : t("Disconnect")}
        </button>
      </div>
    )
  );
};

export default SideMenu;
