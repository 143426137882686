import React, { useState, useEffect, useRef } from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import useCANStream from '../../../hooks/useCANStream.hook';

const MAX_TEST_TIME = 20; // seconds
const POLL_INTERVAL = 100; // milliseconds
const MAX_ITERATIONS = (MAX_TEST_TIME * 1000) / POLL_INTERVAL;

const RightBrakeTest = ({
  onResult,
  primaryService,
  pin,
  stepNumber,
  getSendPassthroughRW,
  stopNotificationListener,
  startNotificationListener
}) => {
  const [testRunning, setTestRunning] = useState(false);
  const [testFinished, setTestFinished] = useState(false);
  const [testResult, setTestResult] = useState(null);
  const [instruction, setInstruction] = useState("Waiting for initial brake state to be released...");
  const [countdown, setCountdown] = useState(MAX_TEST_TIME);
  
  const timerRef = useRef(null);
  const iterationCount = useRef(0);
  const hasStartedRef = useRef(false);
  const brakeReadyRef = useRef(false);

  // Stream configuration
  const streamIndices = [{
    nodeId: 0x00000601,
    index: 0x202C,
    subIndex: 0x00
  }];

  const isBrakePressed = (value) => value > 0;
  const isBrakeReleased = (value) => value === 0;

  // Set up CAN stream
  const { values, startStream, stopStream } = useCANStream(
    primaryService,
    pin,
    getSendPassthroughRW,
    stopNotificationListener,
    startNotificationListener,
    streamIndices,
    () => {} // We'll handle values through the values array
  );

  const startTimer = () => {
    if (timerRef.current) return;
    
    console.log("Starting brake test timer");
    timerRef.current = setInterval(() => {
      iterationCount.current += 1;
      
      if (iterationCount.current >= MAX_ITERATIONS) {
        cleanup();
        if (!testFinished) {
          handleTestStop(false);
          setTestFinished(true);
        }
        return;
      }

      const timeElapsed = (iterationCount.current * POLL_INTERVAL) / 1000;
      setCountdown(Math.max(0, MAX_TEST_TIME - timeElapsed));
    }, POLL_INTERVAL);
  };

  // Monitor values array for changes
  useEffect(() => {
    if (!testRunning || testFinished) return;

    const currentValue = values?.[0];
    
    // Wait for valid initial value
    if (!hasStartedRef.current && currentValue !== null && currentValue !== undefined) {
      console.log("Got initial brake value:", currentValue);
      hasStartedRef.current = true;
      
      if (isBrakeReleased(currentValue)) {
        console.log("Initial brake state is released, ready for test");
        brakeReadyRef.current = true;
        setInstruction("Press and hold the right brake.");
        startTimer();
      }
      return;
    }

    // Monitor brake state changes
    if (hasStartedRef.current && currentValue !== null && currentValue !== undefined) {
      if (!brakeReadyRef.current && isBrakeReleased(currentValue)) {
        console.log("Brake released, ready for test");
        brakeReadyRef.current = true;
        setInstruction("Press and hold the right brake.");
        startTimer();
      } else if (brakeReadyRef.current && isBrakePressed(currentValue)) {
        console.log("Brake activated - test passed!");
        handleTestStop(true);
        setTestFinished(true);
      }
    }
  }, [values, testRunning, testFinished]);

  // Cleanup function
  const cleanup = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
    stopStream();
  };

  // Start stream on mount
  useEffect(() => {
    if (!testRunning && !testFinished) {
      console.log("Starting initial brake test setup");
      setTestRunning(true);
      startStream();
    }
    return () => cleanup();
  }, []);

  const handleTestStop = (result) => {
    setInstruction(null);
    setTestResult(result);
    setTestRunning(false);
    cleanup();
  };

  const restartTest = () => {
    cleanup();
    setTestRunning(true);
    setTestResult(null);
    setInstruction("Waiting for initial brake state to be released...");
    setCountdown(MAX_TEST_TIME);
    setTestFinished(false);
    hasStartedRef.current = false;
    brakeReadyRef.current = false;
    iterationCount.current = 0;
    startStream();
  };

  const currentValue = values?.[0] ?? 'Waiting for data...';
  const brakeState = currentValue === 'Waiting for data...' ? 
    'Waiting for data...' : 
    (isBrakePressed(currentValue) ? 'Pressed' : 'Released');

  return (
    <div>
      <h1>Step {stepNumber} - Right Brake Test</h1>
      <p className="instructions">{instruction}</p>
      
      {testFinished && (
        <div className={testResult ? "success-title" : "fail-title"}>
          <h3>{testResult ? "Test Successful" : "Test Failed"}</h3>
          {testResult 
            ? "The right brake was successfully activated."
            : "The right brake was not activated within the time limit."}
        </div>
      )}
      
      {testFinished && (
        <div className="test-summary">
          <p>
            <img src="/imgs/icons/icon-warning.svg" alt="Warning" />
            A summary of the entire test will be displayed at the end.
          </p>
        </div>
      )}
      
      {testResult === null && (
        <p>Current Brake State: {currentValue} ({brakeState})</p>
      )}
      
      {!testFinished && (
        <div>
          {hasStartedRef.current ? (
            <>
              <span className="countdown">Time remaining: {Math.ceil(countdown)} seconds</span>
              <ProgressBar
                now={(MAX_TEST_TIME - countdown) * 5}
                className={countdown > 0 ? 'in-progress' : 'timeout'}
              />
            </>
          ) : (
            <p>Waiting for connection...</p>
          )}
        </div>
      )}
      
      {testResult === true && (
        <div>
          <Button onClick={() => onResult({ 
            result: true, 
            data: "Brake Activated",
            message: "The right brake was successfully activated."
          })}>Next</Button>
        </div>
      )}
      
      {testResult === false && (
        <div>
          <Button className="btn btn-large btn-primary mr-4" onClick={restartTest}>
            Restart Test
          </Button>
          <Button className="btn btn-large btn-secondary" onClick={() => onResult({ 
            result: false, 
            data: "Brake Not Activated",
            message: "The right brake was not activated within the time limit."
          })}>
            Skip Test
          </Button>
        </div>
      )}
    </div>
  );
};

export default RightBrakeTest;