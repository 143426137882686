import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, ProgressBar, Table, Alert, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useAutotune from '../../hooks/useAutotune.hook';
import { useCAN } from '../../hooks/useCAN.hook';

const AutotuneModal = ({ show, onHide, primaryService, pin, updateControllerSettings, readPassthroughValue }) => {
    const { t } = useTranslation();
    const { getProtocolByParam } = useCAN();
    const { 
        startAutotune,
        endAutotune,
        autotuneProgress, 
        autotuneResults, 
        autotuneStatus 
    } = useAutotune();

    const [currentPage, setCurrentPage] = useState(1);
    const [polePairs, setPolePairs] = useState('1');
    const [ratedCurrent, setRatedCurrent] = useState('5');
    const [gearRatio, setGearRatio] = useState('120');
    const [autotuneError, setAutotuneError] = useState('');
    const [displayProgress, setDisplayProgress] = useState(0);
    const [localResults, setLocalResults] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [saveStatus, setSaveStatus] = useState({});
    const [loadError, setLoadError] = useState('');
    const [localAutotuneStatus, setLocalAutotuneStatus] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    // Parameter validation constraints from protocol
    const VALIDATION_RULES = {
        polePairs: {
            min: 1,
            max: 10,
            errorMessage: t('Number of pole pairs must be between 1 and 10')
        },
        ratedCurrent: {
            min: 1,
            max: 65536,
            errorMessage: t('Rated current must be between 1A and 65536A')
        },
        gearRatio: {
            min: 0,
            max: 1000,
            errorMessage: t('Gear ratio must be between 0 and 1000')
        }
    };

    const validateInputs = () => {
        const gearRatioNum = parseInt(gearRatio);
        const polePairsNum = parseInt(polePairs);
        const ratedCurrentNum = parseInt(ratedCurrent);

        if (polePairsNum < VALIDATION_RULES.polePairs.min || 
            polePairsNum > VALIDATION_RULES.polePairs.max) {
            setAutotuneError(VALIDATION_RULES.polePairs.errorMessage);
            return false;
        }

        if (ratedCurrentNum < VALIDATION_RULES.ratedCurrent.min || 
            ratedCurrentNum > VALIDATION_RULES.ratedCurrent.max) {
            setAutotuneError(VALIDATION_RULES.ratedCurrent.errorMessage);
            return false;
        }

        if (gearRatioNum < VALIDATION_RULES.gearRatio.min || 
            gearRatioNum > VALIDATION_RULES.gearRatio.max) {
            setAutotuneError(VALIDATION_RULES.gearRatio.errorMessage);
            return false;
        }

        return true;
    };

    useEffect(() => {
        if (show) {
            const loadDefaults = async () => {
                setIsLoading(true);
                setLoadError('');

                try {
                    const polesPairsProtocol = getProtocolByParam('CO_PARAM_MOTOR_CONFIG_POLEPAIRS');
                    const gearRatioProtocol = getProtocolByParam('CO_PARAM_MOTOR_CONFIG_GEAR_RATIO');

                    if (!polesPairsProtocol || !gearRatioProtocol) {
                        throw new Error('Protocol information not found');
                    }

                    const polesValue = await readPassthroughValue(
                        pin,
                        0x00000601,
                        parseInt(polesPairsProtocol.Index, 16),
                        parseInt(polesPairsProtocol.Subindex, 16),
                        primaryService
                    );

                    setPolePairs(polesValue.toString())

                    await new Promise(resolve => setTimeout(resolve, 100));

                    const gearValue = await readPassthroughValue(
                        pin,
                        0x00000601,
                        parseInt(gearRatioProtocol.Index, 16),
                        parseInt(gearRatioProtocol.Subindex, 16),
                        primaryService
                    );

                    setGearRatio(gearValue.toString());

                } catch (error) {
                    console.error('Error loading defaults:', error);
                    setLoadError(t('Failed to load current motor settings'));
                    setPolePairs('1');
                    setGearRatio('120');
                } finally {
                    setIsLoading(false);
                }
            };

            loadDefaults();
        }
    }, [show]);

    useEffect(() => {
        if (!show) {
            setCurrentPage(1);
            setAutotuneError('');
            setDisplayProgress(0);
            setLocalResults(null);
            setSaveStatus({});
            setLoadError('');
            setIsSaving(false);
        }
    }, [show]);

    useEffect(() => {
        if (!isNaN(autotuneProgress)) {
            setDisplayProgress(Math.min(Math.round(parseInt(autotuneProgress)), 100));
        }
    }, [autotuneProgress]);

    useEffect(() => {
        const requiredResults = [
            'Iq_Kp',
            'Iq_Ki',
            'Id_Kp',
            'Id_Ki',
            'Stator_Resistance',
            'Stator_Inductance',
            'Ke',
            'Rated_Torque',
            'Rated_Speed'
        ];

        if (autotuneStatus === 'error' || 
            (autotuneResults && typeof autotuneResults === 'string' && autotuneResults.includes('Error'))) {
            setLocalAutotuneStatus('error');
            setAutotuneError(typeof autotuneResults === 'string' ? autotuneResults : autotuneResults.error || 'Unknown error occurred');
            setCurrentPage(2);
            return;
        }

        if (autotuneStatus === 'running') {
            setLocalAutotuneStatus('running');
            setCurrentPage(2);
            setAutotuneError('');
            return;
        }

        if (autotuneProgress === 101 && 
            autotuneResults && 
            typeof autotuneResults === 'object' &&
            !autotuneResults.error &&
            requiredResults.every(key => key in autotuneResults)) {
            setLocalResults(JSON.parse(JSON.stringify(autotuneResults)));
            setLocalAutotuneStatus('success');
            setCurrentPage(3);
            return;
        }

        if (currentPage === 2 && autotuneStatus === null) {
            setLocalAutotuneStatus('error');
            setAutotuneError(t('An unknown error occurred. Please try again.'));
            return;
        }
    }, [autotuneResults, autotuneStatus, autotuneProgress, currentPage, t]);

    const handleStartAutotune = async () => {
        setAutotuneError('');
        setLocalResults(null);
        setSaveStatus({});
        
        if (!validateInputs()) {
            return;
        }

        try {
            setCurrentPage(2);
            await startAutotune(
                primaryService, 
                pin, 
                0x00000601, 
                parseInt(polePairs), 
                parseInt(ratedCurrent),
                parseInt(gearRatio)
            );
        } catch (error) {
            console.error('Error starting autotune:', error);
            setAutotuneError(t('Failed to start autotune process')); 
        }
    };

    const handleClose = () => {
        if (autotuneError) {
            endAutotune();
        }
        setAutotuneError('');
        setCurrentPage(1);
        setDisplayProgress(0);
        setLocalResults(null);
        setLocalAutotuneStatus(null);
        setSaveStatus({});
        onHide();
    };

    const handleSaveSettings = async () => {
        if (!localResults || isSaving) return;
        console.log('handleSaveSettings');
        setIsSaving(true);
        const saveResults = {};
        let hasErrors = false;
        const allUpdates = [];

        // Map the param names to their shorter result key names
        const paramToResultKeyMap = {
            'CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_IQ_KP': 'Iq_Kp',
            'CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_IQ_KI': 'Iq_Ki',
            'CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_ID_KP': 'Id_Kp',
            'CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_ID_KI': 'Id_Ki',
            'CO_PARAM_AUTOTUNE_OUTPUTS_STATOR_RESISTANCE': 'Stator_Resistance',
            'CO_PARAM_AUTOTUNE_OUTPUTS_STATOR_INDUCTANCE': 'Stator_Inductance',
            'CO_PARAM_AUTOTUNE_KE': 'Ke'
        };

        const mappings = [
            {
                sourceParam: 'CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_IQ_KP',
                targetParams: ['CO_PARAM_MOTOR_TORQUE_PID_IQ_KP_THRESHOLD_1', 'CO_PARAM_MOTOR_TORQUE_PID_IQ_KP_THRESHOLD_2']
            },
            {
                sourceParam: 'CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_IQ_KI',
                targetParams: ['CO_PARAM_MOTOR_TORQUE_PID_IQ_KI_THRESHOLD_1', 'CO_PARAM_MOTOR_TORQUE_PID_IQ_KI_THRESHOLD_2']
            },
            {
                sourceParam: 'CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_ID_KP',
                targetParams: ['CO_PARAM_MOTOR_TORQUE_PID_ID_KP_THRESHOLD_1', 'CO_PARAM_MOTOR_TORQUE_PID_ID_KP_THRESHOLD_2']
            },
            {
                sourceParam: 'CO_PARAM_AUTOTUNE_OUTPUTS_TORQUECONTROL_ID_KI',
                targetParams: ['CO_PARAM_MOTOR_TORQUE_PID_ID_KI_THRESHOLD_1', 'CO_PARAM_MOTOR_TORQUE_PID_ID_KI_THRESHOLD_2']
            },
            {
                sourceParam: 'CO_PARAM_AUTOTUNE_OUTPUTS_STATOR_RESISTANCE',
                targetParams: ['CO_PARAM_MOTOR_CONFIG_STATOR_RESISTANCE']
            },
            {
                sourceParam: 'CO_PARAM_AUTOTUNE_OUTPUTS_STATOR_INDUCTANCE',
                targetParams: ['CO_PARAM_MOTOR_CONFIG_STATOR_INDUCTANCE']
            },
            {
                sourceParam: 'CO_PARAM_AUTOTUNE_KE',
                targetParams: ['CO_PARAM_MOTOR_CONFIG_MAGNET_FLUX']
            }
        ];

        try {
            // Prepare all updates first
            for (const mapping of mappings) {
                const sourceProtocol = getProtocolByParam(mapping.sourceParam);
                const resultKey = paramToResultKeyMap[mapping.sourceParam];
                const sourceValue = parseInt(localResults[resultKey]);
                
                if (!sourceProtocol || isNaN(sourceValue)) {
                    console.warn(`Missing protocol or invalid value for ${mapping.sourceParam}:`, sourceValue);
                    continue;
                }

                for (const targetParam of mapping.targetParams) {
                    const targetProtocol = getProtocolByParam(targetParam);
                    if (!targetProtocol) continue;

                    const value32Bit = sourceValue & 0xFFFFFFFF;
                    
                    const paramToUpdate = {
                        index: targetProtocol.Index,
                        sub_index: targetProtocol.Subindex,
                        newValue: value32Bit,
                        size: targetProtocol.Type.includes("uint8") ? 1 : 
                              targetProtocol.Type.includes("uint16") ? 2 : 
                              targetProtocol.Type.includes("uint32") ? 4 : 2,  // Default to 2 bytes if not specified
                        inputTitle: targetParam,
                        categoryKey: "motor",
                        subCategoryKey: "configuration",
                        variableKey: targetParam,
                        resultKey: resultKey
                    };

                    console.log('Preparing parameter:', {
                        param: targetParam,
                        sourceValue,
                        value32Bit: `0x${value32Bit.toString(16).padStart(8, '0')}`
                    });

                    allUpdates.push(paramToUpdate);
                }
            }
            console.log('send updateControllerSettings, localResults', localResults);

            // Send all updates at once
            await updateControllerSettings(
                pin,
                allUpdates,
                primaryService
            );

            // Verify all updates
            for (const update of allUpdates) {
                await new Promise(resolve => setTimeout(resolve, 500));
                try {
                    const verifiedValue = await readPassthroughValue(
                        pin,
                        0x00000601,
                        parseInt(update.index, 16),
                        parseInt(update.sub_index, 16),
                        primaryService
                    );
                    console.log('saveResults[update.variableKey]', verifiedValue, update.newValue)
                    saveResults[update.variableKey] = verifiedValue === update.newValue ? 'success' : 'error';
                    if (verifiedValue !== update.newValue) {
                        hasErrors = true;
                    }
                } catch (error) {
                    console.error(`Error verifying ${update.variableKey}:`, error);
                    saveResults[update.variableKey] = 'error';
                    hasErrors = true;
                }
            }
        } catch (error) {
            console.error('Error during save:', error);
            hasErrors = true;
        } finally {
            setSaveStatus(saveResults);
            setIsSaving(false);

            if (hasErrors) {
                setAutotuneError(t('Some values failed to save. Please try again or contact support.'));
            }
        }
    };

    const renderConfigurationPage = () => (
        <>
            <Modal.Body>
                {isLoading ? (
                    <div className="text-center py-4">
                        <Spinner animation="border" />
                        <p className="mt-2">{t('Loading configuration...')}</p>
                    </div>
                ) : (
                    <>
                        <Alert variant="warning">
                            {t('Warning: Once the autotune process starts, it cannot be cancelled. You will need to wait until the process is complete.')}
                        </Alert>
                        {loadError && (
                            <Alert variant="danger" className="mb-3">
                                {loadError}
                            </Alert>
                        )}
                        {autotuneError && (
                            <Alert variant="danger" className="mb-3">
                                {autotuneError}
                            </Alert>
                        )}
                        <Form>
                            <Form.Group controlId="formPolePairs" className="mb-3">
                                <Form.Label>{t('Number of Pole Pairs')}</Form.Label>
                                <Form.Select
                                    value={polePairs}
                                    onChange={(e) => setPolePairs(e.target.value)}
                                >
                                    {[...Array(10)].map((_, i) => (
                                        <option key={i + 1} value={i + 1}>{i + 1}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group controlId="formRatedCurrent">
                                <Form.Label>{t('Rated Current (AC)')}</Form.Label>
                                <Form.Select
                                    value={ratedCurrent}
                                    onChange={(e) => setRatedCurrent(e.target.value)}
                                >
                                    {[5, 10, 15, 20, 25].map((current) => (
                                        <option key={current} value={current}>{current}A</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group controlId="formGearRatio" className="mt-3">
                                <Form.Label>{t('Motor Gear Ratio (x10)')}</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={gearRatio}
                                    onChange={(e) => {
                                        const value = parseInt(e.target.value);
                                        if (!isNaN(value) && value >= 0 && value <= 1000) {
                                            setGearRatio(e.target.value);
                                        }
                                    }}
                                    min={0}
                                    max={1000}
                                    step={1}
                                />
                                <Form.Text className="text-muted">
                                    {t('Enter gear ratio multiplied by 10. For example, for a ratio of 12:1, enter 120')}
                                </Form.Text>
                            </Form.Group>
                        </Form>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('Cancel')}
                </Button>
                <Button variant="primary" onClick={handleStartAutotune} disabled={isLoading}>
                    {t('Start Autotune')}
                </Button>
            </Modal.Footer>
        </>
    );

    const renderProgressPage = () => (
        <>
            <Modal.Body>
                <div className="mb-4">
                    {autotuneError ? (
                        <Alert variant="danger">
                            {autotuneError}
                            <div className="mt-2">
                                {t('The autotune process has been stopped. You can close this window and try again.')}
                            </div>
                        </Alert>
                    ) : (
                        <Alert variant="info">
                            {t('Autotune in progress... Please wait.')}
                        </Alert>
                    )}
                </div>
                <div className="mt-3">
                    <ProgressBar 
                        now={displayProgress} 
                        label={`${displayProgress}%`}
                        variant={autotuneError ? 'danger' : 'primary'}
                    />
                </div>
            </Modal.Body>
            {autotuneError && (
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            )}
        </>
    );

    const renderResultsPage = () => {
        if (!localResults || typeof localResults !== 'object' || localResults.error) return null;

        const getStatusIcon = (parameter) => {
            if (isSaving) return <Spinner animation="border" size="sm" />;
            if (!saveStatus[parameter]) return null;
            return saveStatus[parameter] === 'success' ? 
                <span className="text-success"> ✓ </span> : 
                <span className="text-danger"> ✗ </span>;
        };

        const allSaved = Object.keys(saveStatus).length > 0;
        const allSuccessful = Object.values(saveStatus).every(status => status === 'success');

        return (
            <>
                <Modal.Body>
                    <h5>{t('Autotune Results:')}</h5>
                    <Table striped bordered hover size="sm">
                        <tbody>
                            {Object.entries(localResults)
                                .filter(([key]) => typeof localResults[key] !== 'object')
                                .map(([key, value]) => (
                                    <tr key={key}>
                                        <td><strong>{t(key)}</strong></td>
                                        <td>
                                            {value}
                                            {getStatusIcon(key)}
                                        </td>
                                        <td>
                                            {localResults[key].saved 
                                                ? t('Saved')
                                                : t('Not Saved')}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                    {allSaved && !isSaving && (
                        <Alert variant={allSuccessful ? 'success' : 'danger'}>
                            {allSuccessful 
                                ? t('All values have been successfully saved to the vehicle')
                                : autotuneError}
                        </Alert>
                    )}
                    {isSaving && (
                        <Alert variant="info">
                            {t('Saving values to vehicle...')}
                        </Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="secondary" 
                        onClick={handleClose}
                        disabled={isSaving}
                    >
                        {allSaved ? t('Close') : t('Discard Values')}
                    </Button>
                    {!isSaving && (
                        <Button 
                            variant="primary" 
                            onClick={handleSaveSettings}
                            disabled={isSaving}
                        >
                            {isSaving ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-2"
                                    />
                                    {t('Saving...')}
                                </>
                            ) : t('Save Values to Vehicle')}
                        </Button>
                    )}
                </Modal.Footer>
            </>
        );
    };

    return (
        <Modal 
            show={show} 
            onHide={currentPage === 1 ? handleClose : undefined}
            backdrop={currentPage !== 1 ? 'static' : true}
            keyboard={currentPage === 1}
        >
            <Modal.Header closeButton={currentPage === 1}>
                <Modal.Title>
                    {currentPage === 1 && t('Autotune Configuration')}
                    {currentPage === 2 && t('Autotune Progress')}
                    {currentPage === 3 && t('Autotune Results')}
                </Modal.Title>
            </Modal.Header>
            
            {currentPage === 1 && renderConfigurationPage()}
            {currentPage === 2 && renderProgressPage()}
            {currentPage === 3 && renderResultsPage()}
        </Modal>
    );
};

export default AutotuneModal;