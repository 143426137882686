import React from "react";

const VersionInfoComponent = ({ versionInfo }) => {
  if (!versionInfo) return null;
  
  return (
    <div className="version-info">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Device Information</h5>
              <div className="row">
                <div className="col-md-3">
                  <p className="mb-1 text-muted">Serial Number</p>
                  <p className="font-weight-bold">{versionInfo.serialNumber}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VersionInfoComponent;
