import { useState, useCallback, useMemo } from 'react';

const useSerialNumber = () => {
    const [msb, setMsb] = useState(null);
    const [lsb, setLsb] = useState(null);

    // Helper to process ASCII bytes into actual characters and reverse them
    const processAsciiBytes = useCallback((bytes) => {
        if (!bytes || !bytes.length) return null;
        
        // Get the last 4 bytes (positions 5-8, which are indices 5,6,7,8)
        const lastFourBytes = bytes.slice(5, 9);
        
        // Convert ASCII byte values to actual characters and reverse the order
        return lastFourBytes
            .map(byte => String.fromCharCode(byte))
            .reverse()
            .join('');
    }, []);

    // Compute serial number when either value changes
    const serialNumber = useMemo(() => {
        if (!msb || !lsb) return null;

        // Get the character strings from each part
        const msbPart = processAsciiBytes(msb);
        const lsbPart = processAsciiBytes(lsb);
        
        if (!msbPart || !lsbPart) return null;
        
        // Combine the parts (MSB part followed by LSB part)
        return msbPart + lsbPart;
    }, [msb, lsb, processAsciiBytes]);

    // Reset function
    const reset = useCallback(() => {
        setMsb(null);
        setLsb(null);
    }, []);

    // Update functions for raw byte arrays
    const updateMsb = useCallback((rawBytes) => {
        setMsb(rawBytes);
    }, []);

    const updateLsb = useCallback((rawBytes) => {
        setLsb(rawBytes);
    }, []);

    return {
        serialNumber,
        updateMsb,
        updateLsb,
        reset,
        msb,
        lsb
    };
};

export default useSerialNumber;