import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import publicProtocol from '../../common/publicCanProtocol.json';
import internalProtocol from '../../common/internalCanProtocol.json';
import bmsProtocol from '../../common/bmsCanProtocol.json';

const ProtocolsComponent = () => {
  const { t } = useTranslation();
  const [selectedProtocol, setSelectedProtocol] = useState('public');
  const [activeSection, setActiveSection] = useState('');
  
  const protocols = {
    public: publicProtocol,
    internal: internalProtocol,
    bms: bmsProtocol
  };

  const getProtocolSections = (protocol) => {
    if (!protocol) return [];
    return Object.keys(protocol).filter(key => key !== 'protocol');
  };

  const renderTableOfContents = (protocol) => {
    if (!protocol) return null;
    const sections = getProtocolSections(protocol);

    return (
      <div className="list-group mb-4">
        {sections.map((section) => (
          <a
            key={section}
            href={`#${section.replace(/\s+/g, '-')}`}
            className={`list-group-item list-group-item-action border-0 rounded my-1 ${
              activeSection === section ? 'active bg-orange' : ''
            }`}
            onClick={() => setActiveSection(section)}
          >
            {section}
          </a>
        ))}
      </div>
    );
  };

  const renderAccessBadge = (access) => {
    const getAccessColor = (access) => {
      if (access === 'R/W') return 'bg-orange';
      if (access === 'R') return 'bg-secondary';
      return 'bg-dark';
    };

    return (
      <div className="mb-3">
        <span className={`badge ${getAccessColor(access)} fs-6`}>
          {access === 'R/W' ? 'Read/Write' : access === 'R' ? 'Read Only' : access}
        </span>
      </div>
    );
  };

  const renderParameterProperties = (parameter) => {
    const properties = [];
    
    if (parameter.Persistence) {
      properties.push(['Persistence', parameter.Persistence]);
    }
    if (parameter.Unit) {
      properties.push(['Unit', parameter.Unit]);
    }
    if (parameter.Type) {
      properties.push(['Type', parameter.Type]);
    }

    return (
      <div className="mt-3">
        <h6 className="mb-2 text-secondary">Properties:</h6>
        <ul className="list-unstyled bg-light p-3 rounded">
          {properties.map(([key, value], idx) => (
            <li key={idx} className="mb-2">
              <strong className="text-dark">{key}:</strong> {value}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderParameterDetails = (paramKey, parameter, sectionIndex) => {
    return (
      <div className="card mb-4 border-0 shadow-sm">
        <div className="card-body">
          <div className="mb-3">
            <h5 className="mb-2">{paramKey}</h5>
            <div className="d-flex gap-3 text-secondary">
              <span>Index: {sectionIndex}</span>
              <span>Subindex: {parameter.Subindex}</span>
            </div>
          </div>

          {parameter.Access && renderAccessBadge(parameter.Access)}
          
          {parameter.Description && (
            <p className="mb-3">{parameter.Description}</p>
          )}

          {parameter.Notes && (
            <div className="alert alert-secondary mb-3">
              {parameter.Notes}
            </div>
          )}

          {parameter.Valid_Range && (
            <div className="mb-3">
              <h6 className="mb-2 text-secondary">Valid Range:</h6>
              <div className="bg-light p-3 rounded">
                {parameter.Valid_Range.min} - {parameter.Valid_Range.max}
                {parameter.Unit && ` ${parameter.Unit}`}
              </div>
            </div>
          )}
          
          {parameter.Valid_Options && (
            <div className="mb-3">
              <h6 className="mb-2 text-secondary">Valid Options:</h6>
              <ul className="list-unstyled bg-light p-3 rounded">
                {parameter.Valid_Options.map((option, idx) => (
                  <li key={idx} className="mb-2">
                    <strong>{option.value}</strong> - {option.description}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {renderParameterProperties(parameter)}
        </div>
      </div>
    );
  };

  const renderProtocolContent = (protocol) => {
    if (!protocol) return null;
    const sections = getProtocolSections(protocol);

    return sections.map((section) => {
      const sectionData = protocol[section];
      return (
        <div key={section} id={section.replace(/\s+/g, '-')} className="mb-5">
          <h3 className="border-bottom pb-2 mb-4">{section}</h3>
          {sectionData.Description && (
            <p className="lead mb-4">{sectionData.Description}</p>
          )}
          {sectionData.Notes && (
            <div className="alert alert-secondary border-0">{sectionData.Notes}</div>
          )}
          {Object.entries(sectionData)
            .filter(([key]) => key !== 'Description' && key !== 'Notes')
            .map(([key, value]) => (
              <div key={key} className="mb-4">
                <h4 className="mb-3">{key}</h4>
                {value.Description && <p className="mb-3">{value.Description}</p>}
                {value.Parameters && Object.entries(value.Parameters).map(([paramKey, paramValue]) => (
                  renderParameterDetails(paramKey, paramValue, value.CANOpen_Index)
                ))}
              </div>
            ))}
        </div>
      );
    });
  };

  const currentProtocol = protocols[selectedProtocol];

  return (
    <div className="container-fluid" id="top">
      <div className="row mb-4">
        <div className="col-12">
          <h2 className="mb-4">{t('CAN Bus Protocols')}</h2>
          <select 
            className="form-select shadow-sm"
            value={selectedProtocol}
            onChange={(e) => setSelectedProtocol(e.target.value)}
          >
            <option value="public">Public CAN Protocol</option>
            <option value="internal">Internal CAN Protocol</option>
            <option value="bms">BMS CAN Protocol</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3">
          <div className="sticky-top pt-3">
            {renderTableOfContents(currentProtocol)}
            <button 
              onClick={() => document.getElementById('top').scrollIntoView({ behavior: 'smooth' })}
              className="btn btn-secondary w-100 mt-3"
            >
              {t('Back to Top')}
            </button>
          </div>
        </div>
        <div className="col-md-9">
          {currentProtocol?.protocol && (
            <div className="mb-5 p-4 bg-light rounded">
              <h3 className="mb-3">{currentProtocol.protocol.title}</h3>
              <p className="mb-3">{currentProtocol.protocol.description}</p>
              <p className="mb-0">
                <strong>Version: </strong>
                <span className="badge bg-orange ms-2">{currentProtocol.protocol.version}</span>
              </p>
            </div>
          )}
          {renderProtocolContent(currentProtocol)}
        </div>
      </div>

      <style>{`
        .bg-orange {
          background-color: #ff6b00;
          color: white;
        }

        .list-group-item {
          color: #333;
          transition: all 0.2s ease;
        }

        .list-group-item:hover:not(.active) {
          background: #f8f9fa;
          color: #ff6b00;
        }

        .parameter-card {
          transition: all 0.2s ease;
        }

        .badge {
          font-weight: normal;
          padding: 0.5rem 1rem;
        }
      `}</style>
    </div>
  );
};

export default ProtocolsComponent;