// DFU States and Status codes
export const DFUState = {
  IDLE: 'IDLE',
  PREPARING: 'PREPARING',
  TRANSFERRING: 'TRANSFERRING',
  VALIDATING: 'VALIDATING',
  FORWARDING: 'FORWARDING',
  COMPLETING: 'COMPLETING',
  ERROR: 'ERROR'
};

export const DFUStatus = {
  NORMAL: 0x00,
  READY_FOR_TRANSFER: 0x01,
  TRANSFER_COMPLETE: 0x02,
  FORWARDING: 0x03,
  FORWARD_COMPLETE: 0x04,
  APPLYING_DFU: 0x05,
  APPLYING_ROLLBACK: 0x06,
  ERASING_FILE: 0x07,
  FILE_ERASED: 0x08,
  TRANSFER_ABORTED: 0x09,
  INIT_FAILED: 0xF0,
  IMAGE_INVALID: 0xFA,
  BAD_VERSION: 0xFB,
  FORWARD_ERROR: 0xFC,
  INTERNAL_ERROR: 0xFD,
  CORRUPTED_FRAME: 0xFE,
  MISSING_FRAME: 0xFF
};

export class DFUManager {
  constructor() {
    // Constants
    this.PAYLOAD_SIZE = 134;
    this.PREAMBLE = 0xAA;
    this.FRAME_CONTROL = {
      FIRST: 0x00,
      MIDDLE: 0x01,
      LAST: 0x02
    };

    // State management
    this.state = DFUState.IDLE;
    this.frameCounter = 0;
    this.totalFrames = 0;
    this.forwardCount = 0;
    this.updateProgress = 0;
    this.isCleaningUp = false;
    this.boundStatusHandler = null;
    this.boundValidationHandler = null;

    // Callbacks
    this.onProgressCallback = null;
    this.onErrorCallback = null;
    this.onSuccessCallback = null;
    this.onLogCallback = null;

    // Data
    this.updateFile = null;
    this.frames = [];

    // BLE characteristics
    this.characteristics = {
      otaCommand: null,
      otaStatus: null,
      dataValidation: null,
      dataTransfer: null
    };

    // Add disconnect handling
    this.updateComplete = false;
    this.disconnectTimeout = null;

    //debug
    this.debugLogs = true;
  }

  log(...args) {
    if (this.debugLogs) {
        console.log(...args);
    }
    if (this.onLogCallback) {
        this.onLogCallback(...args);
    }
  }

  async startUpdate(firmwareService, primaryService, pin, updateFile, progressCallback, successCallback, errorCallback, logCallback) {
    if (this.state !== DFUState.IDLE) {
      throw new Error('DFU update already in progress');
    }

    try {
      this.log('Starting update process');
      this.log('Update file size:', updateFile.length, 'bytes');
      
      this.updateFile = updateFile;
      this.onProgressCallback = (progress) => {
        const validProgress = Number.isFinite(progress) ? Math.min(100, Math.max(0, progress)) : 0;
        this.log('Progress:', validProgress + '%');
        progressCallback(validProgress);
      };
      this.onSuccessCallback = () => {
        this.log('Update completed successfully');
        successCallback();
      };
      this.onErrorCallback = (error) => {
        this.log('Error occurred:', error);
        errorCallback(error);
      };
      this.onLogCallback = logCallback;

      // Initialize characteristics
      this.log('Initializing characteristics...');
      await this.initializeCharacteristics(firmwareService);
      
      // Write PIN if provided
      if (pin) {
        this.log('Writing PIN...');
        await this.writePin(primaryService, pin);
      }

      // Prepare frames
      this.log('Preparing frames...');
      this.prepareFrames();
      this.log('Total frames:', this.totalFrames);

      // Setup notifications
      this.log('Setting up notifications...');
      this.boundStatusHandler = this.handleStatusNotification.bind(this);
      this.boundValidationHandler = this.handleDataValidation.bind(this);
      await this.setupNotifications();

      // Start update process
      this.state = DFUState.PREPARING;
      this.log('Sending start command...');
      await this.characteristics.otaCommand.writeValue(new Uint8Array([0x01]));

    } catch (error) {
      this.handleError('Failed to start update: ' + error.message);
      await this.cleanup();
    }
  }

  async initializeCharacteristics(firmwareService) {
    try {
      this.characteristics.otaCommand = await firmwareService.getCharacteristic("e8012ff1-6630-4a43-80e6-5b3986e2f2d7");
      this.characteristics.otaStatus = await firmwareService.getCharacteristic("e8012ff2-6630-4a43-80e6-5b3986e2f2d7");
      this.characteristics.dataValidation = await firmwareService.getCharacteristic("e8012ff3-6630-4a43-80e6-5b3986e2f2d7");
      this.characteristics.dataTransfer = await firmwareService.getCharacteristic("e8012ff4-6630-4a43-80e6-5b3986e2f2d7");
    } catch (error) {
      throw new Error('Failed to initialize characteristics: ' + error.message);
    }
  }

  async writePin(primaryService, pin) {
    try {
      const passthroughCharacteristic = await primaryService.getCharacteristic(
        'e8012f15-6630-4a43-80e6-5b3986e2f2d7'
      );
      const pinArray = new Uint8Array([...pin.split('').map(char => char.charCodeAt(0)), 48]);
      await passthroughCharacteristic.writeValue(pinArray);
    } catch (error) {
      throw new Error('Failed to write PIN: ' + error.message);
    }
  }

  prepareFrames() {
    const fileBytes = new Uint8Array(this.updateFile);
    let offset = 0;
    this.frames = [];

    while (offset < fileBytes.length) {
      const chunk = fileBytes.slice(offset, offset + this.PAYLOAD_SIZE);
      const isFirst = offset === 0;
      const isLast = (offset + this.PAYLOAD_SIZE) >= fileBytes.length;
      
      const frame = this.buildFrame(chunk, this.frames.length, isFirst, isLast);
      this.frames.push(frame);
      
      offset += this.PAYLOAD_SIZE;
    }

    this.totalFrames = this.frames.length;
  }

  buildFrame(chunk, frameCount, isFirst, isLast) {
    const frameSize = 5 + chunk.length + 1; // header(5) + payload + crc(1)
    const frame = new Uint8Array(frameSize);
    
    // Header
    frame[0] = this.PREAMBLE;
    frame[1] = frameCount & 0xFF;
    frame[2] = (frameCount >> 8) & 0xFF;
    frame[3] = isFirst ? this.FRAME_CONTROL.FIRST : 
               isLast ? this.FRAME_CONTROL.LAST : 
               this.FRAME_CONTROL.MIDDLE;
    frame[4] = chunk.length;
    
    // Payload
    frame.set(chunk, 5);
    
    // CRC
    const crcIndex = frameSize - 1;
    frame[crcIndex] = this.calculateCRC8(frame.slice(0, crcIndex));
    
    return frame;
  }

  async setupNotifications() {
    // Status notifications
    await this.characteristics.otaStatus.startNotifications();
    this.characteristics.otaStatus.addEventListener(
      'characteristicvaluechanged',
      this.boundStatusHandler
    );

    // Data validation notifications
    await this.characteristics.dataValidation.startNotifications();
    this.characteristics.dataValidation.addEventListener(
      'characteristicvaluechanged',
      this.boundValidationHandler
    );
  }

  async handleStatusNotification(event) {
    if (this.isCleaningUp) return;
    
    const statusValue = event.target.value;
    const status = statusValue.getUint8(0);
    
    // Log detailed status information
    this.log('=== Status Notification Details ===');
    this.log('Status Code:', status);
    this.log('Status Hex:', '0x' + status.toString(16).padStart(2, '0'));
    this.log('Current State:', this.state);
    this.log('Frame Counter:', this.frameCounter);
    this.log('Total Frames:', this.totalFrames);
    
    // Log all bytes in the status value
    let allBytes = [];
    for (let i = 0; i < statusValue.byteLength; i++) {
        allBytes.push('0x' + statusValue.getUint8(i).toString(16).padStart(2, '0'));
    }
    this.log('All Status Bytes:', allBytes.join(', '));
    
    // Map status code to readable name
    const statusName = Object.entries(DFUStatus).find(([key, value]) => value === status)?.[0] || 'UNKNOWN';
    this.log('Status Name:', statusName);
    this.log('================================');
    
    try {
      switch (status) {
        case DFUStatus.READY_FOR_TRANSFER:
          this.log('Device ready for transfer');
          if (this.state === DFUState.PREPARING) {
            this.state = DFUState.TRANSFERRING;
            await this.sendNextFrame();
          }
          break;

        case DFUStatus.MISSING_FRAME:
          this.log('Missing frame detected, failing update');
          throw new Error('Missing frame detected - update failed');

        case DFUStatus.TRANSFER_COMPLETE:
          this.log('Transfer complete, waiting for forwarding to start...');
          this.state = DFUState.FORWARDING;
          this.updateProgress = 66;
          break;

        case DFUStatus.FORWARDING:
          if (this.state !== DFUState.FORWARDING) {
            this.log('Entering forwarding state');
            this.state = DFUState.FORWARDING;
          }
          this.forwardCount++;
          this.log('Forwarding in progress, count:', this.forwardCount);
          this.updateProgress = Math.min(98, 66 + (this.forwardCount * 8));
          break;

        case DFUStatus.FORWARD_COMPLETE:
          this.log('Forwarding complete, waiting for final application');
          this.state = DFUState.COMPLETING;
          this.updateProgress = 99;
          break;

        case DFUStatus.APPLYING_DFU:
          this.log('Applying update - final stage');
          this.updateProgress = 100;
          this.updateComplete = true;
          await this.waitForCompletion();
          this.disconnectTimeout = setTimeout(() => {
            if (this.updateComplete) {
              this.onSuccessCallback?.();
              this.cleanup();
            }
          }, 2000);
          break;

        case DFUStatus.FORWARD_ERROR:
        case DFUStatus.INIT_FAILED:
        case DFUStatus.IMAGE_INVALID:
        case DFUStatus.BAD_VERSION:
        case DFUStatus.INTERNAL_ERROR:
          const errorMsg = this.getErrorMessage(status);
          this.log('Critical error status received:', errorMsg);
          throw new Error(errorMsg);

        case DFUStatus.NORMAL:
          this.log('Device in normal state');
          break;

        default:
          this.log('Unknown status received:', status);
      }

      this.onProgressCallback?.(this.updateProgress);
    } catch (error) {
      if (error.message.includes('disconnected') && this.updateComplete) {
        this.log('Expected disconnect after successful update');
        this.onSuccessCallback?.();
      } else {
        this.log('Error in status handling:', error);
        await this.cleanup();
        this.handleError(error.message);
      }
    }
  }

  async waitForCompletion() {
    this.log('Waiting for update completion...');
    await new Promise(resolve => setTimeout(resolve, 2000));
  }

  async handleDataValidation(event) {
    if (this.isCleaningUp) return;
    
    const validationValue = event.target.value;
    
    // Log detailed validation information
    this.log('=== Data Validation Details ===');
    this.log('Current State:', this.state);
    this.log('Frame Counter:', this.frameCounter);
    this.log('Total Frames:', this.totalFrames);
    
    // Log all validation bytes
    let allBytes = [];
    for (let i = 0; i < validationValue.byteLength; i++) {
        allBytes.push('0x' + validationValue.getUint8(i).toString(16).padStart(2, '0'));
    }
    this.log('Validation Bytes:', allBytes.join(', '));
    this.log('============================');
    
    if (this.state !== DFUState.TRANSFERRING) {
      this.log('Data validation received in incorrect state:', this.state);
      return;
    }

    try {
      this.log('Frame validated:', this.frameCounter + 1, 'of', this.totalFrames);
      this.frameCounter++;
      
      if (this.frameCounter < this.totalFrames) {
        await this.sendNextFrame();
      } else {
        this.log('All frames sent successfully');
      }
    } catch (error) {
      this.log('Error handling data validation:', error);
      await this.cleanup();
      this.handleError(error.message);
    }
  }

  async sendNextFrame() {
    if (this.frameCounter >= this.totalFrames) {
      this.log('=== Frame Send Status ===');
      this.log('No more frames to send');
      this.log('Frame Counter:', this.frameCounter);
      this.log('Total Frames:', this.totalFrames);
      this.log('=======================');
      return;
    }
    
    this.log('=== Sending Next Frame ===');
    this.log('Frame Number:', this.frameCounter + 1);
    this.log('Total Frames:', this.totalFrames);
    this.log('Current State:', this.state);

    try {
      const frame = this.frames[this.frameCounter];
      this.log(`Sending frame: ${this.frameCounter + 1} of ${this.totalFrames}`);
      await this.characteristics.dataTransfer.writeValue(frame);
      
      const rawProgress = (this.frameCounter / this.totalFrames) * 66;
      this.updateProgress = Math.min(66, Math.max(0, rawProgress));
      this.onProgressCallback?.(this.updateProgress);

    } catch (error) {
      this.log('Error sending frame:', error);
      throw new Error('Failed to send frame - update failed');
    }
  }

  handleError(message) {
    this.log('Error occurred:', message);
    if (this.state === DFUState.FORWARDING) {
      message = 'Error during firmware forwarding - please try again. If the problem persists, ensure the device has enough memory and is not in use.';
    }
    this.state = DFUState.ERROR;
    this.onErrorCallback?.(message);
  }

  getErrorMessage(status) {
    const errorMessages = {
      [DFUStatus.INIT_FAILED]: 'DFU initialization failed',
      [DFUStatus.IMAGE_INVALID]: 'Invalid firmware image',
      [DFUStatus.BAD_VERSION]: 'Incompatible firmware version',
      [DFUStatus.FORWARD_ERROR]: 'Error during firmware forwarding - please retry',
      [DFUStatus.INTERNAL_ERROR]: 'Internal DFU error',
      [DFUStatus.CORRUPTED_FRAME]: 'Corrupted frame detected',
      [DFUStatus.MISSING_FRAME]: 'Missing frame detected',
      [DFUStatus.FORWARD_COMPLETE]: 'Forwarding completed successfully'
    };
    return errorMessages[status] || `Unknown error (status: ${status})`;
  }

  calculateCRC8(buf) {
    const tableCRC8 = [
      0x00, 0x07, 0x0E, 0x09, 0x1C, 0x1B, 0x12, 0x15, 0x38, 0x3F, 0x36, 0x31, 0x24, 0x23, 0x2A, 0x2D,
      0x70, 0x77, 0x7E, 0x79, 0x6C, 0x6B, 0x62, 0x65, 0x48, 0x4F, 0x46, 0x41, 0x54, 0x53, 0x5A, 0x5D,
      0xE0, 0xE7, 0xEE, 0xE9, 0xFC, 0xFB, 0xF2, 0xF5, 0xD8, 0xDF, 0xD6, 0xD1, 0xC4, 0xC3, 0xCA, 0xCD,
      0x90, 0x97, 0x9E, 0x99, 0x8C, 0x8B, 0x82, 0x85, 0xA8, 0xAF, 0xA6, 0xA1, 0xB4, 0xB3, 0xBA, 0xBD,
      0xC7, 0xC0, 0xC9, 0xCE, 0xDB, 0xDC, 0xD5, 0xD2, 0xFF, 0xF8, 0xF1, 0xF6, 0xE3, 0xE4, 0xED, 0xEA,
      0xB7, 0xB0, 0xB9, 0xBE, 0xAB, 0xAC, 0xA5, 0xA2, 0x8F, 0x88, 0x81, 0x86, 0x93, 0x94, 0x9D, 0x9A,
      0x27, 0x20, 0x29, 0x2E, 0x3B, 0x3C, 0x35, 0x32, 0x1F, 0x18, 0x11, 0x16, 0x03, 0x04, 0x0D, 0x0A,
      0x57, 0x50, 0x59, 0x5E, 0x4B, 0x4C, 0x45, 0x42, 0x6F, 0x68, 0x61, 0x66, 0x73, 0x74, 0x7D, 0x7A,
      0x89, 0x8E, 0x87, 0x80, 0x95, 0x92, 0x9B, 0x9C, 0xB1, 0xB6, 0xBF, 0xB8, 0xAD, 0xAA, 0xA3, 0xA4,
      0xF9, 0xFE, 0xF7, 0xF0, 0xE5, 0xE2, 0xEB, 0xEC, 0xC1, 0xC6, 0xCF, 0xC8, 0xDD, 0xDA, 0xD3, 0xD4,
      0x69, 0x6E, 0x67, 0x60, 0x75, 0x72, 0x7B, 0x7C, 0x51, 0x56, 0x5F, 0x58, 0x4D, 0x4A, 0x43, 0x44,
      0x19, 0x1E, 0x17, 0x10, 0x05, 0x02, 0x0B, 0x0C, 0x21, 0x26, 0x2F, 0x28, 0x3D, 0x3A, 0x33, 0x34,
      0x4E, 0x49, 0x40, 0x47, 0x52, 0x55, 0x5C, 0x5B, 0x76, 0x71, 0x78, 0x7F, 0x6A, 0x6D, 0x64, 0x63,
      0x3E, 0x39, 0x30, 0x37, 0x22, 0x25, 0x2C, 0x2B, 0x06, 0x01, 0x08, 0x0F, 0x1A, 0x1D, 0x14, 0x13,
      0xAE, 0xA9, 0xA0, 0xA7, 0xB2, 0xB5, 0xBC, 0xBB, 0x96, 0x91, 0x98, 0x9F, 0x8A, 0x8D, 0x84, 0x83,
      0xDE, 0xD9, 0xD0, 0xD7, 0xC2, 0xC5, 0xCC, 0xCB, 0xE6, 0xE1, 0xE8, 0xEF, 0xFA, 0xFD, 0xF4, 0xF3
    ];

    let crc = 0;
    for (const byte of buf) {
      const x = crc ^ byte;
      crc = tableCRC8[x];
    }
    return crc;
  }

  async cleanup() {
    if (this.isCleaningUp) {
      this.log('Cleanup already in progress');
      return;
    }

    this.isCleaningUp = true;
    this.log('Starting cleanup...');

    if (this.disconnectTimeout) {
      clearTimeout(this.disconnectTimeout);
      this.disconnectTimeout = null;
    }

    try {
      if (this.characteristics.otaStatus && this.boundStatusHandler) {
        this.log('Cleaning up OTA status notifications...');
        await this.characteristics.otaStatus.stopNotifications();
        this.characteristics.otaStatus.removeEventListener(
          'characteristicvaluechanged',
          this.boundStatusHandler
        );
      }

      if (this.characteristics.dataValidation && this.boundValidationHandler) {
        this.log('Cleaning up data validation notifications...');
        await this.characteristics.dataValidation.stopNotifications();
        this.characteristics.dataValidation.removeEventListener(
          'characteristicvaluechanged',
          this.boundValidationHandler
        );
      }
      
      // Reset state
      this.state = DFUState.IDLE;
      this.frameCounter = 0;
      this.totalFrames = 0;
      this.forwardCount = 0;
      this.updateProgress = 0;
      this.boundStatusHandler = null;
      this.boundValidationHandler = null;

      // Clear characteristics
      this.characteristics = {
        otaCommand: null,
        otaStatus: null,
        dataValidation: null,
        dataTransfer: null
      };
      
      this.log('Cleanup completed');
    } catch (error) {
      this.log('Error during cleanup:', error);
    } finally {
      this.isCleaningUp = false;
      this.updateComplete = false;
    }
  }
}