import React, { useState, useRef } from "react";
import { Button, ProgressBar, Table, Card } from "react-bootstrap";
import { useCAN } from "../../hooks/useCAN.hook";
import readWriteTests from './testCases/read-write.tests.json';

const ReadWriteTestsComponent = ({ 
    pin, 
    primaryService, 
    startNotificationListener, 
    stopNotificationListener, 
    getSendPassthroughRW,
    updateControllerSettings,
    readPassthroughValue 
}) => {
    const { getAllProtocols } = useCAN();
    const [isTestRunning, setIsTestRunning] = useState(false);
    const [progress, setProgress] = useState(0);
    const [readResults, setReadResults] = useState({});
    const [writeResults, setWriteResults] = useState({});
    const [verifyResults, setVerifyResults] = useState({});
    const [secondWriteResults, setSecondWriteResults] = useState({});
    const [secondVerifyResults, setSecondVerifyResults] = useState({});
    const [currentParam, setCurrentParam] = useState("");

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    
    // Get size based on parameter type
    const getParamSize = (type) => {
        switch(type) {
            case 'uint8_t':
                return 1;
            case 'uint16_t':
                return 2;
            case 'uint32_t':
                return 4;
            default:
                return 4;
        }
    };

    const processParameter = async (paramName, testValues, paramInfo) => {
        try {
            setCurrentParam(paramName);
            console.log(`Processing parameter: ${paramName}`);

            // Read initial value
            console.log(`Reading initial value for ${paramName}`);
            const initialValue = await readPassthroughValue(
                pin, 
                0x00000601, 
                parseInt(paramInfo.index, 16), 
                parseInt(paramInfo.sub_index, 16), 
                primaryService
            );
            
            console.log(`Initial value read: ${initialValue}`);
            setReadResults(prev => ({
                ...prev,
                [paramName]: initialValue
            }));

            // First write
            console.log(`Writing new value for ${paramName}: ${testValues[0]}`);
            await updateControllerSettings(
                pin,
                [{
                    index: paramInfo.index,
                    sub_index: paramInfo.sub_index,
                    newValue: testValues[0],
                    size: getParamSize(paramInfo.type)
                }],
                primaryService
            );

            setWriteResults(prev => ({
                ...prev,
                [paramName]: `Done (${testValues[0]})`
            }));

            // Wait for controller to stabilize after first write
            console.log("Waiting for controller to stabilize...");
            await sleep(500);

            // Verify first written value
            console.log(`Verifying value for ${paramName}`);
            const verifiedValue = await readPassthroughValue(
                pin, 
                0x00000601, 
                parseInt(paramInfo.index, 16), 
                parseInt(paramInfo.sub_index, 16), 
                primaryService
            );

            console.log(`Verified value: ${verifiedValue} (Expected: ${testValues[0]})`);
            setVerifyResults(prev => ({
                ...prev,
                [paramName]: verifiedValue === testValues[0] ? "Success" : "Failed"
            }));

            // Wait before second write
            await sleep(500);

            // Second write
            console.log(`Writing second value for ${paramName}: ${testValues[1]}`);
            await updateControllerSettings(
                pin,
                [{
                    index: paramInfo.index,
                    sub_index: paramInfo.sub_index,
                    newValue: testValues[1],
                    size: getParamSize(paramInfo.type)
                }],
                primaryService
            );

            setSecondWriteResults(prev => ({
                ...prev,
                [paramName]: `Done (${testValues[1]})`
            }));

            // Wait for controller to stabilize after second write
            console.log("Waiting for controller to stabilize...");
            await sleep(500);

            // Verify second written value
            console.log(`Verifying second value for ${paramName}`);
            const secondVerifiedValue = await readPassthroughValue(
                pin, 
                0x00000601, 
                parseInt(paramInfo.index, 16), 
                parseInt(paramInfo.sub_index, 16), 
                primaryService
            );

            console.log(`Second verified value: ${secondVerifiedValue} (Expected: ${testValues[1]})`);
            setSecondVerifyResults(prev => ({
                ...prev,
                [paramName]: secondVerifiedValue === testValues[1] ? "Success" : "Failed"
            }));

        } catch (error) {
            console.error(`Error processing parameter ${paramName}:`, error);
            setWriteResults(prev => ({
                ...prev,
                [paramName]: "Error"
            }));
            setVerifyResults(prev => ({
                ...prev,
                [paramName]: "Error"
            }));
            setSecondWriteResults(prev => ({
                ...prev,
                [paramName]: "Error"
            }));
            setSecondVerifyResults(prev => ({
                ...prev,
                [paramName]: "Error"
            }));
        }
    };

    const startTest = async () => {
        try {
            setIsTestRunning(true);
            setProgress(0);
            setReadResults({});
            setWriteResults({});
            setVerifyResults({});
            setSecondWriteResults({});
            setSecondVerifyResults({});
            setCurrentParam("");

            // Get protocols
            const protocols = getAllProtocols();
            console.log("Protocols loaded");
            
            // Get first 5 parameters
            const parameters = Object.entries(readWriteTests.testCases);
            const totalParams = parameters.length;

            // Process each parameter
            for (let i = 0; i < parameters.length; i++) {
                const [paramName, testValues] = parameters[i];
                const paramInfo = protocols[paramName];
                
                if (!paramInfo?.index || !paramInfo?.sub_index) {
                    console.error("Missing protocol info for parameter:", paramName);
                    continue;
                }

                await processParameter(paramName, testValues, paramInfo);
                setProgress(((i + 1) / totalParams) * 100);
                
                // Small delay between parameters
                if (i < parameters.length - 1) {
                    await sleep(500);
                }
            }

        } catch (error) {
            console.error("Error during test:", error);
        } finally {
            setIsTestRunning(false);
            setCurrentParam("");
        }
    };

    return (
        <div className="container-fluid">
            <Card>
                <Card.Header>
                    <Card.Title>Parameter Read/Write Test</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Button 
                        onClick={startTest} 
                        disabled={isTestRunning}
                        className="w-100 mb-4"
                        variant="primary"
                    >
                        Start Test
                    </Button>

                    {isTestRunning && (
                        <div className="mb-4">
                            <ProgressBar 
                                now={progress} 
                                label={`${Math.round(progress)}%`} 
                                className="mb-2"
                            />
                            <div className="text-center">
                                Processing: {currentParam}
                            </div>
                        </div>
                    )}

                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Parameter</th>
                                <th>Initial Value</th>
                                <th>First Write</th>
                                <th>First Verify</th>
                                <th>Second Write</th>
                                <th>Final Verify</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(readWriteTests.testCases).map(([paramName, _]) => (
                                <tr key={paramName}>
                                    <td>{paramName}</td>
                                    <td>{readResults[paramName] ?? '-'}</td>
                                    <td>{writeResults[paramName] ?? '-'}</td>
                                    <td>{verifyResults[paramName] ?? '-'}</td>
                                    <td>{secondWriteResults[paramName] ?? '-'}</td>
                                    <td>{secondVerifyResults[paramName] ?? '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    );
};

export default ReadWriteTestsComponent;