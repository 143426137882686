import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const RemoteGraphModal = ({
  show,
  onHide,
  index,
  subIndex,
  socket,
  pin
}) => {
  const [data, setData] = useState([]);
  const [timeRange, setTimeRange] = useState(30);
  const [currentValue, setCurrentValue] = useState(null);

useEffect(() => {
  if (show && socket) {
    console.log('Starting graph monitoring for:', { index, subIndex });
    
    // Start monitoring when modal opens
    socket.emit('infoMessage', {
      pin,
      message: {
        type: 'startCustomMonitoring',
        index,
        subIndex
      }
    });

    // Handle incoming data
    const handleCanMessage = (data) => {
      const value = new Uint8Array(data.message);
      if (value[0] === 'P'.charCodeAt(0)) {
        const decodedData = decodePassthroughResponse(value);
        if (decodedData && decodedData.index === parseInt(index, 16) && decodedData.subIndex === parseInt(subIndex, 16)) {
          const timestamp = Date.now();
          setCurrentValue(decodedData.canData);
          setData(prevData => {
            const newData = [...prevData, { timestamp, value: decodedData.canData }];
            return newData.filter(entry => timestamp - entry.timestamp <= 120000);
          });
        }
      }
    };

    socket.on('canMessage', handleCanMessage);

    // Cleanup function
    return () => {
      console.log('Stopping graph monitoring');
      socket.emit('infoMessage', {
        pin,
        message: {
          type: 'stopCustomMonitoring'
        }
      });
      socket.off('canMessage', handleCanMessage);
    };
  }
}, [show, socket, index, subIndex, pin]);

  const decodePassthroughResponse = (response) => {
    if (response.length < 9) return null;

    const canHeader = response.slice(1, 5);
    const canDataBytes = response.slice(5, 9);
    const canData = canDataBytes.reduce((acc, byte, index) => acc + (byte << (index * 8)), 0);

    const index = (canHeader[2] << 8) | canHeader[1];
    const subIndex = canHeader[3];

    return { index, subIndex, canData };
  };

  const now = Date.now();
  const filteredData = data
    .filter(entry => now - entry.timestamp <= timeRange * 1000)
    .map(entry => ({
      timestamp: -(now - entry.timestamp) / 1000,
      value: entry.value
    }));

  const chartData = {
    labels: filteredData.map(entry => entry.timestamp),
    datasets: [{
      label: `Index: 0x${parseInt(index, 16).toString(16)}, SubIndex: 0x${parseInt(subIndex, 16).toString(16)}`,
      data: filteredData.map(entry => ({ x: entry.timestamp, y: entry.value })),
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.4,
      pointRadius: 0
    }]
  };

  const chartOptions = {
    animation: false,
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          text: 'Time (seconds ago)'
        },
        min: -timeRange,
        max: 0
      },
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      legend: {
        display: true
      }
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Custom Parameter Monitor</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <p>
            Monitoring Index: 0x{parseInt(index, 16).toString(16).toUpperCase()}, 
            SubIndex: 0x{parseInt(subIndex, 16).toString(16).toUpperCase()}
          </p>
          <p>Current Value: {currentValue !== null ? currentValue : 'Loading...'}</p>
        </div>
        <Form.Group controlId="timeRangeSelect">
          <Form.Label>Select Time Range</Form.Label>
          <Form.Control 
            as="select" 
            value={timeRange} 
            onChange={(e) => setTimeRange(Number(e.target.value))}
          >
            <option value={30}>Last 30 seconds</option>
            <option value={60}>Last 60 seconds</option>
            <option value={120}>Last 120 seconds</option>
          </Form.Control>
        </Form.Group>
        <div>
          <Line data={chartData} options={chartOptions} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoteGraphModal;